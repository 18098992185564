import React from 'react'
import { getDistanceLabel } from '../../utils/address'
import { Tag, ThemeVariants, Typography } from 'rds'

const LocationSelectLocationDescription = ({ location }) => {
    return (
        <div>
            <Typography>{location.address}</Typography>
            <div className='rds-m_top__sm rds-flexbox align-center'>
                <Tag
                    variant={location.isOpenNow ? ThemeVariants.DARK : null}
                    icon={location.isOpenNow ? null : 'LockOne'}
                    className='rds-m_right__sm'
                >
                    {location.isOpenNow ? 'Abierto' : 'Cerrado'}
                </Tag>
                {location.distance ? (
                    <Tag>{getDistanceLabel(location.distance)}</Tag>
                ) : null}
            </div>
        </div>
    )
}

export default LocationSelectLocationDescription
