import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'No Shipment label',
            es: 'Mensaje para zona sin envíos',
        },
        name: 'noShipmentLabel',
        type: FormItemTypes.TEXT,
    },
]

export default props
