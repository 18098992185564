import { Card } from 'rds'
import React from 'react'
import styled from 'styled-components'

const StyledModalWrapper = styled.div`
    &:after {
        content: '';
        background-color: rgb(0 0 0 / 50%);
        backdrop-filter: blur(2px);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 998;
    }

    & .rds-card.rds-modal_content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 999;
        width: max-content;
        max-height: 95svh;
        max-width: 95vw;
        overflow-y: auto;
    }

    @media screen and (max-width: 900px) {
        & .rds-form_content {
            display: flex !important;
        }
    }
`

const Modal = ({ visible, children, onClose }) => {
    const handleEsc = React.useCallback(
        e => {
            if (e.key === 'Escape' && typeof onClose === 'function') {
                onClose()
            }
        },
        [onClose],
    )

    React.useEffect(() => {
        window.addEventListener('keydown', handleEsc)
        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [handleEsc])

    const body = document.getElementsByTagName('body')[0]
    const initialOverflowY = 'initial'
    React.useEffect(() => {
        if (visible) {
            body.style.overflowY = 'hidden'
        } else {
            body.style.overflowY = initialOverflowY
        }
        return () => {
            body.style.overflowY = initialOverflowY
        }
    }, [visible, body.style])

    if (!visible) {
        return null
    }
    return (
        <StyledModalWrapper>
            <Card className='rds-modal_content'>{children}</Card>
        </StyledModalWrapper>
    )
}

export default Modal
