import React from 'react'
import { Button, Card, HeaderSizes, Typography } from 'rds'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CartSummaryItem from './CartSummaryItem'

const CartSummary = () => {
    const cart = useSelector(state => state.cart, shallowEqual)
    const customFields = useSelector(state => state.field.fields, shallowEqual)

    return (
        <Card
            headerTitle='Resumen'
            headerSize={HeaderSizes.SMALL}
            className='cart-summary'
        >
            <div>
                {cart.entries.length ? (
                    cart.entries.map((ce, i) => (
                        <CartSummaryItem
                            cartEntry={ce}
                            key={i}
                            isLastOne={i === cart.entries.length - 1}
                            customFields={customFields}
                        />
                    ))
                ) : (
                    <div className='rds-full-block'>
                        <Typography>
                            Sin items en el carrito. Trata agregando algun
                            producto e intenta nuevamente.
                        </Typography>
                        <Link to='/' className='rds-m_top__sm'>
                            <Button label='Ir al Inicio' />
                        </Link>
                    </div>
                )}
            </div>
        </Card>
    )
}

export default CartSummary
