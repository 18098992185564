import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import {
    Card,
    Button,
    ThemeVariants,
    IconTypes,
    Tag,
    Typography,
    TypographyTypes,
    Block,
} from 'rds'
import { getPictureThumbnail } from '../../../../utils/file'

const StyledProductPicture = styled.img`
    ${props => `
    width: 100%;
    margin: auto;
    display: inline-block;
    height: 100%;
    height: 100%;
    transition: 0.2s all;
    margin-left:
        ${props.index === 0 ? `-${100 * props.carouselPosition}%` : null};
    object-fit:
        ${props.picturesType === 'UNCROPPED' ? 'contain' : null};
    object-position: ${props.picturesPosition};
`}
`

const StyledCarrouselHandlers = styled.div`
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 50%;
`

const Standard = ({
    carouselPosition,
    nameTypographyType,
    onCarrouselClick,
    onFavClick,
    product,
}) => {
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const productCardSettings = useSelector(
        state => state.session.subscription.webstore.settings.products,
        shallowEqual,
    )
    const [hovering, setHovering] = React.useState(false)
    return (
        <Card
            key={product._id}
            className={`product-card product-card_standard ${
                productCardSettings.distribution === 'HORIZONTAL'
                    ? 'product-card_standard__horizontal'
                    : ''
            }`}
        >
            {product.pictures?.length ? (
                <div className='product-card_standard-carousel'>
                    <div className='product-card_standard-carousel_container'>
                        {product.pictures.map((picture, index) => (
                            <StyledProductPicture
                                className='product-card_standard-carousel_picture'
                                key={index}
                                index={index}
                                carouselPosition={carouselPosition}
                                picturesPosition={
                                    productCardSettings.picturesPosition
                                }
                                picturesType={productCardSettings.picturesType}
                                src={getPictureThumbnail(
                                    picture.secure_url,
                                    400,
                                )}
                                alt={product.name}
                            />
                        ))}
                    </div>
                    {product.pictures?.length > 1 ? (
                        <StyledCarrouselHandlers className='product-card_standard-carousel_handlers'>
                            <span
                                onClick={
                                    carouselPosition !== 0
                                        ? () => onCarrouselClick()
                                        : null
                                }
                                className={`product-card_standard-carousel_handler ${
                                    carouselPosition !== 0 ? '' : 'disabled'
                                }`}
                            >
                                {'<'}
                            </span>
                            <span
                                className={`product-card_standard-carousel_handler right ${
                                    carouselPosition <
                                    product.pictures.length - 1
                                        ? ''
                                        : 'disabled'
                                }`}
                                onClick={
                                    carouselPosition < product.pictures.length
                                        ? () => onCarrouselClick(true)
                                        : null
                                }
                            >
                                {'>'}
                            </span>
                        </StyledCarrouselHandlers>
                    ) : null}
                </div>
            ) : null}
            <Link
                to={`/products/${product._id}`}
                onMouseOver={() => setHovering(true)}
                onMouseOut={() => setHovering(false)}
                className='product_details-link'
            >
                <div className='product_details rds-p_around__md rds-p_top__md rds-flexbox direction-column justify-between rds-relative'>
                    <div className='product_variants-tag'>
                        {product.hasComparedPrice ? (
                            <Block
                                className='rds-flexbox rds-m_right__sm'
                                style={{ width: 'fit-content' }}
                                roundedBorder
                            >
                                <Tag
                                    className='discount-tag'
                                    variant={ThemeVariants.WARNING}
                                >
                                    En descuento
                                </Tag>
                            </Block>
                        ) : null}
                        {productCardSettings.showNumberOfVariants &&
                        product.variants?.length > 1 ? (
                            <Tag>{product.variants.length} variantes</Tag>
                        ) : null}
                    </div>
                    <div className='rds-full-block'>
                        <header className='product-card_header'>
                            <Typography
                                type={nameTypographyType}
                                variant={hovering ? ThemeVariants.MAIN : null}
                                className='product-card_name rds-overflow_hidden rds-nowrap'
                            >
                                {product.name}
                            </Typography>
                            {productCardSettings.showDescription ? (
                                <Typography
                                    type={TypographyTypes.P}
                                    className='product-card_description rds-overflow_hidden'
                                >
                                    {product.description}
                                </Typography>
                            ) : null}
                        </header>
                        <div className='rds-full-block rds-flexbox justify-between align-center'>
                            {productCardSettings.showPrice ? (
                                <Tag
                                    className='rds-m_top__sm rds-nowrap'
                                    variant={ThemeVariants.DARK}
                                >
                                    $ {product.minPrice}
                                    {product.minPrice !== product.maxPrice
                                        ? `  -  $ ${product.maxPrice}`
                                        : ''}
                                </Tag>
                            ) : null}
                            {sessionUser._id &&
                            productCardSettings.showFavButton ? (
                                <Button
                                    className='rds-m_top__sm rds-m_left__sm'
                                    variant={ThemeVariants.SECONDARY_LIGHT}
                                    icon={IconTypes.HEART}
                                    onClick={onFavClick}
                                    filled={sessionUser.favorites?.includes(
                                        product._id,
                                    )}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </Link>
        </Card>
    )
}

export default Standard
