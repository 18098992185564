import { createSlice } from '@reduxjs/toolkit'
import api from '../api'

export const locationSlice = createSlice({
    name: 'location',
    initialState: {
        isFetching: false,
        error: null,
        locations: [],
        selectedLocationId: null,
    },
    reducers: {
        setStart: state => {
            state.isFetching = true
            state.error = null
        },
        setEnd: state => {
            state.isFetching = false
            state.error = null
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocationId = action.payload
        },
        setLocations: (state, action) => {
            state.locations = action.payload
        },
    },
})

const { setStart, setEnd, setError, setLocations } = locationSlice.actions
export const { setSelectedLocation } = locationSlice.actions

export const fetchLocations = () => async dispatch => {
    try {
        dispatch(setStart())
        const res = await api.get('locations')
        dispatch(setLocations(res.data.locations))
    } catch (error) {
        dispatch(setError(error.response.data.message))
    } finally {
        dispatch(setEnd())
    }
}

export default locationSlice.reducer
