import { ThemeContext, cssRules } from 'rds'
import React from 'react'
import styledComponents, { keyframes, css } from 'styled-components'

const productSkeletonKeyframes = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
`

const productSkeletonAnimation = () => css`
    ${productSkeletonKeyframes} 1s infinite;
`

const StyledProductCardSkeleton = styledComponents.div`
    animation: ${productSkeletonAnimation};
    ${props => `
        ${cssRules.backgroundRule(props)} 
        ${cssRules.borderRadiusRule(props)}
        min-height: 200px;
    `}`

const ProductCardSkeleton = () => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <StyledProductCardSkeleton
            themeContext={themeContext}
            className='rds-p_around__md'
        />
    )
}

export default ProductCardSkeleton
