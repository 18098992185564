import { isEqual } from 'lodash'
import api from '../api'
import { calculateUnitPriceWithAdditionals } from '.'

/**
 * Generates a clean Additionals object
 * @param {Object} additionalsObject Additionals object containin name of the addiontal and the array of values selected
 * @returns Cleaned Additionals object
 */
const cleanUpAdditionalsObject = (additionalsObject = {}) => {
    const cleanedAdditionalsObject = { ...additionalsObject }
    for (const key in additionalsObject) {
        const value = additionalsObject[key]
        if (!Array.isArray(value) || !value.length) {
            delete cleanedAdditionalsObject[key]
        }
    }
    return cleanedAdditionalsObject
}

/**
 * Whether a product + variant + selected additionals is already in cart
 * @param {Array} cartEntries Array of cart entries
 * @param {Object} product The master product
 * @param {Object} variant The variant that wants to be checked
 * @param {Object} selectedAdditionals Object of selected additionals
 * @returns A boolean indicating if the product variant with same additionals selected is in the cart
 */
export const getProductIsInCart = (
    cartEntries = [],
    product = { variants: [] },
    variant = {},
    selectedAdditionals = {},
    notes,
) => {
    let isVariantInCart = false

    const matchingEntries = cartEntries.filter(cartEntry => {
        const currentVariantId = variant._id.toString()
        const entryVariantId = (
            cartEntry.variant._id || cartEntry.variant
        ).toString()
        return entryVariantId === currentVariantId
    })

    // Check for duplicates on cart entries
    // Duplicates being the ones with the same custom fields (if present)
    // and same additionals selected (if present)
    matchingEntries.forEach(matchingEntry => {
        // First check if notes are the same, if they're not then it is
        // a new cart entry no matter what
        const entryNotes = matchingEntry.notes || ''
        const incomingNotes = notes || ''
        if (entryNotes.toLowerCase() !== incomingNotes.toLowerCase()) {
            return false
        }

        const currentVariantFields = variant.customFields
        const fullMatchingVariant = product.variants.find(
            variant =>
                variant._id.toString() ===
                (matchingEntry.variant._id || matchingEntry.variant).toString(),
        )
        const matchingEntryFields = fullMatchingVariant.customFields

        // Clean-up selected additionals (given that unselected additionals will
        // leave an empty array that will make the exact match break)
        const safeSelectedAdditionals =
            cleanUpAdditionalsObject(selectedAdditionals)
        const safeMatchingAdditionals = cleanUpAdditionalsObject(
            matchingEntry.additionals,
        )

        if (
            isEqual(currentVariantFields, matchingEntryFields) &&
            isEqual(safeSelectedAdditionals, safeMatchingAdditionals)
        ) {
            isVariantInCart = true
        }
    })
    return isVariantInCart
}

/**
 * Builds a MercadoPago items array using cart entries. This array is needed
 * for creating a checkout preference in MercadoPago API.
 * @param {Array} cartEntries List of Cart Entries
 * @returns MercadoPago items array
 */
export const getMPCheckoutItems = async cartEntries => {
    const items = []
    const res = await api.get('fields?visibleInWebstore=true&type=ADDITIONALS')
    const additionalsFields = res.data.fields
    const responses = await Promise.all(
        cartEntries.map(entry =>
            api.get(`products/${entry.item._id || entry.item}`),
        ),
    )
    const populatedProducts = responses.map(res => res.data.product)
    cartEntries.forEach(entry => {
        const cartEntryItemId = entry.item._id || entry.item
        const product = populatedProducts.find(
            prod => prod._id === cartEntryItemId,
        )
        const productVariant = product.variants.find(
            pv => pv._id === entry.variant,
        )
        items.push({
            title: product.name,
            unit_price: calculateUnitPriceWithAdditionals(
                productVariant.price,
                entry.additionals,
                additionalsFields,
            ),
            quantity: entry.quantity,
            category_id: product.category._id || product.category,
            picture_url: productVariant.pictures?.length
                ? productVariant.pictures[0].secure_url
                : null,
        })
    })
    return items
}
