import { createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import api from '../api'
import {
    REANGO_CART_LOCALSTORAGE_NAME,
    REANGO_GUEST_TOKEN_LOCALSTORAGE_NAME,
    REANGO_TOKEN_LOCALSTORAGE_NAME,
} from '../constants'
import { DEBUG_MODE } from '../utils'
import { cartSetCart } from './cartReducer'

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        isFetching: false,
        user: {},
        subscription: {
            webstore: {
                settings: {
                    header: {
                        navigation: {
                            items: [],
                        },
                    },
                    theme: {},
                },
            },
        },
        error: null,
    },
    reducers: {
        setStart: state => {
            state.isFetching = true
            state.error = null
        },
        setEnd: state => {
            state.isFetching = false
            state.error = null
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setSubscription: (state, action) => {
            state.subscription = action.payload
            document.title = state.subscription.projectFullname
        },
        setPages: (state, action) => {
            state.subscription.webstore.pages = action.payload
        },
        setPage: (state, action) => {
            const pageIndex = state.subscription.webstore.pages.findIndex(
                page => page._id === action.payload._id,
            )
            if (pageIndex >= 0) {
                state.subscription.webstore.pages[pageIndex] = action.payload
            }
        },
    },
})
const {
    setPages,
    setPage,
    setSubscription,
    setUser,
    setError,
    setStart,
    setEnd,
} = sessionSlice.actions

export const sessionSetUser =
    (user = {}) =>
    dispatch => {
        try {
            dispatch(setStart())
            dispatch(setUser(user))
            dispatch(setEnd())
        } catch (error) {
            setError(error)
        }
    }

/**
 * Logs in with the given credentials and set the token to be used by every request
 *
 * @param {Object} loginData
 * @param {Boolean} isCustomerUser Determines whether the User being logged in is a customer or a guest user
 * @returns Axios data response object
 */
export const sessionDoLogin = async (loginData, isCustomerUser) => {
    const res = await api.post('login', loginData)
    const { token } = res.data
    api.defaults.headers.common['Authorization'] = token
    if (isCustomerUser) {
        localStorage.setItem(REANGO_TOKEN_LOCALSTORAGE_NAME, token)
    } else {
        localStorage.setItem(REANGO_GUEST_TOKEN_LOCALSTORAGE_NAME, token)
    }
    return res.data
}

/**
 * Applies the Custom CSS specified on the Webstore settings and applies, if applicable, the
 * Google Fonts import and font family assignment.
 * All added through a style HTML tag appended to the page document.
 * @param {Object} webstoreSettings
 */
const applyCustomCSS = webstoreSettings => {
    const { theme, customCSS } = webstoreSettings
    if (!theme?.fontFamily && !customCSS) {
        return
    }
    const style = document.querySelector('#custom-css')
    style.innerHTML = ''
    let styleContent = ''
    if (theme.fontFamily) {
        const parsedFontFamilyName = theme.fontFamily.replaceAll(' ', '+')
        styleContent = `@import url('https://fonts.googleapis.com/css2?family=${parsedFontFamilyName}:wght@300;400;500;600;700&display=swap');\n`
    }
    if (customCSS) {
        styleContent += customCSS
    }
    if (theme.fontFamily) {
        // Append at the end to prevent customCSS's @import errors
        styleContent += `\n* { font-family: '${theme.fontFamily}', sans-serif !important; }`
    }
    style.appendChild(document.createTextNode(styleContent))
}

export const getSubscription = () => async dispatch => {
    try {
        dispatch(setStart())
        const res = await api.get('subscription')
        const { subscription } = res.data
        const webstorePagesRes = await api.get('webstore/pages')
        subscription.webstore.pages = webstorePagesRes.data.pages
        const navigationItemsRes = await api.get('webstore/navigation/items')
        subscription.webstore.settings.header.navigation.items =
            navigationItemsRes.data.items
        dispatch(setSubscription(subscription))
        applyCustomCSS(subscription.webstore.settings)
    } catch (error) {
        setError(error)
    } finally {
        dispatch(setEnd())
    }
}

export const fetchPages = () => async dispatch => {
    try {
        const res = await api.get('webstore/pages')
        dispatch(setPages(res.data.pages))
    } catch (error) {
        setError(error)
    }
}

export const fetchPage = pageId => async dispatch => {
    try {
        const res = await api.get(`webstore/pages/${pageId}`)
        dispatch(setPage(res.data.page))
    } catch (error) {
        setError(error)
    }
}

export const updateUserCart = newCartEntries => async (dispatch, getState) => {
    try {
        dispatch(setStart())
        const { user } = getState().session
        const cleanedUpUserCurrentCart = user.cart.map(entry => ({
            item: entry.item._id || entry.item,
            variant: entry.variant._id || entry.variant,
            quantity: entry.quantity,
            additionals: entry.additionals,
        }))
        // Prevent hitting the database if carts are the same
        if (!isEqual(cleanedUpUserCurrentCart, newCartEntries)) {
            if (DEBUG_MODE) {
                console.log(
                    '[SESSION_REDUCER]',
                    'Updating user cart:',
                    newCartEntries,
                )
            }
            const res = await api.put(`users/${user._id}`, {
                // Making this safier
                cart: newCartEntries || [],
            })
            if (DEBUG_MODE) {
                console.log(
                    '[SESSION_REDUCER]',
                    'Updated user cart:',
                    res.data.user.cart,
                )
            }
        }
        dispatch(setEnd())
    } catch (error) {
        console.error('[SESSION_REDUCER]', error)
        dispatch(setError(error))
    }
}

export const doGuestLogin = () => async dispatch => {
    await sessionDoLogin({
        email: process.env.REACT_APP_WEBSTORE_USERNAME,
        password: process.env.REACT_APP_WEBSTORE_PASSWORD,
        database: process.env.REACT_APP_PROJECT_NAME,
    })
    await dispatch(sessionSetUser())
    if (DEBUG_MODE) {
        console.log('[APP] Is guest user')
    }
    const localStorageCart =
        localStorage.getItem(REANGO_CART_LOCALSTORAGE_NAME) || []
    if (DEBUG_MODE) {
        console.log('[SESSION_REDUCER] Current cart:', localStorageCart)
    }
    dispatch(
        cartSetCart({
            entries: localStorageCart,
            isUserCart: false,
        }),
    )
}

export const doLogout = () => async dispatch => {
    // Clear user token on localStorage
    localStorage.removeItem(REANGO_TOKEN_LOCALSTORAGE_NAME)
    // Clear cart
    localStorage.removeItem(REANGO_CART_LOCALSTORAGE_NAME)
    // Do guest login
    await dispatch(doGuestLogin())
}

export default sessionSlice.reducer
