import React from 'react'
import { useSelector } from 'react-redux'
import styledComponents from 'styled-components'
import { LAYOUT_TYPES } from '../constants'

const StyledContainer = styledComponents.div`${props => `
    min-width: 300px;
    max-width: ${props.containerWidth}px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    ${
        props.layoutType === LAYOUT_TYPES.LEFT_SIDEBAR ||
        props.layoutType === LAYOUT_TYPES.RIGHT_SIDEBAR
            ? `
        display: grid;
        grid-template-columns: ${
            props.layoutType === LAYOUT_TYPES.LEFT_SIDEBAR
                ? `${props.sidebarWidth}px auto`
                : `auto ${props.sidebarWidth}px`
        };
    `
            : ''
    }

    @media (max-width: 500px) {
        width: 95%;
    }
`}`

const Container = props => {
    const containerWidth = useSelector(
        state =>
            state.session.subscription.webstore.settings.theme.containerWidth,
    )
    return (
        <StyledContainer {...props} containerWidth={containerWidth}>
            {props.children}
        </StyledContainer>
    )
}

export default Container
