import React from 'react'
import { useNavigate } from 'react-router'
import styledComponents from 'styled-components'
import { Header, ThemeContext } from 'rds'
import { applyEffectToImage } from '../../utils'

const StyledSlider = styledComponents.div`
    position: relative;
`

const StyledCarrouselHeroInner = styledComponents.div`
    white-space: nowrap;
    transition: ${props => props.transitionSpeed}s transform;
    transform: translateX(${props =>
        props.currentPage ? `-${props.currentPage}00%` : '0'});
    height: ${props => props.height}px;

    & > * {
        position: relative;
        display: inline-grid;
        height: inherit;
        width: 100%;
    }
`

const StyledSliderItem = styledComponents.div`${props => `
    ${props.item.navigateToURL ? 'cursor: pointer;' : ''}
`}`

const StyledSliderItemPicture = styledComponents.div`${props => `
    position: relative;
    height: inherit;
    width: 100%;
    background-image: url(${applyEffectToImage(
        props.item.picture?.url,
        props.item.picture?.effect,
        props.themeContext,
    )});
    background-size: cover;
    background-position: ${props.item.picture?.verticalPosition} ${
    props.item.picture?.horizontalPosition
};
    transition: .2s background-position;
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props.item.overlay?.color};
        opacity: ${props.item.overlay?.opacity};
    }
`}`

const StyledSliderItemHeader = styledComponents.div`${props => `
    position: absolute;
    white-space: break-spaces;
    width: fit-content;
    padding: ${props.padding ? `${props.padding}px` : undefined};
    text-align: ${props.item.header?.textAlignment};
    top:
        ${
            props.item.header?.verticalPosition === 'TOP'
                ? 0
                : props.item.header?.verticalPosition === 'CENTER'
                ? '50%'
                : undefined
        };
    bottom: ${props.item.header?.verticalPosition === 'BOTTOM' ? 0 : undefined};
    left:
        ${
            props.item.header?.horizontalPosition === 'LEFT'
                ? 0
                : props.item.header?.horizontalPosition === 'CENTER'
                ? '50%'
                : undefined
        };
    right:
        ${props.item.header?.horizontalPosition === 'RIGHT' ? 0 : undefined};
    transform: translateY(${
        props.item.header?.verticalPosition === 'CENTER' ? '-50%' : 0
    }) translateX(${
    props.item.header?.horizontalPosition === 'CENTER' ? '-50%' : 0
});
`}`

const StyledCarrouselHeroSelectors = styledComponents.div`
    position: absolute;
    bottom: var(--rds-spacingSmall);
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
`

const StyledCarrouselHeroSelector = styledComponents.div`
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 0 var(--rds-spacingSmall);
    cursor: pointer;
    transition: ${props => props.transitionSpeed}s all;
    
    ${props =>
        props.isActive
            ? `
        opacity: 1;
    `
            : `
            
        opacity: 0.5;
        transform: scale(0.8);
            `}
`

const Slider = ({
    items = [],
    height = 300,
    transitionSpeed = 0.2,
    milisecondsPerPage = 5000,
    padding = 20,
    margin,
}) => {
    const navigate = useNavigate()
    const themeContext = React.useContext(ThemeContext)
    const [currentPage, setCurrentPage] = React.useState(0)

    const resetInterval = React.useCallback(() => {
        if (interval.current) {
            clearInterval(interval.current)
        }
        interval.current = setInterval(() => {
            setCurrentPage(currentPage => {
                if (currentPage + 1 === items.length) {
                    return 0
                } else {
                    return currentPage + 1
                }
            })
        }, milisecondsPerPage)
    }, [milisecondsPerPage, items.length])

    const interval = React.useRef()
    React.useEffect(() => {
        setCurrentPage(0)
        resetInterval()
        return () => {
            clearInterval(interval.current)
        }
    }, [resetInterval])

    const handleItemClick = item => {
        if (item.navigateToURL) {
            navigate(item.navigateToURL)
        }
    }

    const handleSelectorClick = i => {
        setCurrentPage(i)
        resetInterval()
    }

    return (
        <StyledSlider
            className='rds-carrousel-hero'
            style={{ margin: margin ? `${margin}px` : undefined }}
        >
            <StyledCarrouselHeroInner
                className='rds-carrousel-hero_inner'
                transitionSpeed={transitionSpeed}
                currentPage={currentPage}
                height={height}
            >
                {items.map((item, i) => (
                    <StyledSliderItem
                        key={i}
                        item={item}
                        onClick={() => handleItemClick(item)}
                    >
                        <StyledSliderItemPicture
                            item={item}
                            themeContext={themeContext}
                        />
                        <StyledSliderItemHeader item={item} padding={padding}>
                            <Header {...item.header} />
                        </StyledSliderItemHeader>
                    </StyledSliderItem>
                ))}
            </StyledCarrouselHeroInner>
            <StyledCarrouselHeroSelectors className='rds-flexbox align-center'>
                {items.map((item, i) => (
                    <StyledCarrouselHeroSelector
                        key={i}
                        isActive={currentPage === i}
                        transitionSpeed={transitionSpeed}
                        onClick={() => handleSelectorClick(i)}
                    />
                ))}
            </StyledCarrouselHeroSelectors>
        </StyledSlider>
    )
}

export default Slider
