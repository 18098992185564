import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
    Card,
    Chat,
    Label,
    TableItem,
    Tag,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import api from '../../api'
import { getDateLabel } from '../../utils/date'

const DEBUG_PREFIX = '[CASE_DETAIL]'

const CaseDetail = () => {
    const params = useParams()
    const sessionUserId = useSelector(state => state.session.user._id)
    const [caseDoc, setCaseDoc] = React.useState({})
    const [caseComments, setCaseComments] = React.useState([])
    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    })

    React.useEffect(() => {
        if (caseDoc._id === params.caseId) {
            return
        }
        api.get(`cases/${params.caseId}`)
            .then(res => {
                if (mounted.current) {
                    setCaseDoc(res.data.case)
                }
            })
            .catch(err => {
                console.error(DEBUG_PREFIX, err)
            })
    }, [caseDoc._id, params.caseId])

    const handleCaseCommentsFetch = async () => {
        const res = await api.get(`/cases/${params.caseId}/comments`)
        setCaseComments(
            res.data.caseComments.map(caseComment => ({
                ...caseComment,
                text: caseComment.comment,
            })),
        )
    }

    const handleCaseCommentSubmit = async message => {
        const res = await api.post(`/cases/${params.caseId}/comments`, {
            comment: message,
        })
        setCaseComments(
            [...caseComments, res.data.caseComment].map(caseComment => ({
                ...caseComment,
                text: caseComment.comment,
            })),
        )
    }

    return (
        <div>
            <Card headerTitle={`Caso #${caseDoc.name}`}>
                <div className='rds-grid rds-grid_2-columns'>
                    <TableItem className='rds-grid_2-columns_item'>
                        <Label>Descripción</Label>
                        <Typography>{caseDoc.description}</Typography>
                    </TableItem>
                    <TableItem>
                        <Label>Estado</Label>
                        <Typography>
                            <Tag
                                variant={
                                    caseDoc.status === 'CLOSED'
                                        ? ThemeVariants.DARK
                                        : undefined
                                }
                            >
                                {caseDoc.status}
                            </Tag>
                        </Typography>
                    </TableItem>
                    {caseDoc.sale ? (
                        <TableItem>
                            <Label>Venta relacionada</Label>
                            <div>
                                <Link
                                    to={`/purchases/${caseDoc.sale.id}`}
                                    className='rds-text-variant_link'
                                    target='_blank'
                                >
                                    <Typography>
                                        #{caseDoc.sale ? caseDoc.sale.name : ''}
                                    </Typography>
                                </Link>
                            </div>
                        </TableItem>
                    ) : null}
                    <TableItem>
                        <Label>Tipo</Label>
                        <Typography>
                            <Tag>{caseDoc.topic}</Tag>
                        </Typography>
                    </TableItem>
                    <TableItem>
                        <Label>Creado</Label>
                        <Typography>
                            {getDateLabel(caseDoc.createdAt)}
                        </Typography>
                    </TableItem>
                    <TableItem noLine>
                        <Label>Actualizado</Label>
                        <Typography>
                            {getDateLabel(caseDoc.updatedAt)}
                        </Typography>
                    </TableItem>
                    {caseDoc.closedAt ? (
                        <TableItem noLine>
                            <Label>Cerrado en</Label>
                            <Typography type={TypographyTypes.H4}>
                                {getDateLabel(caseDoc.closedAt)}
                            </Typography>
                        </TableItem>
                    ) : null}
                </div>
            </Card>
            <Chat
                onFetch={handleCaseCommentsFetch}
                messages={caseComments}
                onSubmit={handleCaseCommentSubmit}
                sessionUserId={sessionUserId}
                disabled={caseDoc.status === 'CLOSED'}
                messagePlaceholder='Escribe un comentario...'
                emptyStateTitle='No hay comentarios aún'
                emptyStateText='Estamos analizando tu caso, espera un momento...'
                useInterval
            />
        </div>
    )
}

export default CaseDetail
