import { CLOUDINARY_EFFECTS } from '../constants'

export const DEBUG_MODE =
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'development'

export const isBuilder = window.location !== window.parent.location

export const hexToRgb = (hex = '#000000') => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

/**
 * Gets and rgba value for the provided hexadecimal color.
 *
 * @param {String} color Hexadecimal color string
 * @param {Number} colorAlpha 0 to 100 opacity amount
 * @returns The rgba formatted color string
 */
export const getParsedColor = (color = '#000000', colorAlpha) => {
    if (!color || color.includes('var(')) {
        return color
    }
    const rgba = hexToRgb(color)
    rgba.a = colorAlpha ? colorAlpha / 100 : 0
    return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`
}

/**
 * Calculates the unit price of a given product adding the
 * prices of every additional
 *
 * @param {Number} unitPrice Unit price of the product variant
 * @param {Object} selectedAdditionals Map of field API names, and their selected values
 * @param {Array} additionals Array of additionals fields available
 * @return Calculated unit price including additionals
 */
export const calculateUnitPriceWithAdditionals = (
    unitPrice = 0,
    selectedAdditionals = {},
    additionals = [],
) => {
    let priceWithAdditionals = unitPrice
    Object.keys(selectedAdditionals).forEach(selectedAdditional => {
        const additionalField = additionals.find(
            additional => additional.apiName === selectedAdditional,
        )
        if (!additionalField || !additionalField.additionals) {
            console.error(
                `Additional field "${selectedAdditional}" was not found`,
            )
            return
        }
        const selectedAdditionalValues = selectedAdditionals[selectedAdditional]
        selectedAdditionalValues.forEach(selectedAdditionalValue => {
            const matchingAdditional = additionalField.additionals.find(
                additionalField =>
                    additionalField.name === selectedAdditionalValue,
            )
            if (!matchingAdditional) {
                console.error(
                    'No matching additional, make sure you are sending the right fields',
                )
                return
            }
            const additionalPrice = matchingAdditional.price
            priceWithAdditionals += additionalPrice
        })
    })
    return priceWithAdditionals
}

/**
 * Formats the incoming phone number value to match expected phone number
 * format.
 * @param {String} phoneNumber Unformatted phone number string
 * @returns Formatted phone number string
 */
export const getFormattedPhoneNumber = phoneNumber => {
    if (!phoneNumber) {
        return phoneNumber
    }
    // Remove special characters
    let safePhoneNumber = phoneNumber
        .replace(/\D/g, '')
        .replaceAll('.', '')
        .replaceAll('+', '')
        .replaceAll('-', '')
    // Remove prefix
    if (safePhoneNumber.startsWith('0')) {
        safePhoneNumber = safePhoneNumber.substring(1, safePhoneNumber.length)
    }
    // Enforce 8 digit length
    safePhoneNumber = safePhoneNumber.substring(0, 8)
    return safePhoneNumber
}

/**
 * Converts the given object to a URL query string
 * @param {Object} queryObject Object with properties that will be translated into string queries
 * @returns URL query string
 */
export const convertObjectToQueryString = (queryObject = {}) => {
    let queryString = ''
    Object.keys(queryObject).forEach((query, i) => {
        queryString += `${query}=${encodeURI(queryObject[query])}${
            i < Object.keys(queryObject).length - 1 ? '&' : ''
        }`
    })
    if (queryString) {
        queryString.replace(/(\r\n|\n|\r)/gm, '')
    }
    return queryString
}

/**
 * Returns the transformation URI for the specified effect.
 *
 * @param {string} effect - The effect to generate the transformation URI for.
 * @param {object} themeContext - The theme context object.
 * @returns {string} The transformation URI for the specified effect.
 */
const getTransformationURIForEffect = (effect, themeContext) => {
    const SIZE = 500
    switch (effect) {
        case CLOUDINARY_EFFECTS.FACE:
            return `c_thumb,g_face,h_${SIZE},w_${SIZE}/f_auto`
        case CLOUDINARY_EFFECTS.ROUNDED:
            return `ar_1.0,c_fill,h_${SIZE},w_${SIZE}/r_max/f_auto`
        case CLOUDINARY_EFFECTS.BACKGROUND_COLOR_MAIN:
            return `e_background_removal/b_rgb:${themeContext.main.replace(
                '#',
                '',
            )}`
        case CLOUDINARY_EFFECTS.BACKGROUND_COLOR_SECONDARY:
            return `e_background_removal/b_rgb:${themeContext.secondary.replace(
                '#',
                '',
            )}`
        case CLOUDINARY_EFFECTS.BACKGROUND_COLOR_WHITE:
            return `e_background_removal/b_rgb:fff`
        case CLOUDINARY_EFFECTS.BACKGROUND_COLOR_TRANSPARENT:
            return `e_background_removal/b_rgb:00000000`
        case CLOUDINARY_EFFECTS.GRAYSCALE:
            return `e_grayscale`
        case CLOUDINARY_EFFECTS.NEGATIVE:
            return `e_negate`
        case CLOUDINARY_EFFECTS.BLUR:
            return `e_blur:800`
        default:
            return ``
    }
}

/**
 * Applies the specified effect to an image URL.
 *
 * @param {string} url - The URL of the image.
 * @param {string} effect - The effect to apply to the image.
 * @param {Object} themeContext - The theme context object.
 * @returns {string} The modified image URL with the effect applied.
 */
export const applyEffectToImage = (url, effect, themeContext) => {
    if (!url || !effect) {
        return url
    }
    const splitter = '/upload/'
    const splittedURL = url.split(splitter)
    const transformationURI = getTransformationURIForEffect(
        effect,
        themeContext,
    )
    return `${splittedURL[0]}${splitter}${transformationURI}/${splittedURL[1]}`
}

/**
 * Builds the background image style based on the provided parameters.
 *
 * @param {string} backgroundImage - The URL of the background image.
 * @param {string} overlayColor - The color of the overlay.
 * @param {number} overlayColorAlpha - The alpha value of the overlay color.
 * @returns {string} The CSS background image style.
 */
export const getBackgroundImageValue = (
    backgroundImage,
    overlayColor,
    overlayColorAlpha,
) => {
    const color = getParsedColor(overlayColor, overlayColorAlpha)
    return `${overlayColor ? `linear-gradient(${color}, ${color})` : ''}${
        backgroundImage && overlayColor ? ', ' : ''
    }${backgroundImage ? `url(${backgroundImage})` : ''}`
}
