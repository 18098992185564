import React from 'react'
import { isEqual } from 'lodash'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import {
    Header,
    Typography,
    TypographyTypes,
    Button,
    IconTypes,
    Rater,
    FormItem,
    FormItemTypes,
    ThemeVariants,
    Card,
    ButtonVariants,
    HeaderSizes,
} from 'rds'
import styles from './ProductDetail.module.css'
import api from '../../../api'
import { PRODUCT_RATING } from '../../../constants'
import { sessionSetUser } from '../../../reducers/sessionReducer'
import { calculateUnitPriceWithAdditionals, DEBUG_MODE } from '../../../utils'
import CartButton from '../CartButton'
import ProductDetailPictures from './ProductDetailPictures'
import ProductDetailReviews from './ProductDetailReviews'
import ProductDetailSkeleton from './ProductDetail.skeleton'

const DEBUG_PREFIX = '[PRODUCT_DETAIL]'

const ProductDetail = ({
    showReviewsAverage,
    showReviewsSection,
    showStockLeft,
    selectionMode = FormItemTypes.SELECT,
}) => {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const sessionUserId = useSelector(state => state.session.user._id)
    const sessionUserFavorites = useSelector(
        state => state.session.user.favorites,
        shallowEqual,
    )
    const allCustomFields = useSelector(
        state => state.field.fields,
        shallowEqual,
    )
    const [error, setError] = React.useState()
    const [isLoading, setIsLoading] = React.useState(true)
    const [category, setCategory] = React.useState()
    const [customFields, setCustomFields] = React.useState([])
    const [activePicture, setActivePicture] = React.useState()
    const [product, setProduct] = React.useState({})
    const [avgReview, setAvgReview] = React.useState()
    const [currentVariant, setCurrentVariant] = React.useState({})
    const [variantOptions, setVariantOptions] = React.useState({})
    const [pictures, setPictures] = React.useState([])
    const [variantAvailability, setVariantAvailability] = React.useState({
        label: '',
        stock: 0,
    })
    const [selectedAdditionals, setSelectedAdditionals] = React.useState({})
    const [priceWithAdditionals, setPriceWithAdditionals] = React.useState(
        currentVariant.price,
    )
    const [hasRequiredFieldsSelected, setHasRequiredFieldsSelected] =
        React.useState(true)
    const [productNotes, setProductNotes] = React.useState()
    const [quantity, setQuantity] = React.useState(1)
    const customFieldsCalculated = React.useRef()
    const isOutOfStock =
        !currentVariant.infiniteStock && currentVariant.stock <= 0
    const isAddToCartDisabled = !hasRequiredFieldsSelected || isOutOfStock
    const isFavorited =
        sessionUserFavorites?.length &&
        sessionUserFavorites.find(
            favorite =>
                favorite === product._id || favorite._id === product._id,
        )

    const resetStates = () => {
        window.scrollTo(0, 0)
        setIsLoading(true)
        setError()
        setProduct({})
        setActivePicture()
        setPictures([])
        setCategory()
        setCurrentVariant({})
        setVariantOptions({})
        setSelectedAdditionals({})
    }

    React.useEffect(() => {
        if (!params?.productId) {
            return
        }

        // Reset states on product ID change so states can be fresh-new (additionals, category, etc.)
        resetStates()

        // Fetch product data
        api.get(`products/${params.productId}?useCache=true`)
            .then(res => {
                const { product } = res.data

                // Validate product
                if (!product.isVisibleInWebstore) {
                    throw new Error('Producto no disponible en la tienda')
                }

                // Set category
                setCategory(product.category._id)

                // Set default variant
                let defaultVariant
                let lowestPrice
                product.variants.forEach(variant => {
                    if (!lowestPrice || variant.price < lowestPrice) {
                        lowestPrice = variant.price
                        defaultVariant = variant
                    }
                })
                if (DEBUG_MODE) {
                    console.log(
                        DEBUG_PREFIX,
                        'Default variant:',
                        defaultVariant,
                    )
                }
                setCurrentVariant(defaultVariant)

                const pictures = defaultVariant.pictures || []
                setPictures(pictures)
                if (pictures.length) {
                    setActivePicture(pictures[0].secure_url)
                }

                if (defaultVariant.customFields) {
                    const defaultVariantOptions = {}
                    customFields.forEach(categoryCustomField => {
                        const defaultVariantOption =
                            defaultVariant.customFields[
                                categoryCustomField.apiName
                            ]
                        if (defaultVariantOption) {
                            defaultVariantOptions[categoryCustomField.apiName] =
                                defaultVariantOption
                        }
                    })
                    setVariantOptions(defaultVariantOptions)
                    if (DEBUG_MODE) {
                        console.log(
                            DEBUG_PREFIX,
                            'Default variant options:',
                            defaultVariantOptions,
                        )
                    }
                }

                setProduct(product)
                setAvgReview(product.reviews.average - 1)
            })
            .catch(err => {
                setError(err.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [params.productId, customFields])

    React.useEffect(() => {
        if (!currentVariant || !product._id) {
            return
        }
        let stockLabel = ''
        let stockDescription = ''
        let priceLabel = null
        if (Object.keys(currentVariant).length) {
            priceLabel = currentVariant.price
            if (!currentVariant.infiniteStock) {
                stockLabel = 'No disponible'
                stockDescription = 'Trata modificando las opciones.'
                if (!isOutOfStock) {
                    stockLabel = `${currentVariant.stock} disponible${
                        currentVariant.stock > 1 ? 's' : ''
                    }`
                    stockDescription = null
                }
            }
        } else {
            stockLabel = 'Selecciona una variante'
            priceLabel = `${product.minPrice}${
                product.minPrice !== product.maxPrice
                    ? ` - ${product.maxPrice}`
                    : ''
            }`
        }
        setVariantAvailability({
            label: stockLabel,
            description: stockDescription,
            priceLabel,
        })
    }, [currentVariant, product, isOutOfStock])

    /**
     * Checks whether the current matching variant has every required fields
     */
    React.useEffect(() => {
        if (
            !currentVariant ||
            !category ||
            !Array.isArray(customFields) ||
            !customFields.length
        ) {
            return
        }

        const requiredCustomFields = customFields.filter(
            customField => customField.required,
        )
        let hasRequiredFields = true

        // Check custom fields
        for (let i = 0; i < requiredCustomFields.length; i++) {
            const reqField = requiredCustomFields[i]
            const { type, apiName } = reqField

            // Treat additionals different as they don't live in the variant
            // but in the local state
            if (type === 'ADDITIONALS') {
                const currentVariantAdditionalValue =
                    selectedAdditionals[apiName]
                // If this required additional is either not selected or
                // deselected (was once selected but not anymore), it means
                // is missing a required field
                if (
                    currentVariantAdditionalValue === null ||
                    currentVariantAdditionalValue === undefined ||
                    (Array.isArray(currentVariantAdditionalValue) &&
                        !currentVariantAdditionalValue.length)
                ) {
                    hasRequiredFields = false
                    break
                }
            } else {
                // Check custom fields
                if (!currentVariant.customFields) {
                    hasRequiredFields = false
                    break
                }
                const currentVariantFieldValue =
                    currentVariant.customFields[apiName]
                if (
                    currentVariantFieldValue === null ||
                    currentVariantFieldValue === undefined
                ) {
                    hasRequiredFields = false
                    break
                }
            }
        }

        setHasRequiredFieldsSelected(hasRequiredFields)
    }, [customFields, currentVariant, category, selectedAdditionals])

    React.useEffect(() => {
        if (
            !Array.isArray(customFields) ||
            !customFields.length ||
            !selectedAdditionals ||
            !currentVariant._id
        ) {
            return
        }
        const additionalFields = customFields.filter(
            field => field.type === 'ADDITIONALS',
        )
        setPriceWithAdditionals(
            calculateUnitPriceWithAdditionals(
                currentVariant.price,
                selectedAdditionals,
                additionalFields,
            ),
        )
    }, [
        customFields,
        selectedAdditionals,
        currentVariant._id,
        currentVariant.price,
    ])

    React.useEffect(() => {
        if (
            !category ||
            !Array.isArray(allCustomFields) ||
            !allCustomFields.length ||
            customFieldsCalculated.current
        ) {
            return
        }
        customFieldsCalculated.current = true
        const categoryCustomFields = allCustomFields.filter(field =>
            field.categories.find(
                fieldCategory => fieldCategory._id === category,
            ),
        )
        setCustomFields(categoryCustomFields)
    }, [category, allCustomFields, customFields])

    /**
     * Builds a cleaned version of the given options object.
     *
     * Cleaned version means that it gets rid of every property that can
     * not be present on the variant itself, these being "null" values, "undefined"
     * values and "false" values.
     *
     * @param {Object} optionsObject
     * @returns Clean version of options object
     */
    const getCleanedOptionsObject = optionsObject => {
        const cleanedOptionsObject = {}
        for (const option in optionsObject || {}) {
            const isFieldValid = customFields.find(
                field => field.apiName === option,
            )
            const optionValue = optionsObject[option]
            if (
                isFieldValid &&
                optionValue !== null &&
                optionValue !== undefined &&
                optionValue !== false
            ) {
                cleanedOptionsObject[option] = optionValue
            }
        }
        return cleanedOptionsObject
    }

    const handleVariantChange = (e, fieldAPIName) => {
        // Store in state for next use
        const newVariantOptions = {
            ...variantOptions,
            [fieldAPIName]: e.target.value,
        }
        setVariantOptions(newVariantOptions)

        // Search for a variant that matches EVERY cusom field
        // selected by the user
        let newVariantDetails = {
            ...currentVariant,
            pictures: product.pictures,
            stock: 0,
            infiniteStock: false,
            _id: null,
        }

        // Get a clean version of selected options
        const safeNewVariantOptions = getCleanedOptionsObject(newVariantOptions)
        if (DEBUG_MODE) {
            console.log(
                DEBUG_PREFIX,
                'Looking for matching variant with:',
                safeNewVariantOptions,
            )
        }

        // In case it matched any variant
        // check whether the matching was exact or partial
        for (let i = 0; i < product.variants.length; i++) {
            const variant = product.variants[i]
            const safeVariantOptions = getCleanedOptionsObject(
                variant.customFields,
            )
            if (DEBUG_MODE) {
                console.log(DEBUG_PREFIX, 'Comparing with:', safeVariantOptions)
            }
            if (isEqual(safeNewVariantOptions, safeVariantOptions)) {
                newVariantDetails = variant
                break
            }
        }

        if (DEBUG_MODE) {
            console.log(DEBUG_PREFIX, 'Matched:', newVariantDetails)
        }

        // Set it as current variant
        setCurrentVariant(newVariantDetails)

        // Reset quantity input
        setQuantity(1)

        // If new variant has pictures, show only those and set
        // the first one as active
        if (newVariantDetails.pictures?.length) {
            setPictures(newVariantDetails.pictures)
            setActivePicture(newVariantDetails.pictures[0].secure_url)
        }
    }

    const handleFav = async () => {
        try {
            if (!sessionUserId) {
                navigate('/login')
            }
            const res = await api.put(
                `users/${sessionUserId}/favorites/${product._id}`,
            )
            dispatch(sessionSetUser(res.data.user))
        } catch (error) {
            setError(error.message)
        }
    }

    if (isLoading) {
        return <ProductDetailSkeleton />
    } else if (error) {
        return (
            <Card
                headerIcon={IconTypes.WARNING}
                headerTitle='Algo salió mal'
                headerText={error}
                headerVariant={ThemeVariants.ERROR}
                headerSize={HeaderSizes.MEDIUM}
            />
        )
    }
    return (
        <div className='product-detail'>
            <Helmet
                script={[
                    helmetJsonLdProp({
                        '@context': 'https://schema.org',
                        '@type': 'Product',
                        category: product.category?.name,
                        description: product.description,
                        name: product.name,
                        sku: currentVariant.sku,
                        weight: currentVariant.weight,
                    }),
                ]}
            />
            <div
                className={`${styles['product-detail_inner']} product-detail_inner rds-grid rds-grid_2-columns`}
            >
                <ProductDetailPictures
                    product={product}
                    pictures={pictures}
                    activePicture={activePicture}
                    onSelect={picture => setActivePicture(picture.secure_url)}
                />
                <Card className='rds-overflow_visible'>
                    <Header title={product.name} text={product.description} />
                    <div className='rds-m_top__sm'>
                        {showReviewsAverage && avgReview ? (
                            <div className='rds-flexbox align-center'>
                                <Rater
                                    values={PRODUCT_RATING}
                                    initial={avgReview}
                                />
                                <Typography className='rds-m_left__sm rds-m_right__sm'>
                                    ·
                                </Typography>
                                <Typography type={TypographyTypes.H4}>
                                    {product.reviews.count} opiniones
                                </Typography>
                            </div>
                        ) : null}
                        <div>
                            <div className='rds-flexbox align-center'>
                                {priceWithAdditionals > currentVariant.price ? (
                                    <Typography
                                        className='rds-m_right__sm'
                                        type={TypographyTypes.H2}
                                    >
                                        $ {priceWithAdditionals}
                                    </Typography>
                                ) : variantAvailability.priceLabel ? (
                                    <>
                                        <Typography
                                            className='rds-m_right__sm'
                                            type={TypographyTypes.H2}
                                        >
                                            $ {variantAvailability.priceLabel}
                                        </Typography>
                                        {currentVariant.comparedPrice ? (
                                            <Typography
                                                type={TypographyTypes.H3}
                                                variant={
                                                    ThemeVariants.LINE_THROUGH
                                                }
                                                style={{ opacity: 0.5 }}
                                            >
                                                $ {currentVariant.comparedPrice}
                                            </Typography>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                            {showStockLeft ? (
                                <div>
                                    <Typography type={TypographyTypes.H3}>
                                        {variantAvailability.label}
                                    </Typography>
                                    <Typography>
                                        {variantAvailability.description}
                                    </Typography>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div
                        className={`${styles['product-detail_fields']} product-detail_fields rds-full-block rds-m_top__md`}
                    >
                        {customFields.map(field =>
                            field.type === 'ADDITIONALS' ? (
                                <FormItem
                                    key={field._id}
                                    className='product-detail_field rds-m_bottom__md'
                                    id={field.apiName}
                                    label={`${field.name}${
                                        field.maxAdditionals
                                            ? ` (${field.maxAdditionals} máximo)`
                                            : ''
                                    }`}
                                    type={FormItemTypes.MULTI_SELECT}
                                    values={field.activeAdditionals.map(
                                        additional => ({
                                            label: `${additional.name}${
                                                additional.price
                                                    ? ` (+$${additional.price})`
                                                    : ''
                                            }`,
                                            value: additional.name,
                                            id: additional.name,
                                        }),
                                    )}
                                    value={selectedAdditionals[field.apiName]}
                                    onChange={event =>
                                        setSelectedAdditionals({
                                            ...selectedAdditionals,
                                            [field.apiName]: event.target.value,
                                        })
                                    }
                                    max={field.maxAdditionals}
                                    required={field.required}
                                    requiredLabel='campo requerido'
                                    withCounter={field.multipleEntryAdditionals}
                                />
                            ) : (
                                <FormItem
                                    key={field._id}
                                    className='product-detail_field rds-m_bottom__md'
                                    id={field.apiName}
                                    label={field.name}
                                    type={
                                        field.type === 'PICKLIST'
                                            ? selectionMode
                                            : field.type === 'CHECKBOX'
                                            ? FormItemTypes.CHECKBOX
                                            : null
                                    }
                                    values={field.values.map(value => ({
                                        label: value,
                                        id: value,
                                    }))}
                                    value={variantOptions[field.apiName]}
                                    onChange={event =>
                                        handleVariantChange(
                                            event,
                                            field.apiName,
                                        )
                                    }
                                    required={field.required}
                                    requiredLabel='campo requerido'
                                />
                            ),
                        )}
                    </div>
                    <div className='rds-m_bottom__sm rds-full-block'>
                        <FormItem
                            label='Notas'
                            type={FormItemTypes.TEXTAREA}
                            value={productNotes}
                            onChange={e => setProductNotes(e.target.value)}
                            className='rds-full-block rds-m_bottom__md'
                            placeholder='Ingresa tus notas del producto aquí...'
                        />
                        <FormItem
                            className='rds-m_bottom__md'
                            type={FormItemTypes.NUMBER}
                            label='Cantidad'
                            min={1}
                            max={currentVariant.stock}
                            value={quantity}
                            step={1}
                            onChange={event => {
                                setQuantity(event.target.value)
                            }}
                            disabled={isAddToCartDisabled}
                            required
                        />
                        {!hasRequiredFieldsSelected ? (
                            <Typography className='rds-m_bottom__sm rds-text-align_center'>
                                <b>Selecciona todos los campos requeridos</b>
                            </Typography>
                        ) : null}
                        <div className='rds-full-block rds-grid'>
                            <CartButton
                                product={product}
                                variant={currentVariant}
                                disabled={isAddToCartDisabled}
                                additionals={selectedAdditionals}
                                notes={productNotes}
                                quantity={quantity}
                            />
                            <Button
                                icon={IconTypes.HEART}
                                label={
                                    isFavorited
                                        ? 'Quitar de favoritos'
                                        : 'Agregar a favoritos'
                                }
                                filled={isFavorited}
                                onClick={handleFav}
                                variant={ButtonVariants.TRANSPARENT}
                            />
                        </div>
                    </div>
                    {/* @TODO: Use ShareProduct component here */}
                </Card>
                <div></div>
                {showReviewsSection ? (
                    <div className='rds-m_top__sm'>
                        <ProductDetailReviews productId={product._id} />
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default ProductDetail
