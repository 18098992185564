import React from 'react'
import styled from 'styled-components'

const BASE_URI = 'https://www.youtube.com/embed/'
const DEFAULT_ALLOWS = [
    'accelerometer',
    'autoplay',
    'encrypted-media',
    'gyroscope',
    'picture-in-picture',
]

/**
 * Builds a YouTube video URL with specified parameters.
 *
 * @param {string} videoId - The ID of the YouTube video.
 * @param {Object} config - The configuration object.
 * @returns {string} The generated YouTube video URL.
 */
const buildUrl = (videoId, config = {}) => {
    const url = new URL(BASE_URI + videoId)
    url.searchParams.append('autoplay', config.autoPlay ? 1 : 0)
    url.searchParams.append('mute', config.startMuted ? 1 : 0)
    url.searchParams.append('controls', config.showControls ? 1 : 0)
    url.searchParams.append('loop', config.loop ? 1 : 0)
    if (config.loop) {
        url.searchParams.append('playlist', videoId)
    }
    url.searchParams.append('start', config.start)
    url.searchParams.append('end', config.end)
    return url.toString()
}

const StyledYouTubeVideoWrapper = styled.div(props => ({
    '&:after': {
        content: Number(props.overlayOpacity) ? '""' : 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: props.overlayColor,
        opacity: Number(props.overlayOpacity),
    },
    '& iframe': {
        display: 'block',
    },
}))

const YouTubeVideo = ({
    videoId,
    height = 300,
    allowFullScreen,
    autoPlay,
    startMuted,
    showControls,
    loop,
    start,
    end,
    overlayColor,
    overlayOpacity,
}) => {
    const config = {
        autoPlay,
        startMuted,
        showControls,
        loop,
        start,
        end,
    }
    return (
        <StyledYouTubeVideoWrapper
            className='rds-relative'
            width='100%'
            overlayColor={overlayColor}
            overlayOpacity={overlayOpacity}
        >
            <iframe
                width='100%'
                height={height}
                src={buildUrl(videoId, config)}
                title='YouTube video player'
                frameborder={0}
                allow={DEFAULT_ALLOWS.join(';')}
                allowfullscreen={allowFullScreen}
            ></iframe>
        </StyledYouTubeVideoWrapper>
    )
}

export default YouTubeVideo
