import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ThemeVariants, Typography, TypographyTypes } from 'rds'
import Modal from '../Modal'
import LocationSelect from './LocationSelect'
import { setSelectedLocation } from '../../reducers/locationReducer'

const LocationSelector = () => {
    const dispatch = useDispatch()
    const locations = useSelector(state => state.location.locations)
    const hasManyLocations = locations.length > 1
    const [localSelectedLocation, setLocalSelectedLocation] = React.useState()
    const [isModalVisible, setIsModalVisible] = React.useState()
    const selectedLocationFull = locations.find(
        location => location.id === localSelectedLocation,
    )
    const isConfirmDisabled = !selectedLocationFull?.isOpenNow
    const confirmLabel = !localSelectedLocation
        ? 'Seleccione un local'
        : isConfirmDisabled
        ? 'El local se encuentra cerrado'
        : 'Confirmar'

    React.useEffect(() => {
        const hasAnyOpen = !!locations.find(location => location.isOpenNow)
        setIsModalVisible(hasAnyOpen)
    }, [locations])

    const handleChange = event => {
        const { value } = event.target
        setLocalSelectedLocation(value)
    }

    const selectLocation = locationId => {
        dispatch(setSelectedLocation(locationId))
    }

    const handleConfirm = () => {
        selectLocation(localSelectedLocation)
        setIsModalVisible(false)
    }

    if (!hasManyLocations) {
        // If there is only one location, select it automatically
        const uniqueLocation = locations[0]
        selectLocation(uniqueLocation.id)
        return null
    }
    return (
        <Modal visible={isModalVisible}>
            <div>
                <div>
                    <Typography type={TypographyTypes.H2}>
                        Seleccione el local
                    </Typography>
                    <Typography>Puedes cambiarlo más adelante</Typography>
                </div>
                <div className='rds-m_top__md'>
                    <LocationSelect
                        onChange={handleChange}
                        value={localSelectedLocation}
                    />
                </div>
                <Button
                    label={confirmLabel}
                    className='rds-m_top__md rds-full-block rds-flexbox'
                    variant={ThemeVariants.MAIN}
                    onClick={handleConfirm}
                    disabled={isConfirmDisabled}
                />
            </div>
        </Modal>
    )
}

export default LocationSelector
