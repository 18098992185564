import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    ButtonVariants,
    Icon,
    IconTypes,
    Typography,
    TypographyTypes,
    Button,
} from 'rds'
import styles from './Topbar.module.css'

const Session = ({ onClick, fullWidth }) => {
    const headerSettings = useSelector(
        state => state.session.subscription.webstore.settings.header,
        shallowEqual,
    )
    const sessionUser = useSelector(state => state.session.user, shallowEqual)

    return sessionUser._id ? (
        <div
            onClick={onClick}
            className='rds-flexbox align-center nav_user-info'
        >
            <div className='rds-flexbox nav_favorites-icon rds-m_right__md'>
                <Link
                    className='rds-flexbox rds-relative'
                    to='/profile/favorites'
                >
                    <Icon icon={IconTypes.HEART} />
                </Link>
            </div>
            <Link
                to='/profile'
                className='rds-flexbox'
                style={{ marginRight: '2vw' }}
            >
                <div style={{ width: 'max-content' }}>
                    <Typography
                        type={TypographyTypes.H4}
                        variant={headerSettings.text.variant}
                    >
                        <b>{sessionUser.name}</b>
                    </Typography>
                </div>
            </Link>
        </div>
    ) : headerSettings.session.type !== 'NONE' ? (
        <Link
            to='/login'
            className={`rds-flexbox topbar_login-button ${styles['topbar_login-button']}`}
            onClick={onClick}
        >
            {headerSettings.session.type === 'BUTTON' ? (
                <Button
                    label={headerSettings.session.label}
                    variant={ButtonVariants.MAIN}
                    style={fullWidth ? { width: '100%' } : undefined}
                />
            ) : (
                <Icon
                    icon={IconTypes.USER}
                    variant={headerSettings.text.variant}
                />
            )}
        </Link>
    ) : null
}

export default Session
