import { FormItemTypes } from 'rds'
import { HEADER_VARIANTS } from '../../constants'

const props = [
    {
        label: {
            en: 'Text variant',
            es: 'Variante del texto',
        },
        name: 'headerVariant',
        type: FormItemTypes.SELECT,
        enum: HEADER_VARIANTS,
    },
    {
        label: {
            en: 'Use category picture',
            es: 'Usar imagen de Categoría',
        },
        name: 'useCategoryPicture',
        type: FormItemTypes.CHECKBOX,
    },
    {
        label: {
            en: 'Overlay color',
            es: 'Color superpuesto',
        },
        name: 'overlayColor',
        type: 'color',
    },
    {
        label: {
            en: 'Overlay color opacity',
            es: 'Opacidad del color superpuesto',
        },
        name: 'overlayColorAlpha',
        type: 'range',
    },
    {
        label: {
            en: 'Full width',
            es: 'Ancho total',
        },
        name: 'fullWidth',
        type: FormItemTypes.CHECKBOX,
    },
]
export default props
