export const getAddressGoogleMapsSearch = address => {
    if (!address.street || !address.doorNumber) {
        return undefined
    }
    const safeStreet = address.street.replaceAll('.', '+').replaceAll(' ', '+')
    return `${safeStreet}+${address.doorNumber},${
        address.zipCode ? `+${address.zipCode}` : ''
    }+${address.city},+${address.country}`
}

export const getAddressSummary = address => {
    if (!address.street || !address.doorNumber) {
        return undefined
    }
    return `${address.street} ${address.doorNumber}${
        address.apartment ? ` apt. ${address.apartment}` : ''
    }. ${address.city}, ${address.country}`
}

/**
 * Builds a user-friendly distance value
 * @param {Number} distance Distance in meters
 * @returns User-friendly distance label
 */
export const getDistanceLabel = (distance = 0) => {
    let distanceLabel
    if (distance > 1000) {
        distanceLabel = `${Math.round(distance / 1000)} km`
    } else {
        distanceLabel = `${distance} m`
    }
    return distanceLabel
}
