import { DEBUG_MODE } from './index'
const DEBUG_PREFIX = '[ONESIGNAL]'

window.OneSignal = window.OneSignal || []

const doInit = (sessionId, appId) => {
    try {
        if (!appId) {
            console.error(`${DEBUG_PREFIX} An App ID must be provided`)
            return
        }

        window.OneSignal.push(async function () {
            if (DEBUG_MODE) {
                console.log('[ONESIGNAL] App ID:', appId)
            }
            window.OneSignal.init({
                appId,
                persistNotification: false,
                autoResubscribe: true,
                notificationClickHandlerMatch: 'origin',
                allowLocalhostAsSecureOrigin: true,
                notifyButton: {
                    enable: false,
                },
            })

            // Set debug mode
            window.OneSignal.log.setLevel(DEBUG_MODE ? '' : 'none')

            // IMPORTANT: Set user ID
            window.OneSignal.setExternalUserId(sessionId)

            if (DEBUG_MODE) {
                console.log(
                    '[ONESIGNAL] Ext user ID:',
                    await window.OneSignal.getExternalUserId(),
                )
            }

            // Check OneSingal subscription state
            const pushEnabled =
                await window.OneSignal.isPushNotificationsEnabled()
            if (!pushEnabled) {
                if (DEBUG_MODE) {
                    console.warn(
                        `${DEBUG_PREFIX} Push not enabled yet. Asking...`,
                    )
                }
                window.OneSignal.showNativePrompt()
            } else if (DEBUG_MODE) {
                console.log(`${DEBUG_PREFIX} Push already enabled`)
            }

            window.OneSignal.setSubscription(true)

            // Events
            window.OneSignal.on('subscriptionChange', isSubscribed => {
                console.log(
                    DEBUG_PREFIX,
                    "The user's subscription state is now:",
                    isSubscribed,
                )
            })
        })
    } catch (error) {
        console.error(`${DEBUG_PREFIX} Error:`, error)
    }
}

const OneSignal = { doInit }
export default OneSignal
