import {
    cssRules,
    ThemeContext,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledNavigationItem = styled.div`
    ${props => `
        transition: 0.2s box-shadow;
        display: inline-block;
        ${
            props.active
                ? `
            box-shadow: 0 5px 0 0 ${props.themeContext.main};
        `
                : ''
        }
    `}
`

const StyledDropdownPanel = styled.div`
    ${props => `
        ${cssRules.blackBackgroundRule(props)}
        box-shadow: 0 20px 40px -5px ${cssRules.blackValue(
            props.themeContext,
        )}78;
    `}
`

const StyledDropdownPanelSubcategories = styled.div`
    ${props => `
        box-shadow: 60px 0 80px 70px ${cssRules.blackValue(props.themeContext)}
    `}
`

const TopbarNavigationItemLink = ({ item }) => {
    const headerSettings = useSelector(
        state => state.session.subscription.webstore.settings.header,
        shallowEqual,
    )
    return (
        <NavLink
            to={
                item.category
                    ? `/categories/${item.category._id}`
                    : item.page.path
            }
            className={navData =>
                `sidebar-item rds-p_left__sm rds-p_right__sm ${
                    navData.isActive ? 'active' : ''
                }`
            }
            exact='true'
        >
            <Typography
                style={{ display: 'inline-block' }}
                type={TypographyTypes.H3}
                variant={headerSettings.text.variant}
            >
                {item.label || item.category.name}
            </Typography>
        </NavLink>
    )
}

const TopbarNavigationItemCategory = ({ item }) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <>
            <TopbarNavigationItemLink item={item} />
            {item.category.subcategories?.length ? (
                <StyledDropdownPanel
                    themeContext={themeContext}
                    className='sidebar-item_dropdown-panel'
                >
                    <div className='container rds-grid rds-grid_2-columns'>
                        <StyledDropdownPanelSubcategories
                            themeContext={themeContext}
                            className='sidebar-item_dropdown-panel_subcategories rds-p_top__md rds-p_bottom__md'
                        >
                            {item.category.subcategories.map(subc => (
                                <NavLink
                                    to={`/categories/${item.category._id}?subcategories=${subc._id}`}
                                    key={subc._id}
                                    className='rds-m_right__md rds-p_top__sm rds-p_bottom__sm'
                                >
                                    <Typography
                                        type={TypographyTypes.H3}
                                        variant={ThemeVariants.WHITE}
                                    >
                                        {subc.name}
                                    </Typography>
                                </NavLink>
                            ))}
                        </StyledDropdownPanelSubcategories>
                        {item.category.picture ? (
                            <img
                                className='rds-full-block'
                                src={item.category.picture.secure_url}
                                alt={item.category.name}
                            />
                        ) : null}
                    </div>
                </StyledDropdownPanel>
            ) : null}
        </>
    )
}

const TopbarNavigationItem = ({ item = {} }) => {
    const themeContext = React.useContext(ThemeContext)
    const location = useLocation()

    const [active, setActive] = React.useState(false)

    const link = item.category
        ? `/categories/${item.category._id}`
        : item.page.path

    React.useEffect(() => {
        let newState
        if (location.pathname) {
            const splittedLocationPath = location.pathname.split(link)
            if (splittedLocationPath.length > 1 && !splittedLocationPath[1]) {
                newState = true
            } else if (location.hash && link.includes(location.hash)) {
                newState = true
            }
        }
        setActive(newState)
    }, [link, location])

    return (
        <StyledNavigationItem
            themeContext={themeContext}
            className='sidebar-item_wrapper rds-p_top__sm rds-p_bottom__sm rds-m_right__sm'
            active={active}
        >
            {item.category ? (
                <TopbarNavigationItemCategory item={item} />
            ) : (
                <TopbarNavigationItemLink item={item} />
            )}
        </StyledNavigationItem>
    )
}

export default TopbarNavigationItem
