import React from 'react'
import {
    EmptyState,
    HeaderSizes,
    ThemeVariants,
    Block,
    Header,
    Button,
    ButtonVariants,
} from 'rds'
import ProductCard from '../Product/ProductCard'
import { useParams, useSearchParams } from 'react-router-dom'
import api from '../../api'
import styles from './CategoryProducts.module.css'
import ProductCardSkeleton from '../Product/ProductCard.skeleton'

const DEBUG_PREFIX = '[CATEGORY_PRODUCTS]'

const CategoryProducts = ({ columns = 2 }) => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const abortController = React.useMemo(() => new AbortController(), [])
    const [loading, setLoading] = React.useState(false)
    const [products, setProducts] = React.useState([])
    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            abortController.abort()
            mounted.current = false
        }
    }, [abortController])

    const timeout = React.useRef()
    React.useEffect(() => {
        let { categoryId } = params
        // This is to make sure Category ID does not contain any
        // of the filters applied (sometimes happened categoryId="<category-id>?<subcategory-id>")
        categoryId = categoryId.split('?')[0]
        if (!categoryId) return

        // Use query params
        let filterQuery = ''
        if (searchParams) {
            const searchParamsEntries = [...searchParams.entries()]
            searchParamsEntries.forEach(searchParamsEntry => {
                filterQuery += '&'
                const filterKey = searchParamsEntry[0]
                const filterValue = searchParamsEntry[1]
                switch (filterKey) {
                    case 'product_min-price':
                        filterQuery += `price=>=${filterValue}`
                        break

                    case 'product_max-price':
                        filterQuery += `price=<=${filterValue}`
                        break

                    case 'subcategories':
                        filterValue.split(',').forEach(subcatId => {
                            filterQuery += `subcategories[]=${subcatId}`
                        })
                        break

                    default:
                        filterQuery += `customFields.${filterKey}=${filterValue}`
                        break
                }
            })
        }
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(async () => {
            try {
                if (mounted.current) {
                    setLoading(true)
                }
                const fullQuery = `products?category=${categoryId}&state=true${filterQuery}`
                const res = await api.get(fullQuery, {
                    signal: abortController.signal,
                })
                if (mounted.current) {
                    setProducts(res.data.products)
                }
            } catch (error) {
                console.error(DEBUG_PREFIX, error)
            }
            if (mounted.current) {
                setLoading(false)
            }
        }, 500)
    }, [searchParams, params, abortController.signal])

    const handleFilterReset = () => {
        setSearchParams(searchParams => {
            const searchParamKeys = [...searchParams.keys()]
            searchParamKeys.forEach(searchParamKey => {
                searchParams.delete(searchParamKey)
            })
            return searchParams
        })
    }

    const hasFilters = [...searchParams.keys()].length

    return (
        <div>
            {hasFilters ? (
                <Block
                    variant={ThemeVariants.GRAY}
                    className='rds-flexbox align-center justify-between rds-p_around__md rds-p_top__sm rds-p_bottom__sm'
                    roundedBorder
                    bordered
                >
                    <Header
                        title='Estas viendo resultados filtrados'
                        size={HeaderSizes.XSMALL}
                        className='rds-m_right__sm'
                    />
                    <Button
                        label='Quitar filtros'
                        variant={ButtonVariants.TRANSPARENT}
                        onClick={handleFilterReset}
                    />
                </Block>
            ) : null}
            <div>
                {loading || products?.length ? (
                    <div
                        className={`${styles['category-products_list']} ${
                            hasFilters ? 'rds-m_top__sm' : ''
                        } category-products_list rds-grid rds-grid_${columns}-columns`}
                    >
                        {loading
                            ? [...Array(4).keys()].map(i => (
                                  <ProductCardSkeleton key={i} />
                              ))
                            : products.map(p => (
                                  <ProductCard key={p._id} product={p} />
                              ))}
                    </div>
                ) : (
                    <EmptyState
                        alignLeft
                        title='No hay productos que coincidan con tus filtros'
                        message='Restaura los filtros para ver todos los productos de esta categoria'
                    />
                )}
            </div>
        </div>
    )
}

export default CategoryProducts
