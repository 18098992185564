import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
    Button,
    ButtonVariants,
    Card,
    cssRules,
    Label,
    Tag,
    ThemeContext,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import styles from './ActivePurchaseTrackerItem.module.css'
import { SALE_STATUSES } from '../../constants'

const StyledWrapper = styled.div`
    ${props => `
        ${cssRules.borderRadiusRule(props)}
        box-shadow: 0 10px 40px 0 ${cssRules.blackValue(props.themeContext)}70;
    `}
`

const StyledImg = styled.img`
    ${props => `
        ${cssRules.borderRadiusRule(props)}
    `}
`

const ActivePurchaseTrackerItem = ({ sale }) => {
    const themeContext = React.useContext(ThemeContext)
    const [expanded, setExpanded] = React.useState(false)

    const handleViewMoreClick = () => setExpanded(!expanded)

    const getPictures = sale =>
        sale.products.map(product =>
            product.variant.pictures?.length ? (
                <div
                    key={product._id}
                    className={styles['active-purhcase-tracker_product']}
                >
                    {product.amount > 1 ? (
                        <Tag
                            className={
                                styles['active-purhcase-tracker_product-qty']
                            }
                        >
                            {product.amount}
                        </Tag>
                    ) : null}
                    <StyledImg
                        themeContext={themeContext}
                        className='rds-m_left__sm'
                        src={product.variant.pictures[0].secure_url}
                        title={product.master.name}
                        alt={product.variant.sku}
                        height={80}
                        width={80}
                    />
                </div>
            ) : null,
        )

    const getUserFriendlyCustomFieldName = fieldName =>
        `${fieldName.substr(0, 1).toUpperCase()}${fieldName.substr(
            1,
            fieldName.length - 1,
        )}`

    const getSaleStatus = sale => {
        switch (sale.status) {
            case SALE_STATUSES.DRAFT:
                return 'Borrador'

            case SALE_STATUSES.NEW:
                return 'Esperando confirmación'

            case SALE_STATUSES.PAYMENT_RECEIVED:
                return 'En preparación'

            case SALE_STATUSES.CONFIRMED:
                return 'En preparación'

            case SALE_STATUSES.IN_TRANSIT:
                return 'En camino'

            default:
                break
        }
    }

    return (
        <StyledWrapper
            themeContext={themeContext}
            className='active-purchase-tracker container_sm rds-m_bottom__sm'
        >
            <Card variant={ThemeVariants.DARK} className='rds-p_around__md'>
                <div className='rds-flexbox align-center justify-between'>
                    <div>
                        <Typography
                            type={TypographyTypes.P}
                            variant={ThemeVariants.WHITE}
                        >
                            Tu compra con código <b>#{sale.name}</b> está
                        </Typography>
                        <Typography
                            type={TypographyTypes.H2}
                            variant={ThemeVariants.WHITE}
                        >
                            {getSaleStatus(sale)}
                        </Typography>
                    </div>
                    <div
                        className={`active-purchase-tracker_pictures ${
                            expanded
                                ? 'active-purchase-tracker_pictures_hidden'
                                : ''
                        }`}
                    >
                        {getPictures(sale)}
                    </div>
                </div>
                <div
                    className={`rds-m_top__md active-purchase-tracker_details ${
                        expanded
                            ? 'active-purchase-tracker_details_visible'
                            : ''
                    }`}
                >
                    <div>{getPictures(sale)}</div>
                    <div className='rds-m_top__sm rds-grid rds-grid_2-columns'>
                        <div className='rds-m_bottom__sm'>
                            <Label>Productos</Label>
                            {sale.products.map((p, i) => (
                                <div
                                    key={i}
                                    className='rds-full-block rds-m_bottom__sm'
                                >
                                    <Typography
                                        type={TypographyTypes.H3}
                                        variant={ThemeVariants.WHITE}
                                    >
                                        {p.master.name}{' '}
                                        <span style={{ opacity: 0.5 }}>
                                            ({p.variant.sku})
                                        </span>
                                    </Typography>
                                    {p.variant.customFields
                                        ? Object.keys(
                                              p.variant.customFields,
                                          ).map(cf => (
                                              <Typography
                                                  key={cf}
                                                  type={TypographyTypes.P}
                                                  variant={ThemeVariants.WHITE}
                                              >
                                                  {getUserFriendlyCustomFieldName(
                                                      cf,
                                                  )}
                                                  :{' '}
                                                  <b>
                                                      {
                                                          p.variant
                                                              .customFields[cf]
                                                      }
                                                  </b>
                                              </Typography>
                                          ))
                                        : null}
                                </div>
                            ))}
                        </div>
                        <div className='rds-m_bottom__sm'>
                            <Label>Costo total</Label>
                            <Typography
                                type={TypographyTypes.H3}
                                variant={ThemeVariants.WHITE}
                            >
                                $ {sale.totalPriceSnapshot}
                            </Typography>
                        </div>
                        {sale.mpPaymentDetails && sale.mpPaymentDetails.card ? (
                            <div className='rds-m_bottom__sm'>
                                <Label>Metodo de pago</Label>
                                <Typography
                                    type={TypographyTypes.H3}
                                    variant={ThemeVariants.WHITE}
                                >
                                    **** **** ****{' '}
                                    {
                                        sale.mpPaymentDetails.card
                                            .last_four_digits
                                    }
                                </Typography>
                            </div>
                        ) : null}
                        <div className='rds-m_bottom__sm'>
                            <Label>Fecha</Label>
                            <Typography
                                type={TypographyTypes.H3}
                                variant={ThemeVariants.WHITE}
                            >
                                {new Date(sale.createdAt).toLocaleDateString()}
                            </Typography>
                        </div>
                        <div className='rds-m_bottom__sm'>
                            <Label>Dirección de envío</Label>
                            <Typography
                                type={TypographyTypes.H3}
                                variant={ThemeVariants.WHITE}
                            >
                                {sale.address || '-'}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='rds-flexbox align-center justify-between rds-m_top__sm'>
                    <Typography
                        type={TypographyTypes.P}
                        variant={ThemeVariants.WHITE}
                        style={{ opacity: 0.7 }}
                    >
                        {sale.mpPaymentDetails
                            ? `ID de transaccion de MercadoPago: ${sale.mpPaymentDetails.id}`
                            : ''}
                    </Typography>
                    <div>
                        <Link
                            to={`/payment-success?collection_id=${
                                sale.mpPaymentDetails
                                    ? sale.mpPaymentDetails.id
                                    : ''
                            }&purchase_id=${sale._id}`}
                            className='rds-m_right__md'
                        >
                            <Button
                                label='Ver detalles'
                                variant={ButtonVariants.TRANSPARENT}
                                onClick={handleViewMoreClick}
                            />
                        </Link>
                        <Button
                            label={`Ver ${expanded ? 'menos' : 'más'}`}
                            variant={ButtonVariants.MAIN}
                            onClick={handleViewMoreClick}
                        />
                    </div>
                </div>
            </Card>
        </StyledWrapper>
    )
}

export default ActivePurchaseTrackerItem
