import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import {
    Block,
    Button,
    ButtonVariants,
    IconTypes,
    Tag,
    ThemeContext,
    ThemeVariants,
    Typography,
    cssRules,
} from 'rds'
import { getPictureThumbnail } from '../../../../utils/file'

const StyledBackground = styled.div(props => ({
    height: '100%',
    minHeight: 200,
    backgroundRepeat: 'no-repeat',
    backgroundColor: cssRules.blackValue(props),
    backgroundPosition: props.settings.picturesPosition,
    backgroundSize:
        props.settings.picturesType === 'UNCROPPED' ? 'contain' : 'cover',
    backgroundImage: props.product.pictures?.length
        ? `url(${getPictureThumbnail(
              props.product.pictures[0].secure_url,
              500,
          )})`
        : null,
    '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        opacity: `${props.settings.overlayOpacity || 0}%`,
        zIndex: 1,
    },
}))

const StyledBackgroundInner = styled.div({
    zIndex: 2,
    height: '100%',
})

const StyledBackgroundInnerDetails = styled.div({
    width: '100%',
})

const Background = ({ product, onFavClick, nameTypographyType }) => {
    const themeContext = React.useContext(ThemeContext)
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const productCardSettings = useSelector(
        state => state.session.subscription.webstore.settings.products,
        shallowEqual,
    )
    return (
        <Link to={`/products/${product._id}`}>
            <Block
                bordered
                className='product-card product-card_background-variant rds-overflow_hidden rds-relative'
                roundedBorder
            >
                <StyledBackground
                    themeContext={themeContext}
                    className='product-card_background-variant_background rds-p_around__md'
                    product={product}
                    settings={productCardSettings}
                >
                    <StyledBackgroundInner className='product-card_background-variant_background-inner rds-flexbox justify-end direction-column rds-relative'>
                        <StyledBackgroundInnerDetails className='product-card_background-variant_background-inner_details rds-absolute'>
                            <Typography
                                type={nameTypographyType}
                                variant={ThemeVariants.WHITE}
                                className='product-card_name rds-overflow_hidden rds-nowrap'
                            >
                                {product.name}
                            </Typography>
                            {productCardSettings.showDescription ? (
                                <Typography
                                    variant={ThemeVariants.WHITE}
                                    className='product-card_description rds-overflow_hidden rds-nowrap'
                                >
                                    {product.description}
                                </Typography>
                            ) : null}
                            {productCardSettings.showNumberOfVariants &&
                            product.variants?.length > 1 ? (
                                <Typography variant={ThemeVariants.WHITE}>
                                    {product.variants.length} variantes
                                </Typography>
                            ) : null}
                            <div className='rds-full-block rds-flexbox justify-between align-center'>
                                {productCardSettings.showPrice ? (
                                    <Tag
                                        className='rds-m_top__sm rds-nowrap'
                                        variant={ThemeVariants.DARK}
                                    >
                                        $ {product.minPrice}
                                        {product.minPrice !== product.maxPrice
                                            ? `  -  $ ${product.maxPrice}`
                                            : ''}
                                    </Tag>
                                ) : null}
                                {sessionUser._id &&
                                productCardSettings.showFavButton ? (
                                    <Button
                                        className='rds-m_top__sm rds-m_left__sm'
                                        variant={ButtonVariants.DARK}
                                        icon={IconTypes.HEART}
                                        onClick={onFavClick}
                                        filled={sessionUser.favorites?.includes(
                                            product._id,
                                        )}
                                    />
                                ) : null}
                            </div>
                        </StyledBackgroundInnerDetails>
                    </StyledBackgroundInner>
                </StyledBackground>
            </Block>
        </Link>
    )
}

export default Background
