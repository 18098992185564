import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Block, Header, Typography, TypographyTypes } from 'rds'
import api from '../../api'
import { getPictureThumbnail } from '../../utils/file'
import './FeaturedProduct.css'

const FeaturedProduct = ({
    productId,
    showPicture,
    showPrice,
    headerText,
    buttonLabel = 'Ir a verlo',
    margin,
    padding,
    roundedBorder,
    bordered,
}) => {
    const [product, setProduct] = React.useState({})

    const fetchProduct = async productId => {
        try {
            const res = await api.get(`products/${productId}`)
            setProduct(res.data.product)
        } catch (error) {
            console.error('[FEATURED_PRODUCT]')
        }
    }

    React.useEffect(() => {
        if (!productId) return
        fetchProduct(productId)
    }, [productId])

    return (
        <Block
            className='featured-product rds-flexbox align-center justify-between rds-overflow_hidden'
            style={{
                margin: margin ? `${margin}px` : undefined,
                padding: padding ? `${padding}px` : undefined,
            }}
            roundedBorder={roundedBorder}
            bordered={bordered}
        >
            <div className='featured-product_inner rds-full-block rds-grid rds-grid_2-columns'>
                {showPicture && product.pictures?.length ? (
                    <img
                        className='featured-product_picture'
                        src={getPictureThumbnail(
                            product.pictures[0].secure_url,
                            500,
                        )}
                        alt={product.name}
                    />
                ) : null}
                <div className='featured-product_header-container rds-p_around__md rds-full-block'>
                    <Header
                        title={product.name}
                        text={headerText || product.description}
                    />
                    {showPrice ? (
                        <div>
                            <Typography
                                className='rds-m_top__sm'
                                type={TypographyTypes.H2}
                            >
                                $ {product.minPrice}
                            </Typography>
                        </div>
                    ) : null}
                    <Link to={`products/${productId}`}>
                        <Button className='rds-m_top__md' label={buttonLabel} />
                    </Link>
                </div>
            </div>
        </Block>
    )
}

export default FeaturedProduct
