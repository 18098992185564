import { FormItemTypes } from 'rds'
import { CLOUDINARY_EFFECTS, POSITIONING_ENUM } from '../../constants'

const props = [
    {
        label: {
            en: 'Image',
            es: 'Imagen',
        },
        name: 'src',
        type: FormItemTypes.FILES_PICKER,
        category: {
            en: 'Image',
            es: 'Imagen',
        },
    },
    {
        label: {
            en: 'Effect',
            es: 'Efecto',
        },
        tooltip: {
            en: 'Apply a visual effect to the image, it can take a few seconds to load',
            es: 'Aplica un efecto visual a la imagen, puede tardar unos segundos en cargar',
        },
        name: 'effect',
        type: FormItemTypes.SELECT,
        enum: Object.keys(CLOUDINARY_EFFECTS),
        usesAI: true,
        category: {
            en: 'Image',
            es: 'Imagen',
        },
    },
    {
        label: {
            en: 'Alternative text',
            es: 'Texto alternativo',
        },
        tooltip: {
            en: 'This text is used by screen readers and is good for SEO',
            es: 'Este texto es usado por lectores de pantallas y es bueno para el SEO',
        },
        name: 'alt',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Navigation',
            es: 'Navegación',
        },
    },
    {
        label: {
            en: 'Height',
            es: 'Alto',
        },
        tooltip: {
            en: 'If not specified, the image will take the original height',
            es: 'Si no se especifica, la imagen tomará el alto original',
        },
        name: 'height',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Dimensions',
            es: 'Dimensiones',
        },
    },
    {
        label: {
            en: 'Fit type',
            es: 'Tipo de encaje',
        },
        tooltip: {
            en: 'Determines whether the image is going to be cropped or not',
            es: 'Determina si la imagen se va a recortar o no',
        },
        name: 'objectFit',
        type: FormItemTypes.SELECT,
        enum: ['cover', 'contain'],
        category: {
            en: 'Dimensions',
            es: 'Dimensiones',
        },
    },
    {
        label: {
            en: 'Image position',
            es: 'Posición de la imagen',
        },
        name: 'objectPosition',
        type: FormItemTypes.SELECT,
        enum: POSITIONING_ENUM,
        category: {
            en: 'Dimensions',
            es: 'Dimensiones',
        },
    },
    {
        label: {
            en: 'Navigate to URL',
            es: 'URL de Navegación',
        },
        tooltip: {
            en: 'If specified, the image will be clickable and will navigate to the specified URL',
            es: 'Si se especifica, la imagen será clickeable y navegará a la URL especificada',
        },
        name: 'navigateToURL',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Navigation',
            es: 'Navegación',
        },
    },
    {
        label: {
            en: 'Margin',
            es: 'Márgen',
        },
        name: 'margin',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'General aspect',
            es: 'Aspecto general',
        },
    },
    {
        label: {
            en: 'Rounded borders',
            es: 'Bordes redondeados',
        },
        name: 'roundedBorder',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'General aspect',
            es: 'Aspecto general',
        },
    },
    {
        label: {
            en: 'Bordered',
            es: 'Bordeado',
        },
        name: 'bordered',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'General aspect',
            es: 'Aspecto general',
        },
    },
]

export default props
