import { Label, Typography, TypographyTypes } from 'rds'
import React from 'react'

const PaymentDetailsItem = ({ label, value }) => {
    return (
        <div className='rds-m_bottom__md'>
            <Label>{label}</Label>
            <Typography type={TypographyTypes.P}>{value}</Typography>
        </div>
    )
}

export default PaymentDetailsItem
