import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Items',
            es: 'Ítems',
        },
        name: 'items',
        type: 'SLIDER_BUILDER',
    },
    {
        label: {
            en: 'Height',
            es: 'Altura',
        },
        name: 'height',
        type: FormItemTypes.NUMBER,
        min: 0,
        step: 0.1,
        category: {
            en: 'General theme',
            es: 'Aparienca general',
        },
    },
    {
        label: {
            en: 'Padding',
            es: 'Relleno',
        },
        name: 'padding',
        type: FormItemTypes.NUMBER,
        min: 0,
        step: 0.1,
        category: {
            en: 'General theme',
            es: 'Aparienca general',
        },
    },
    {
        label: {
            en: 'Margin',
            es: 'Margen',
        },
        name: 'margin',
        type: FormItemTypes.NUMBER,
        min: 0,
        step: 0.1,
        category: {
            en: 'General theme',
            es: 'Aparienca general',
        },
    },
    {
        label: {
            en: 'Transition speed (seconds)',
            es: 'Velocidad de transición (segundos)',
        },
        name: 'transitionSpeed',
        type: FormItemTypes.NUMBER,
        min: 0.1,
        step: 0.1,
    },
    {
        label: {
            en: 'Miliseconds per page',
            es: 'Milisegundos por página',
        },
        name: 'milisecondsPerPage',
        type: FormItemTypes.NUMBER,
        min: 1,
    },
]
export default props
