import { createSlice } from '@reduxjs/toolkit'
import api from '../api'

export const fieldSlice = createSlice({
    name: 'field',
    initialState: {
        isFetching: false,
        error: null,
        fields: [],
    },
    reducers: {
        setStart: state => {
            state.isFetching = true
            state.error = null
        },
        setEnd: state => {
            state.isFetching = false
            state.error = null
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setFields: (state, action) => {
            state.fields = action.payload
        },
    },
})

export const { setError, setStart, setEnd, setFields } = fieldSlice.actions

export const fetchCustomFields = () => async dispatch => {
    try {
        dispatch(setStart())
        const res = await api.get('fields?visibleInWebstore=true')
        dispatch(setFields(res.data.fields))
    } catch (error) {
        console.error('[FIELDS]', error.response)
        dispatch(setError(error))
    } finally {
        dispatch(setEnd())
    }
}

export default fieldSlice.reducer
