import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Form title',
            es: 'Titulo del formulario',
        },
        name: 'formTitle',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Form text',
            es: 'Texto del formulario',
        },
        name: 'formText',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Submit button label',
            es: 'Texto del boton de enviar',
        },
        name: 'submitLabel',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Require name',
            es: 'Requerir nombre',
        },
        name: 'nameRequired',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Behavior',
            es: 'Comportamiento',
        },
    },
    {
        label: {
            en: 'Require email address',
            es: 'Requerir email',
        },
        name: 'emailRequired',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Behavior',
            es: 'Comportamiento',
        },
    },
    {
        label: {
            en: 'Submit message',
            es: 'Mensaje al enviar',
        },
        name: 'submitMessage',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Behavior',
            es: 'Comportamiento',
        },
    },
]
export default props
