import React from 'react'
import { useSelector } from 'react-redux'
import {
    Card,
    Header,
    HeaderSizes,
    TableItem,
    Tag,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import api from '../../api'
import { Link } from 'react-router-dom'

const MyCasesItem = ({ caseDoc, position, totalCases }) => {
    return (
        <TableItem key={caseDoc._id} noLine={position === totalCases - 1}>
            <Link to={`/cases/${caseDoc._id}`}>
                <Typography
                    type={TypographyTypes.P}
                    variant={ThemeVariants.MAIN}
                >
                    <b>#{caseDoc.name}</b>
                </Typography>
            </Link>
            <Typography type={TypographyTypes.H4}>
                {caseDoc.description}
            </Typography>
            <Tag
                className='rds-m_top__sm'
                variant={
                    caseDoc.status === 'CLOSED' ? ThemeVariants.DARK : undefined
                }
            >
                {caseDoc.status}
            </Tag>
        </TableItem>
    )
}

const MyCases = ({ title = 'Mis Casos', showClosed = true }) => {
    const sessionUserId = useSelector(state => state.session.user._id)
    const [cases, setCases] = React.useState([])
    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    React.useEffect(() => {
        if (!sessionUserId) return
        api.get(`cases?createdBy=${sessionUserId}`)
            .then(res => {
                if (mounted.current) {
                    setCases(res.data.cases)
                }
            })
            .catch(err => console.error('[MY_CASES]', err.response))
    }, [sessionUserId])

    if (!cases.length) {
        return null
    }
    return (
        <div className='container_sm'>
            <Header
                title={title}
                size={HeaderSizes.MEDIUM}
                className='rds-m_left__sm'
            />
            <Card className='rds-m_top__sm'>
                {cases
                    .filter(c => c.status !== 'CLOSED')
                    .map((c, i) => (
                        <MyCasesItem
                            caseDoc={c}
                            position={i}
                            totalCases={cases.length}
                        />
                    ))}
            </Card>
            {showClosed ? (
                <Card className='rds-m_top__sm'>
                    {cases
                        .filter(c => c.status === 'CLOSED')
                        .map((c, i) => (
                            <MyCasesItem
                                caseDoc={c}
                                position={i}
                                totalCases={cases.length}
                            />
                        ))}
                </Card>
            ) : null}
        </div>
    )
}

export default MyCases
