import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import {
    FormItem,
    Button,
    FormItemTypes,
    ThemeVariants,
    Block,
    cssRules,
    ThemeContext,
    Typography,
    TypographyTypes,
    Label,
    Icon,
    FormSection,
    ButtonVariants,
} from 'rds'
import api from '../../api'
import { DEBUG_MODE } from '../../utils'
import { cartClearCart } from '../../reducers/cartReducer'
import { setSelectedLocation } from '../../reducers/locationReducer'
import { getMPCheckoutItems } from '../../utils/product'
import { SALE_PAYMENT_TYPES } from '../../constants'
import DeliverFormAddressSelector from './DeliverFormAddressSelector'
import Container from '../../layout/Container'
import LocationSelect from '../../layout/Locations/LocationSelect'
import CheckoutFormOptionLabel from './CheckoutFormOptionLabel'

const DEBUG_PREFIX = '[CHECKOUT_FORM]'
const PAYMENT_METHOD_ICON_SIZE = 25

const CheckoutForm = ({ noShipmentLabel }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cartEntries = useSelector(state => state.cart.entries, shallowEqual)
    const session = useSelector(state => state.session, shallowEqual)
    const locations = useSelector(state => state.location.locations)
    const selectedLocationId = useSelector(
        state => state.location.selectedLocationId,
    )
    const themeContext = React.useContext(ThemeContext)
    const [deliverTypes, setDeliverTypes] = React.useState([])
    const [paymentTypes, setPaymentTypes] = React.useState([])
    const [personalInformation, setPersonalInformation] = React.useState({
        name: '',
        email: '',
        phone: '',
        phonePrefix: '+598',
    })
    const [isDeliver, setIsDeliver] = React.useState(true)
    const [checkoutData, setCheckoutData] = React.useState({
        onSitePayment: {
            payingWithCashAmount: '',
        },
    })
    const [isLoading, setIsLoading] = React.useState()
    const [subTotal, setSubTotal] = React.useState(0)
    const [calculatedShipmentCost, setCalculatedShipmentCost] = React.useState()
    const [formErrorMessages, setFormErrorMessages] = React.useState({})
    const [submitError, setSubmitError] = React.useState()
    const [shipmentError, setShipmentError] = React.useState()
    const [cashAmountError, setCashAmountError] = React.useState()
    const me = React.useRef()
    const submitButton = React.useRef()
    const isAuthenticated = !!session?.user?._id

    // Location
    const selectedLocation = locations.find(
        location => location.id === selectedLocationId,
    )
    const isSelectedLocationOpen = selectedLocation?.isOpenNow
    const doesSelectedLocationAcceptShipments =
        !!selectedLocation?.shipmentCosts.length
    const onlinePaymentMethods = selectedLocation?.paymentMethods?.online
    const onsitePaymentMethods = selectedLocation?.paymentMethods?.onsite

    // Submit state
    const error =
        (isDeliver && shipmentError) ||
        cashAmountError ||
        (!isAuthenticated &&
            !personalInformation.email &&
            !personalInformation.phone &&
            'Debes ingresar correo electrónico o teléfono') ||
        (selectedLocationId &&
            !isSelectedLocationOpen &&
            'El local seleccionado se encuentra cerrado')
    const isSubmitDisabled =
        (isDeliver && !(checkoutData.street && checkoutData.doorNumber)) ||
        error ||
        isLoading ||
        !cartEntries.length ||
        (!isAuthenticated &&
            !(
                personalInformation.name &&
                (personalInformation.email || personalInformation.phone)
            )) ||
        !checkoutData.paymentType ||
        (checkoutData.paymentType === SALE_PAYMENT_TYPES.ONSITE &&
            !checkoutData.onSitePayment.method) ||
        !isSelectedLocationOpen
    const total = subTotal + ((isDeliver && calculatedShipmentCost) || 0)
    const timeout = React.useRef()

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    React.useEffect(() => {
        setFormErrorMessages({})
    }, [personalInformation])

    React.useEffect(() => {
        setCheckoutData(checkoutData => ({
            ...checkoutData,
            location: selectedLocationId,
        }))
    }, [selectedLocationId])

    React.useEffect(() => {
        const newDeliverTypes = [
            {
                id: 'take-away',
                label: (
                    <CheckoutFormOptionLabel
                        icon='Application'
                        label='Retiro en local'
                    />
                ),
                description: 'Retiras en la dirección del local seleccionado',
            },
        ]
        if (doesSelectedLocationAcceptShipments) {
            newDeliverTypes.push({
                id: 'deliver',
                label: (
                    <CheckoutFormOptionLabel
                        icon='Home'
                        label='Envío a domicilio'
                    />
                ),
                description:
                    'Enviaremos tu compra a la dirección que especifiques',
            })
        } else {
            setIsDeliver(false)
        }
        setDeliverTypes(newDeliverTypes)
    }, [doesSelectedLocationAcceptShipments])

    React.useEffect(() => {
        const newPaymentTypes = []
        if (onsitePaymentMethods?.length) {
            newPaymentTypes.push({
                label: (
                    <CheckoutFormOptionLabel
                        icon='Swipe'
                        label='Pago en la entrega'
                    />
                ),
                description: 'Realizar el pago al momento de la entrega',
                id: SALE_PAYMENT_TYPES.ONSITE,
                value: SALE_PAYMENT_TYPES.ONSITE,
            })
        }
        if (onlinePaymentMethods?.length) {
            newPaymentTypes.push({
                label: (
                    <CheckoutFormOptionLabel
                        icon='BankTransfer'
                        label='Pago online'
                    />
                ),
                description: 'Serás redirigido a MercadoPago',
                id: SALE_PAYMENT_TYPES.ONLINE,
                value: SALE_PAYMENT_TYPES.ONLINE,
            })
        }
        setCheckoutData(checkoutData => {
            checkoutData.paymentType = newPaymentTypes[0]?.value
            checkoutData.onSitePayment = {}
            return { ...checkoutData }
        })
        setPaymentTypes(newPaymentTypes)
    }, [onlinePaymentMethods, onsitePaymentMethods])

    /**
     * Calculates the sub-total price of the purchase
     * just for UI purposes
     */
    React.useEffect(() => {
        getMPCheckoutItems(cartEntries).then(saleItems => {
            let currentSubTotal = 0
            saleItems.forEach(
                saleItem =>
                    (currentSubTotal +=
                        saleItem.unit_price * saleItem.quantity),
            )
            setSubTotal(currentSubTotal)
        })
    }, [cartEntries])

    React.useEffect(() => {
        if (DEBUG_MODE) {
            console.log(DEBUG_PREFIX, 'Updated form:', checkoutData)
        }
    }, [checkoutData])

    React.useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        if (!isDeliver || !checkoutData.street || !checkoutData.doorNumber) {
            return
        }
        timeout.current = setTimeout(async () => {
            try {
                setShipmentError()
                setIsLoading(true)
                const res = await api.get(
                    `webstore/shipment-costs/search?street=${
                        checkoutData.street
                    }&doorNumber=${checkoutData.doorNumber}${
                        checkoutData.zipCode
                            ? `&zipCode=${checkoutData.zipCode}`
                            : ''
                    }&city=${checkoutData.city}&country=${
                        checkoutData.country
                    }${
                        checkoutData.location
                            ? `&location=${checkoutData.location}`
                            : ''
                    }`,
                )
                const { webstoreShipmentCost } = res.data
                if (DEBUG_MODE) {
                    console.log(
                        DEBUG_PREFIX,
                        'Matching Shipment Cost:',
                        webstoreShipmentCost,
                    )
                }
                if (webstoreShipmentCost) {
                    setCalculatedShipmentCost(webstoreShipmentCost.cost)
                } else {
                    setCalculatedShipmentCost()
                    setShipmentError(
                        noShipmentLabel ||
                            'El local seleccionado no realiza entregas a la dirección',
                    )
                }
            } catch (error) {
                console.error(
                    DEBUG_PREFIX,
                    'Error getting shipment cost',
                    error.response?.data,
                )
                setShipmentError(
                    'No pudimos encontrar la dirección que ingresaste',
                )
            } finally {
                setIsLoading(false)
            }
        }, 500)
    }, [
        noShipmentLabel,
        isDeliver,
        checkoutData.street,
        checkoutData.doorNumber,
        checkoutData.zipCode,
        checkoutData.city,
        checkoutData.country,
        checkoutData.location,
    ])

    React.useEffect(() => {
        setCashAmountError()
        if (
            checkoutData.onSitePayment.payingWithCashAmount?.length &&
            checkoutData.onSitePayment.method === 'CASH' &&
            Number(checkoutData.onSitePayment.payingWithCashAmount) < total
        ) {
            setCashAmountError(
                'El monto de efectivo no puede ser menor al total',
            )
        }
    }, [
        checkoutData.onSitePayment.method,
        checkoutData.onSitePayment.payingWithCashAmount,
        total,
    ])

    const handleAddressChange = (key, value) => {
        // Prevent unnecesary updates
        if (checkoutData[key] !== value) {
            setCheckoutData(checkoutData => ({
                ...checkoutData,
                [key]: value,
            }))
        }
    }

    const handleDeliverTypeChange = event => {
        const isDeliverSelected = event.target.value === 'deliver'
        if (!isDeliverSelected) {
            // If it's pickup, erase any shipment state
            setShipmentError()
            setCheckoutData(checkoutData => ({
                ...checkoutData,
                street: '',
                doorNumber: '',
            }))
        }
        setIsDeliver(isDeliverSelected)
    }

    const handlePersonalInformationChange = (key, value) => {
        setPersonalInformation(personalInformation => ({
            ...personalInformation,
            [key]: value,
        }))
    }

    const getCheckoutButtonLabel = () => {
        let label
        if (isLoading) {
            label = 'Espere unos segundos...'
        } else if (checkoutData.paymentType === SALE_PAYMENT_TYPES.ONLINE) {
            label = 'Pagar ahora'
        } else {
            label = 'Finalizar compra'
        }
        return label
    }

    const getOnsitePaymentMethodOptions = () => {
        const onsitePaymentMethodOptions = []
        if (onsitePaymentMethods.includes('CASH')) {
            onsitePaymentMethodOptions.push({
                label: 'Efectivo',
                value: 'CASH',
                id: 'CASH',
                description: (
                    <div className='rds-m_top__sm'>
                        <Icon
                            type='PaperMoneyTwo'
                            size={PAYMENT_METHOD_ICON_SIZE}
                        />
                    </div>
                ),
            })
        }
        const acceptsVISA = onsitePaymentMethods.includes('VISA')
        const acceptsMASTER = onsitePaymentMethods.includes('MASTERCARD')
        if (acceptsVISA || acceptsMASTER) {
            onsitePaymentMethodOptions.push({
                label: 'POS',
                value: 'POS',
                id: 'POS',
                description: (
                    <div className='rds-m_top__sm rds-flexbox align-center'>
                        {acceptsVISA ? (
                            <img
                                className='rds-m_right__sm'
                                src='/img/visa.svg'
                                alt='VISA'
                                height={PAYMENT_METHOD_ICON_SIZE}
                                width={PAYMENT_METHOD_ICON_SIZE}
                                style={{
                                    objectFit: 'contain',
                                    objectPosition: 'left',
                                }}
                            />
                        ) : null}
                        {acceptsMASTER ? (
                            <img
                                className='rds-m_right__sm'
                                src='/img/mastercard.svg'
                                alt='MasterCard'
                                height={PAYMENT_METHOD_ICON_SIZE}
                                width={PAYMENT_METHOD_ICON_SIZE}
                                style={{
                                    objectFit: 'contain',
                                    objectPosition: 'left',
                                }}
                            />
                        ) : null}
                    </div>
                ),
            })
        }
        const acceptsMercadoPago = onsitePaymentMethods.includes('MERCADOPAGO')
        if (acceptsMercadoPago) {
            onsitePaymentMethodOptions.push({
                label: 'MercadoPago (QR)',
                value: 'MERCADOPAGO',
                id: 'MERCADOPAGO',
                description: (
                    <img
                        alt='MercadoPago'
                        src='/img/mercadopago-logo.png'
                        height={PAYMENT_METHOD_ICON_SIZE}
                        width={PAYMENT_METHOD_ICON_SIZE}
                        style={{ objectFit: 'contain', objectPosition: 'left' }}
                    />
                ),
            })
        }
        const acceptsMoneyTransfer =
            onsitePaymentMethods.includes('MONEY_TRANSFER')
        if (acceptsMoneyTransfer) {
            onsitePaymentMethodOptions.push({
                label: 'Transferencia',
                value: 'MONEY_TRANSFER',
                id: 'MONEY_TRANSFER',
                description: (
                    <div className='rds-m_top__sm'>
                        <Icon type='Exchange' size={PAYMENT_METHOD_ICON_SIZE} />
                    </div>
                ),
            })
        }
        return onsitePaymentMethodOptions
    }

    const handleCheckout = async event => {
        try {
            event.preventDefault()
            setSubmitError()
            setIsLoading(true)

            let contactId
            const phoneNumber = personalInformation.phone
                ? personalInformation.phonePrefix + personalInformation.phone
                : null

            // Check authentication status
            if (!session.user._id) {
                // Create Contact with the given details (or use an existing one)
                let alreadyExistingContact
                if (personalInformation.email || phoneNumber) {
                    const isSearchByPhone = !personalInformation.email
                    let getContactEndpoint = `contacts?`
                    if (isSearchByPhone) {
                        // Use "$" for end-with kind of search
                        // this is not ideal but search is impossible without it
                        getContactEndpoint += `phone=$${encodeURIComponent(
                            phoneNumber,
                        )}`
                    } else {
                        getContactEndpoint += `email=${encodeURIComponent(
                            personalInformation.email,
                        )}`
                    }
                    // First check if there is an email matching
                    const alreadyExistingContactRes = await api.get(
                        getContactEndpoint,
                    )
                    const results = alreadyExistingContactRes.data.contacts
                    if (results?.length) {
                        if (isSearchByPhone) {
                            for (
                                let index = 0;
                                index < results.length;
                                index++
                            ) {
                                const result = results[index]
                                // Look for exact phone match
                                if (result.phone === phoneNumber) {
                                    alreadyExistingContact = result
                                    break
                                }
                            }
                        } else {
                            // If there is a matching, assume it is the same user
                            alreadyExistingContact = results[0]
                        }
                    }
                }
                if (alreadyExistingContact) {
                    const getFullContactRes = await api.get(
                        `contacts/` + alreadyExistingContact.id,
                    )
                    if (getFullContactRes.data.contact) {
                        alreadyExistingContact = getFullContactRes.data.contact
                    }
                    // Populate Contact information that might be missing from original
                    // Contact document, like phone number, name, etc.
                    const isNameDifferent =
                        alreadyExistingContact.name !== personalInformation.name
                    const isPhoneDifferent =
                        phoneNumber &&
                        alreadyExistingContact.phone !== phoneNumber
                    if (isNameDifferent || isPhoneDifferent) {
                        const updatedContactInformation = {}
                        if (isNameDifferent) {
                            updatedContactInformation.name =
                                personalInformation.name
                        }
                        if (isPhoneDifferent) {
                            updatedContactInformation.phone = phoneNumber
                        }
                        if (DEBUG_MODE) {
                            console.log(
                                DEBUG_PREFIX,
                                'Updating Contact with:',
                                updatedContactInformation,
                            )
                        }
                        if (alreadyExistingContact.user?.id) {
                            await api.put(
                                `users/${alreadyExistingContact.user.id}`,
                                updatedContactInformation,
                            )
                        } else {
                            await api.put(
                                `contacts/${alreadyExistingContact._id}`,
                                updatedContactInformation,
                            )
                        }
                    }
                    contactId = alreadyExistingContact._id
                } else {
                    const contactData = {
                        ...personalInformation,
                        phone: phoneNumber,
                    }
                    if (DEBUG_MODE) {
                        console.log(
                            DEBUG_PREFIX,
                            'Creating Contact with:',
                            contactData,
                        )
                    }
                    const contactRes = await api.post('contacts', contactData)
                    contactId = contactRes.data.contact._id
                }
            } else {
                contactId = session.user.contact._id || session.user.contact
            }

            const saleBasePayload = {
                contact: contactId,
                location: checkoutData.location,
                products: cartEntries.map(cartEntry => ({
                    master: cartEntry.item._id || cartEntry.item,
                    variant: cartEntry.variant._id || cartEntry.variant,
                    additionals: cartEntry.additionals,
                    amount: Number(cartEntry.quantity),
                    notes: cartEntry.notes,
                })),
                paymentType: checkoutData.paymentType,
                onSitePayment:
                    checkoutData.paymentType === SALE_PAYMENT_TYPES.ONSITE
                        ? {
                              method: checkoutData.onSitePayment.method,
                              payingWithCashAmount:
                                  checkoutData.onSitePayment
                                      .payingWithCashAmount,
                          }
                        : undefined,
            }

            if (isDeliver) {
                saleBasePayload.street = checkoutData.street
                saleBasePayload.doorNumber = checkoutData.doorNumber
                saleBasePayload.apartment = checkoutData.apartment
                saleBasePayload.zipCode = checkoutData.zipCode
                saleBasePayload.city = checkoutData.city
                saleBasePayload.country = checkoutData.country
                saleBasePayload.notes = checkoutData.notes
            }

            if (DEBUG_MODE) {
                console.log(DEBUG_PREFIX, 'Sale to create:', saleBasePayload)
            }
            const res = await api.post('/sales', saleBasePayload)

            if (checkoutData.paymentType === SALE_PAYMENT_TYPES.ONLINE) {
                // Redirect to MP checkout
                window.location.assign(res.data.paymentLink)
            } else if (checkoutData.paymentType === SALE_PAYMENT_TYPES.ONSITE) {
                // If is unauthenticated purhcase, clean LS cart
                if (!session.user._id) {
                    dispatch(cartClearCart())
                }
                navigate(`/payment-success?purchase_id=${res.data.sale._id}`)
            }
        } catch (error) {
            if (DEBUG_MODE) {
                console.error(DEBUG_PREFIX, error)
            }
            let errorMessage = error.response?.data?.message
            if (error.response?.data?.errors) {
                errorMessage = ''
                for (const errorKey in error.response.data.errors) {
                    const errorValue = error.response.data.errors[errorKey]
                    if (errorValue?.message) {
                        errorMessage += '\n' + errorValue.message
                    }
                }
            }
            setSubmitError(errorMessage)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmitClick = () => {
        submitButton.current.click()
    }

    return (
        <form
            className='checkout-form rds-flexbox align-top'
            style={{
                minHeight: '80vh',
            }}
            onSubmit={handleCheckout}
            ref={me}
        >
            <div>
                {!session.user._id ? (
                    <FormSection
                        title='Contacto'
                        className='rds-full-block rds-m_bottom__sm'
                    >
                        <FormItem
                            id='name'
                            autoComplete='name'
                            label='Nombre'
                            onChange={event => {
                                handlePersonalInformationChange(
                                    'name',
                                    event.currentTarget.value,
                                )
                            }}
                            value={personalInformation.name}
                            requiredLabel='Campo requerido'
                            required
                        />
                        <FormItem
                            label='Correo electrónico'
                            id='email'
                            autoComplete='email'
                            type={FormItemTypes.EMAIL}
                            onChange={event => {
                                handlePersonalInformationChange(
                                    'email',
                                    event.currentTarget.value,
                                )
                            }}
                            value={personalInformation.email}
                            errorMessage={formErrorMessages.email}
                        />
                        <div className='rds-grid_2-columns_item'>
                            <Label>Teléfono</Label>
                            <div className='rds-flexbox'>
                                <FormItem
                                    id='phonePrefix'
                                    autoComplete='phone-prefix'
                                    type={FormItemTypes.SELECT}
                                    className='rds-m_right__sm'
                                    values={[
                                        {
                                            label: (
                                                <span className='rds-m_right__sm'>
                                                    🇺🇾
                                                </span>
                                            ),
                                            value: '+598',
                                            id: '+598',
                                        },
                                    ]}
                                    value={personalInformation.phonePrefix}
                                    style={{ width: 'auto' }}
                                    forceDropdown
                                    disabled
                                />
                                <FormItem
                                    id='phone'
                                    autoComplete='tel-national'
                                    onChange={event => {
                                        handlePersonalInformationChange(
                                            'phone',
                                            event.currentTarget.value,
                                        )
                                    }}
                                    value={personalInformation.phone}
                                    errorMessage={formErrorMessages.phone}
                                />
                            </div>
                        </div>
                    </FormSection>
                ) : null}
                <FormSection
                    title='Local'
                    className='rds-full-block rds-m_bottom__sm'
                >
                    <LocationSelect
                        onChange={event => {
                            dispatch(setSelectedLocation(event.target.value))
                        }}
                        value={checkoutData.location}
                    />
                </FormSection>
                <FormSection
                    title='Método de entrega'
                    className='rds-m_bottom__sm rds-overflow_visible rds-full-block'
                >
                    <FormItem
                        type={FormItemTypes.CARD_SELECT}
                        values={deliverTypes}
                        value={isDeliver ? 'deliver' : 'take-away'}
                        onChange={handleDeliverTypeChange}
                    />
                    {isDeliver ? (
                        <DeliverFormAddressSelector
                            onChange={handleAddressChange}
                        />
                    ) : null}
                </FormSection>
                <FormSection
                    title='Método de pago'
                    className='rds-full-block rds-m_bottom__sm'
                >
                    <FormItem
                        type={FormItemTypes.CARD_SELECT}
                        values={paymentTypes}
                        value={checkoutData.paymentType}
                        onChange={event => {
                            const { value } = event.target
                            setCheckoutData({
                                ...checkoutData,
                                paymentType: value,
                                onSitePayment: {
                                    ...checkoutData.onSitePayment,
                                    method:
                                        value === SALE_PAYMENT_TYPES.ONSITE
                                            ? checkoutData.onSitePayment.method
                                            : '',
                                    payingWithCashAmount:
                                        value === SALE_PAYMENT_TYPES.ONSITE
                                            ? checkoutData.onSitePayment
                                                  .payingWithCashAmount
                                            : '',
                                },
                            })
                        }}
                    />
                    {checkoutData.paymentType === SALE_PAYMENT_TYPES.ONSITE ? (
                        <FormItem
                            label='Forma de pago'
                            className='rds-m_top__md'
                            type={FormItemTypes.CARD_SELECT}
                            values={getOnsitePaymentMethodOptions()}
                            value={checkoutData.onSitePayment.method}
                            onChange={event => {
                                const { value } = event.target
                                setCheckoutData({
                                    ...checkoutData,
                                    onSitePayment: {
                                        ...checkoutData.onSitePayment,
                                        method: value,
                                        payingWithCashAmount:
                                            value === 'CASH'
                                                ? checkoutData.onSitePayment
                                                      .payingWithCashAmount
                                                : '',
                                    },
                                })
                            }}
                            required
                        />
                    ) : null}
                    {checkoutData.onSitePayment.method === 'CASH' ? (
                        <FormItem
                            type={FormItemTypes.NUMBER}
                            label='Monto'
                            placeholder='Monto en efectivo con el que abonarás'
                            tooltip='Este dato nos ayuda a poder enviar el dinero necesario para el cambio'
                            value={
                                checkoutData.onSitePayment.payingWithCashAmount
                            }
                            onChange={event =>
                                setCheckoutData({
                                    ...checkoutData,
                                    onSitePayment: {
                                        ...checkoutData.onSitePayment,
                                        payingWithCashAmount:
                                            event.target.value,
                                    },
                                })
                            }
                            min={total}
                        />
                    ) : null}
                    {checkoutData.onSitePayment.method === 'MONEY_TRANSFER' ? (
                        <div className='rds-m_top__md'>
                            <Label>Indicaciones para transferencia</Label>
                            <Typography>
                                {
                                    selectedLocation.paymentMethods
                                        .moneyTransferDescription
                                }
                            </Typography>
                        </div>
                    ) : null}
                </FormSection>
            </div>
            <Block
                variant={ThemeVariants.WHITE}
                className='checkout-form_ribbon rds-full-block rds-p_bottom__sm'
                style={{
                    borderTop: cssRules.borderValue({ themeContext }),
                    boxShadow: cssRules.boxShadowValue({ themeContext }),
                }}
            >
                {submitError ? (
                    <Block
                        className='checkout-form_ribbon_error rds-full-block rds-p_around__sm rds-flexbox align-center'
                        bordered
                    >
                        <Container>
                            {submitError.split('\n').map((error, index) => (
                                <Typography
                                    key={index}
                                    variant={ThemeVariants.WHITE}
                                >
                                    {error}
                                </Typography>
                            ))}
                        </Container>
                    </Block>
                ) : null}
                <Container className='checkout-form_ribbon_inner rds-p_top__sm rds-flexbox align-center justify-between'>
                    <div className='rds-flexbox align-end justify-end'>
                        {isDeliver &&
                        typeof calculatedShipmentCost === 'number' ? (
                            <>
                                <div className='direction-column rds-flexbox'>
                                    <Label>Subtotal</Label>
                                    <Typography type={TypographyTypes.H3}>
                                        ${subTotal}
                                    </Typography>
                                </div>
                                <Typography
                                    className='rds-m_left__sm rds-m_right__md'
                                    style={{ marginBottom: '3px' }}
                                    type={TypographyTypes.H4}
                                >
                                    +
                                </Typography>
                                <div className='direction-column rds-flexbox'>
                                    <Label>Envío</Label>
                                    <Typography type={TypographyTypes.H3}>
                                        {calculatedShipmentCost
                                            ? `$ ${calculatedShipmentCost}`
                                            : 'Gratis'}
                                    </Typography>
                                </div>
                                <Typography
                                    className='rds-m_left__sm rds-m_right__md'
                                    style={{ marginBottom: '3px' }}
                                    type={TypographyTypes.H4}
                                >
                                    =
                                </Typography>
                            </>
                        ) : null}
                        <div className='direction-column rds-flexbox'>
                            <Label>Total</Label>
                            <Typography type={TypographyTypes.H3}>
                                ${total}
                            </Typography>
                        </div>
                    </div>
                    <div className='checkout-form_ribbon_actions rds-flexbox align-center'>
                        {error ? (
                            <Block
                                className='checkout-form_ribbon_error rds-m_right__md'
                                roundedBorder
                            >
                                <Typography variant={ThemeVariants.WHITE}>
                                    {error}
                                </Typography>
                            </Block>
                        ) : null}
                        <div>
                            <input
                                type='submit'
                                style={{ display: 'none' }}
                                ref={submitButton}
                            />
                            <Button
                                className='rds-p_top__sm rds-p_bottom__sm'
                                variant={ButtonVariants.MAIN}
                                label={getCheckoutButtonLabel()}
                                onClick={handleSubmitClick}
                                disabled={isSubmitDisabled}
                            />
                        </div>
                    </div>
                </Container>
            </Block>
        </form>
    )
}

export default CheckoutForm
