const props = [
    {
        label: {
            en: 'HTML',
            es: 'HTML',
        },
        name: 'html',
        type: 'HTML',
    },
]

export default props
