import React from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, Carrousel, Header, HeaderSizes, IconTypes } from 'rds'
import api from '../../api'
import { PRODUCT_CARROUSEL_TYPES } from '../../constants'
import ProductCard from './ProductCard'
import ProductCardSkeleton from './ProductCard.skeleton'

/**
 * Generates a custom endpoint URL based on the provided filters and additional filters.
 *
 * @param {Object} filters - The filters to be applied to the endpoint URL.
 * @param {string} [additionalFilters=''] - Additional filters to be appended to the endpoint URL.
 * @param {string} [endpoint=''] - The endpoint URL to be used.
 * @returns {string} The generated custom endpoint URL.
 */
const getCustomEndpoint = (
    filters,
    additionalFilters = '',
    endpoint = 'products',
) => {
    const url = new URL(endpoint, api.defaults.baseURL)
    for (const key in filters) {
        const value = filters[key]
        if (!value) {
            delete filters[key]
        }
    }
    url.search = new URLSearchParams(filters)
    if (additionalFilters) {
        url.search += '&' + additionalFilters
    }
    return decodeURIComponent(url)
}

const ProductsCarrousel = ({
    category,
    minPrice,
    maxPrice,
    sort,
    sortDirection,
    limit,
    title,
    text,
    type = PRODUCT_CARROUSEL_TYPES.RECOMMENDED,
    itemsPerPage = 4,
    steps = 2,
}) => {
    const params = useParams()
    const webstoreProductSettings = useSelector(
        state => state.session.subscription.webstore.settings.products,
        shallowEqual,
    )
    const [isLoading, setIsLoading] = React.useState(false)
    const [carrouselProducts, setCarrouselProducts] = React.useState([])
    const mounted = React.useRef()
    const safeColumnsNumber = itemsPerPage > 6 ? 6 : itemsPerPage

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    const getProducts = React.useCallback(async () => {
        setIsLoading(true)
        setCarrouselProducts([])

        let endpoint = ''
        const filters = {
            category,
            'price=>': minPrice,
            'price=<': maxPrice,
            sort,
            sortDirection,
            limit,
            useCache: true,
            state: true,
        }

        switch (type) {
            case PRODUCT_CARROUSEL_TYPES.RECOMMENDED:
                endpoint = getCustomEndpoint(
                    filters,
                    null,
                    'reports/webstore-recommended-products',
                )
                break

            case PRODUCT_CARROUSEL_TYPES.MOST_SOLD:
                endpoint = getCustomEndpoint(
                    filters,
                    null,
                    'reports/webstore-most-sold-products',
                )
                break

            case PRODUCT_CARROUSEL_TYPES.RELATED:
                if (!params.productId) {
                    return
                }
                const res = await api.get(
                    `products/${params.productId}?useCache=true`,
                )
                const { product } = res.data
                if (product) {
                    const { tags } = product
                    let tagsQueryParam = ''
                    if (tags?.length) {
                        tagsQueryParam = tags
                            .map(tag => `tags[]=${tag._id || tag}`)
                            .join('&')
                    }
                    tagsQueryParam += '&_id=!' + params.productId
                    endpoint = getCustomEndpoint(filters, tagsQueryParam)
                } else {
                    endpoint = getCustomEndpoint(filters)
                }
                break

            default:
                endpoint = getCustomEndpoint(filters)
                break
        }

        const res = await api.get(endpoint)
        if (mounted.current) {
            setCarrouselProducts(res.data.products)
        }
        setIsLoading(false)
    }, [
        category,
        minPrice,
        maxPrice,
        sort,
        sortDirection,
        limit,
        type,
        params.productId,
    ])

    React.useEffect(() => {
        getProducts()
    }, [getProducts])

    if (!Object.keys(PRODUCT_CARROUSEL_TYPES).includes(type)) {
        throw new Error('Carrousel type not recognized')
    } else if (type === PRODUCT_CARROUSEL_TYPES.RELATED && !params.productId) {
        return (
            <div>
                <Card
                    headerIcon={IconTypes.WARNING}
                    headerTitle='Error en el componente "Carrusel de Productos"'
                    headerText='El Carrusel de Productos de tipo "relacionado" debe estar en una pagina de Producto'
                    headerSize={HeaderSizes.SMALL}
                ></Card>
            </div>
        )
    } else if (!isLoading && !carrouselProducts?.length) {
        return null
    }
    return (
        <div className='products-carrousel'>
            <Header title={title} text={text} size={HeaderSizes.MEDIUM} />
            {isLoading ? (
                <div
                    className={`rds-grid rds-grid_${safeColumnsNumber}-columns rds-m_top__md`}
                >
                    {[...Array(safeColumnsNumber).keys()].map(i => (
                        <ProductCardSkeleton key={i} />
                    ))}
                </div>
            ) : (
                <Carrousel
                    secondBreakPoint={500}
                    itemsPerPage={itemsPerPage}
                    steps={steps}
                    className={`rds-m_top__md ${
                        webstoreProductSettings.distribution === 'HORIZONTAL'
                            ? 'horizontal'
                            : ''
                    }`}
                >
                    {carrouselProducts.map(p => (
                        <ProductCard key={p._id} product={p} />
                    ))}
                </Carrousel>
            )}
        </div>
    )
}

export default ProductsCarrousel
