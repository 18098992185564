import React from 'react'
import { Typography, Logo, TypographyTypes, ThemesNames } from 'rds'
import { useSelector, shallowEqual } from 'react-redux'
import styledComponents from 'styled-components'
import Container from './Container'

const StyledFooter = styledComponents.footer`${props => `
    background-color: ${
        props.webstoreThemeScheme === ThemesNames.DARK
            ? 'var(--rds-colorBlackDark)'
            : 'white'
    };
`}
`

const Footer = () => {
    const webstoreThemeScheme = useSelector(
        state => state.session.subscription.webstore.settings.theme.scheme,
    )
    const socialNetworks = useSelector(
        state => state.session.subscription.webstore.settings.socialNetworks,
        shallowEqual,
    )
    const [parsedSocialNetworks, setParsedSocialNetworks] = React.useState({})
    const hasSocialNetworks =
        parsedSocialNetworks?.facebook ||
        parsedSocialNetworks?.instagram ||
        parsedSocialNetworks?.twitter

    React.useEffect(() => {
        const cleanSocialNetworks = { ...socialNetworks }
        delete cleanSocialNetworks._id
        setParsedSocialNetworks(cleanSocialNetworks)
    }, [socialNetworks])

    const getIcon = socialNetworkId => {
        let icon
        switch (socialNetworkId) {
            case 'facebook':
                icon =
                    'https://static.xx.fbcdn.net/rsrc.php/yD/r/d4ZIVX-5C-b.ico'
                break

            case 'instagram':
                icon =
                    'https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Instagram-Icon.png/480px-Instagram-Icon.png'
                break

            case 'twitter':
                icon =
                    'https://about.twitter.com/content/dam/about-twitter/x/brand-toolkit/logo-black.png.twimg.1920.png'
                break

            default:
                break
        }
        return icon
    }

    return (
        <StyledFooter
            webstoreThemeScheme={webstoreThemeScheme}
            className='rds-full-block rds-p_top__md rds-p_bottom__md'
        >
            <Container>
                {hasSocialNetworks ? (
                    <div>
                        <header className='footer_social-links_header'>
                            <Typography type={TypographyTypes.H3}>
                                Redes sociales
                            </Typography>
                            <Typography>
                                Encuentranos en nuestras redes sociales y
                                enterate de todo lo nuevo
                            </Typography>
                        </header>
                        <div className='footer_social-links rds-m_top__sm rds-flexbox align-center'>
                            {Object.keys(parsedSocialNetworks).map(
                                socialNetworkId => {
                                    const socialNetworkURL =
                                        parsedSocialNetworks[socialNetworkId]
                                    if (!socialNetworkURL) {
                                        return null
                                    }
                                    return (
                                        <div
                                            key={socialNetworkId}
                                            className='footer_social-link rds-m_right__sm'
                                        >
                                            <a href={socialNetworkURL}>
                                                <img
                                                    src={getIcon(
                                                        socialNetworkId,
                                                    )}
                                                    alt={socialNetworkId}
                                                    width={30}
                                                    height={30}
                                                />
                                            </a>
                                        </div>
                                    )
                                },
                            )}
                        </div>
                    </div>
                ) : null}
                <div
                    className='rds-m_top__md rds-flexbox align-center direction-column'
                    style={{ opacity: 0.5, filter: 'grayscale()' }}
                >
                    <div className='rds-flexbox align-center'>
                        <Typography
                            type={TypographyTypes.P}
                            className='rds-m_right__sm'
                        >
                            Powered by
                        </Typography>
                        <a
                            href='https://reango.app'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <Logo />
                        </a>
                    </div>
                </div>
            </Container>
        </StyledFooter>
    )
}

export default Footer
