import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Minimum height',
            es: 'Altura mínima',
        },
        name: 'minHeight',
        type: FormItemTypes.NUMBER,
    },
    {
        label: {
            en: 'Initial latitude',
            es: 'Latitud inicial',
        },
        name: 'centerLat',
        type: FormItemTypes.NUMBER,
    },
    {
        label: {
            en: 'Initial longitude',
            es: 'Longitud inicial',
        },
        name: 'centerLng',
        type: FormItemTypes.NUMBER,
    },
    {
        label: {
            en: 'Initial zoom',
            es: 'Zoom inicial',
        },
        name: 'zoom',
        type: FormItemTypes.NUMBER,
    },
]
export default props
