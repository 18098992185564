import React from 'react'
import { Header, HeaderSizes } from 'rds'
import styledComponents from 'styled-components'
import { useNavigate } from 'react-router'

const StyledHeading = styledComponents.div`${props => `
    ${props.padding ? `padding: ${props.padding}px;` : ''}
    ${props.margin ? `margin: ${props.margin}px;` : ''}
    ${props.navigateToURL ? 'cursor: pointer;' : ''}
    background-color: ${props.backgroundColor};
`}
`

const Heading = ({
    title,
    text,
    size = HeaderSizes.BIG,
    variant,
    align,
    padding,
    margin,
    backgroundColor,
    navigateToURL,
}) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(navigateToURL)
    }

    return (
        <StyledHeading
            padding={padding}
            margin={margin}
            backgroundColor={backgroundColor}
            navigateToURL={navigateToURL}
            onClick={navigateToURL ? handleClick : null}
        >
            <Header
                title={title}
                text={text}
                size={size}
                align={align}
                variant={variant}
            />
        </StyledHeading>
    )
}

export default Heading
