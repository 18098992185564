import React from 'react'
import {
    Card,
    EmptyState,
    FormItem,
    FormItemTypes,
    Button,
    HeaderSizes,
    Loading,
    Header,
    Tag,
    Rater,
    TableItem,
} from 'rds'
import api from '../../api'
import { PRODUCT_RATING } from '../../constants'

const Reviews = ({ productId, userId }) => {
    const [loading, setLoading] = React.useState(false)
    const [currentRate, setCurrentRate] = React.useState(2)
    const [comment, setComment] = React.useState('')
    const [reviews, setReviews] = React.useState([])
    const [userReviewed, setUserReviewed] = React.useState(false)

    const fetchReviews = async productId => {
        if (!productId) {
            return
        }
        setLoading(true)
        const res = await api.get(`reviews?product=${productId}`)
        setReviews(res.data.reviews)
        setLoading(false)
    }

    React.useEffect(() => {
        fetchReviews(productId)
    }, [productId])

    React.useEffect(() => {
        if (
            userId &&
            reviews.length &&
            reviews.findIndex(
                review => review.user === userId || review.user.id === userId,
            ) >= 0
        ) {
            setUserReviewed(true)
        }
    }, [reviews, userId])

    const handleReviewSubmit = async () => {
        setLoading(true)
        const review = {
            user: userId,
            product: productId,
            stars: currentRate + 1,
            comment,
        }
        await api.post('reviews', review)
        fetchReviews(productId)
        setLoading(false)
    }

    if (loading) {
        return <Loading tag='Cargando comentarios...' />
    }
    return (
        <div>
            {userId && !userReviewed ? (
                <TableItem className='rds-full-block'>
                    <Rater
                        values={PRODUCT_RATING}
                        initial={currentRate}
                        onChange={newRate => setCurrentRate(newRate)}
                    />
                    <FormItem
                        className='rds-m_top__md'
                        label='Comentario'
                        type={FormItemTypes.TEXTAREA}
                        onChange={e => setComment(e.target.value)}
                        placeholder='Agrega tu comentario aquí...'
                    />
                    <div className='rds-m_top__sm rds-full-block rds-flexbox justify-end'>
                        <Button
                            label='Comentar'
                            className='rds-full-block'
                            onClick={handleReviewSubmit}
                            disabled={!comment}
                        />
                    </div>
                </TableItem>
            ) : null}
            <div>
                {reviews.length ? (
                    reviews.map((review, index) => (
                        <Card key={index} className='rds-m_top__sm'>
                            <div className='rds-flexbox align-center justify-between'>
                                <Rater
                                    values={PRODUCT_RATING}
                                    initial={review.stars - 1}
                                />
                                {review.user.id === userId ? (
                                    <Tag>Tu opinión</Tag>
                                ) : null}
                            </div>
                            <Header
                                title={review.comment}
                                size={HeaderSizes.XSMALL}
                            />
                        </Card>
                    ))
                ) : (
                    <div className='rds-full-block'>
                        <EmptyState
                            title='No hay comentarios aún'
                            message='Déjanos tu opinión sobre este producto'
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Reviews
