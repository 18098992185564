import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Loading label',
            es: 'Texto mientras carga',
        },
        name: 'loadingLabel',
        type: FormItemTypes.TEXT,
    },
]
export default props
