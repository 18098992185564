import { configureStore } from '@reduxjs/toolkit'

// Reducers
import cartReducer from './reducers/cartReducer'
import fieldReducer from './reducers/fieldReducer'
import sessionReducer from './reducers/sessionReducer'
import uiReducer from './reducers/uiReducer'
import locationReducer from './reducers/locationReducer'

export default configureStore({
    reducer: {
        cart: cartReducer,
        location: locationReducer,
        session: sessionReducer,
        ui: uiReducer,
        field: fieldReducer,
    },
})
