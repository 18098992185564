import React from 'react'
import styled from 'styled-components'
import { getParsedColor } from '../../utils'

const StyledVideoWrapper = styled.div(props => ({
    height: `${props.containerHeight}px`,
}))

const StyledVideo = styled.video(props => ({
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: props.objectPosition,
}))

const StyledVideoOverlay = styled.div(props => ({
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: getParsedColor(
        props.overlayColor,
        props.overlayColorAlpha,
    ),
}))

const Video = ({
    height = 100,
    loop,
    objectPosition,
    overlayColor,
    overlayColorAlpha,
    url,
}) => {
    if (!url) {
        return null
    }
    return (
        <StyledVideoWrapper className='video_wrapper' containerHeight={height}>
            <StyledVideo
                objectPosition={objectPosition}
                className='video rds-absolute'
                loop={loop}
                playsInline
                autoPlay
                muted
            >
                <source src={url} />
            </StyledVideo>
            <StyledVideoOverlay
                className='video_overlay rds-absolute'
                overlayColor={overlayColor}
                overlayColorAlpha={overlayColorAlpha}
            ></StyledVideoOverlay>
        </StyledVideoWrapper>
    )
}

export default Video
