import React from 'react'
import {
    Header,
    Form,
    FormItem,
    FormItemTypes,
    HeaderSizes,
    Block,
    Typography,
    TypographyTypes,
    Button,
    ButtonVariants,
    FormSection,
    FormSectionSizes,
} from 'rds'
import api from '../../api'
import { sessionDoLogin, sessionSetUser } from '../../reducers/sessionReducer'
import {
    REANGO_TOKEN_LOCALSTORAGE_NAME,
    REANGO_CART_LOCALSTORAGE_NAME,
    USER_ROLES,
} from '../../constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DEBUG_MODE } from '../../utils'
import { useDispatch } from 'react-redux'
import { cartSetCart } from '../../reducers/cartReducer'
import styled from 'styled-components'

const StyledLoginWrapper = styled.div`
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
`

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const mounted = React.useRef()
    const [isLogin, setIsLogin] = React.useState(true)
    const [formType, setFormType] = React.useState('login')
    const [formData, setFormData] = React.useState({})

    React.useEffect(() => {
        const currentAuth = localStorage.getItem(REANGO_TOKEN_LOCALSTORAGE_NAME)
        if (currentAuth) {
            window.location.assign('/')
        }
        window.scrollTo(0, 0)
    }, [])

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    React.useEffect(() => {
        if (mounted.current) {
            setIsLogin(formType === 'login')
        }
    }, [formType])

    const handleSuccess = user => {
        // Set the current session
        if (DEBUG_MODE) {
            console.log('[LOGIN] Successfully logged in with:', user)
        }
        dispatch(sessionSetUser(user))

        // TODO; Fix this
        // If user has a cart, replace the current one
        const lsCart = localStorage.getItem(REANGO_CART_LOCALSTORAGE_NAME)
        if (!Array.isArray(user.cart) || !user.cart.length) {
            try {
                const parsedLSCart = JSON.parse(lsCart)
                if (Array.isArray(parsedLSCart) && parsedLSCart.length) {
                    if (DEBUG_MODE) {
                        console.log(
                            "[LOGIN] Using localStorage's cart:",
                            parsedLSCart,
                        )
                    }
                    dispatch(
                        cartSetCart({
                            entries: parsedLSCart,
                            isUserCart: true,
                        }),
                    )
                }
            } catch (error) {
                // Not able to parse localStorage cart
            }
        } else {
            if (DEBUG_MODE) {
                console.log("[LOGIN] Using user's cart:", user.cart)
            }
            dispatch(
                cartSetCart({
                    entries: user.cart,
                    isUserCart: true,
                }),
            )
        }

        // Get redirection URI
        const callback_url = searchParams.get('callback_url')
        // This actually comes from the callback_url param
        // but it recognices as a totally different param
        const address = searchParams.get('address')
        const redirectURL =
            `${callback_url || ''}${address ? `&address=${address}` : ''}` ||
            '/'
        if (DEBUG_MODE) {
            console.log('[LOGIN] Redirecting to:', redirectURL)
        }
        navigate(redirectURL)
    }

    const handleRegister = async () => {
        try {
            await api.post('users', {
                name: formData.name,
                email: formData.email,
                address: formData.address,
                database: process.env.REACT_APP_PROJECT_NAME,
                password: formData.password,
                role: USER_ROLES.WEBSTORE_USER,
                cart: JSON.parse(
                    localStorage.getItem(REANGO_CART_LOCALSTORAGE_NAME),
                ),
            })
            const resData = await sessionDoLogin(
                {
                    email: formData.email,
                    database: process.env.REACT_APP_PROJECT_NAME,
                    password: formData.password,
                },
                true,
            )
            handleSuccess(resData.user)
        } catch (error) {
            console.error('[LOGIN]', error.response)
            alert(error.response.data.message)
        }
    }

    const handleLogin = async () => {
        try {
            const resData = await sessionDoLogin(
                {
                    email: formData.email,
                    password: formData.password,
                    database: process.env.REACT_APP_PROJECT_NAME,
                },
                true,
            )
            handleSuccess(resData.user)
        } catch (error) {
            console.error('[LOGIN]', error.response)
            alert(error.response.data.message)
        }
    }

    const handleInputChange = e => {
        if (mounted.current) {
            setFormData({ ...formData, [e.target.id]: e.target.value })
        }
    }

    const handleFormTypeChange = e => {
        if (mounted.current) {
            setFormType(e.target.value)
        }
    }

    const handleForgotPassword = async () => {
        if (!formData.email) {
            alert('Debes ingresar tu email primero')
            return
        }
        let resultMessage = ''
        try {
            await api.post(`users/forgot-password`, {
                email: formData.email,
            })
            resultMessage =
                'Contraseña restablecida, recibirás un correo electrónico con la nueva'
        } catch (error) {
            console.error('[LOGIN]', error.response)
            resultMessage = error.response.data.message
        } finally {
            alert(resultMessage)
        }
    }

    return (
        <StyledLoginWrapper className='login-form'>
            <Form
                submitLabel={isLogin ? 'Iniciar sesión' : 'Crear cuenta'}
                submitCallback={isLogin ? handleLogin : handleRegister}
            >
                <FormSection size={FormSectionSizes.BIG}>
                    <Header
                        className='rds-text-align_center rds-grid_2-columns_item'
                        title={isLogin ? 'Iniciar sesión' : 'Registrarse'}
                        text={
                            isLogin
                                ? 'Ingresá tus credenciales de usuario'
                                : 'Llená el formulario con tus datos'
                        }
                        size={HeaderSizes.MEDIUM}
                    />
                    <FormItem
                        className='rds-m_top__sm rds-flexbox align-center justify-center'
                        id='form-type'
                        onChange={handleFormTypeChange}
                        value={formType}
                        type={FormItemTypes.SELECT}
                        values={[
                            {
                                id: 'login',
                                label: 'Ya tengo un usuario',
                            },
                            {
                                id: 'register',
                                label: 'Quiero registrarme',
                            },
                        ]}
                    />
                    {!isLogin ? (
                        <FormItem
                            id='name'
                            label='Nombre completo'
                            onChange={handleInputChange}
                            value={formData.name}
                            required
                        />
                    ) : null}
                    <FormItem
                        id='email'
                        label='Correo electrónico'
                        onChange={handleInputChange}
                        value={formData.email}
                        type={FormItemTypes.EMAIL}
                        required
                    />
                    <FormItem
                        id='password'
                        label='Contraseña'
                        placeholder='*****'
                        type={FormItemTypes.PASSWORD}
                        onChange={handleInputChange}
                        value={formData.password}
                        minLength={isLogin ? undefined : 8}
                        pattern={isLogin ? undefined : '[a-zA-Z0-9-]+'}
                        required
                    />
                    {isLogin ? null : (
                        <Block
                            className='rds-grid_2-columns_item rds-p_around__md'
                            roundedBorder
                        >
                            <Typography type={TypographyTypes.H4}>
                                Requisitos para la contraseña:
                            </Typography>
                            <ul className='rds-m_top__sm'>
                                <li>
                                    <Typography type={TypographyTypes.P}>
                                        · Mínimo 8 caracteres
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type={TypographyTypes.P}>
                                        · Debe contener al menos 1 número
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type={TypographyTypes.P}>
                                        · Debe contener al menos 1 letra
                                    </Typography>
                                </li>
                            </ul>
                        </Block>
                    )}
                </FormSection>
            </Form>
            {isLogin ? (
                <div className='rds-full-block rds-flexbox align-center justify-center rds-m_top__sm'>
                    <Button
                        label='Olvidé mi contraseña'
                        variant={ButtonVariants.TRANSPARENT}
                        onClick={handleForgotPassword}
                    />
                </div>
            ) : null}
        </StyledLoginWrapper>
    )
}

export default Login
