import React from 'react'
import { TableItem, Typography, TypographyTypes } from 'rds'
import api from '../../api'
import { calculateUnitPriceWithAdditionals } from '../../utils'
import ProductCardSkeleton from '../Product/ProductCard.skeleton'
import CartEntryAdditionals from './CartEntryAdditionals'
import CartEntryCustomFields from './CartEntryCustomFields'
import CartEntryCustomFieldItem from './CartEntryCustomFieldItem'
import CartSummaryItemHeader from './CartSummaryItemHeader'

const DEBUG_PREFIX = '[CARTSUMMARY_ITEM]'

const CartSummaryItem = ({ cartEntry, isLastOne, customFields = [] }) => {
    const [loading, setLoading] = React.useState(true)
    const [populatedItem, setPopulatedItem] = React.useState({
        item: {},
        variant: {},
    })
    const [entryPriceWithAdditionals, setEntryPriceWithAdditionals] =
        React.useState(populatedItem.variant.price)

    /**
     * Calculates the individual price of the variant
     * including selected additionals
     */
    React.useEffect(() => {
        if (!populatedItem.variant._id || !populatedItem.variant.price) {
            return
        }
        const additionalsFields = customFields.filter(
            field => field.type === 'ADDITIONALS',
        )
        setEntryPriceWithAdditionals(
            calculateUnitPriceWithAdditionals(
                populatedItem.variant.price,
                cartEntry.additionals,
                additionalsFields,
            ),
        )
    }, [
        cartEntry.additionals,
        customFields,
        populatedItem.variant._id,
        populatedItem.variant.price,
    ])

    React.useEffect(() => {
        const prodId = cartEntry.item._id || cartEntry.item
        if (prodId === populatedItem.item._id) {
            // This product doesn't need a refresh
            setPopulatedItem({
                ...cartEntry,
                item: populatedItem.item,
                variant: populatedItem.variant,
            })
            return
        }
        api.get(`products/${prodId}`)
            .then(res => {
                const { product } = res.data
                const productVariantId =
                    cartEntry.variant._id || cartEntry.variant
                const productVariant = product.variants.find(
                    pv => pv._id === productVariantId,
                )
                setPopulatedItem({
                    ...cartEntry,
                    item: product,
                    variant: productVariant,
                })
            })
            .catch(err => {
                console.error(DEBUG_PREFIX, err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [cartEntry, populatedItem.item, populatedItem.variant])

    if (loading) {
        return (
            <div className='rds-full-block rds-m_bottom__sm'>
                <ProductCardSkeleton />
            </div>
        )
    }
    return (
        <TableItem
            className='cart-summary_item rds-full-block'
            noLine={isLastOne}
        >
            <CartSummaryItemHeader item={populatedItem} />
            <CartEntryCustomFields
                entryCustomFields={populatedItem.variant.customFields}
                customFields={customFields}
            />
            <CartEntryAdditionals
                entryAdditionals={cartEntry.additionals}
                customFields={customFields}
            />
            <CartEntryCustomFieldItem label='Notas'>
                <span style={{ wordBreak: 'break-word' }}>
                    {cartEntry.notes || '-'}
                </span>
            </CartEntryCustomFieldItem>
            <CartEntryCustomFieldItem label='Cantidad'>
                {populatedItem.quantity}
            </CartEntryCustomFieldItem>
            <div className='rds-full-block rds-text-align_right'>
                <Typography type={TypographyTypes.H3}>
                    ${entryPriceWithAdditionals * populatedItem.quantity}
                </Typography>
                {populatedItem.quantity > 1 ? (
                    <Typography type={TypographyTypes.H4}>
                        ${entryPriceWithAdditionals} c/u
                    </Typography>
                ) : null}
            </div>
        </TableItem>
    )
}

export default CartSummaryItem
