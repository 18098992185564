import React from 'react'
import { Link } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    ButtonVariants,
    Card,
    Label,
    Typography,
    TypographyTypes,
} from 'rds'
import { REANGO_TOKEN_LOCALSTORAGE_NAME } from '../../constants'
import { doLogout } from '../../reducers/sessionReducer'

const UserProfileBanner = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const sessionUser = useSelector(state => state.session.user, shallowEqual)

    React.useEffect(() => {
        if (!localStorage.getItem(REANGO_TOKEN_LOCALSTORAGE_NAME)) {
            navigate('/login')
        }
    }, [navigate])

    const handleLogout = async () => {
        await dispatch(doLogout())
        navigate('/')
    }

    return (
        <Card className='user-profile-banner rds-p_around__md'>
            <div className='rds-grid rds-grid_2-columns'>
                <Typography
                    type={TypographyTypes.H1}
                    className='rds-overflow_hidden'
                    style={{ textOverflow: 'ellipsis' }}
                >
                    {sessionUser.name}
                </Typography>
                <div className='rds-flexbox align-center justify-end'>
                    <Link to='/profile/edit'>
                        <Button
                            label='Editar mi perfil'
                            variant={ButtonVariants.MAIN}
                        />
                    </Link>
                    <Button
                        label='Cerrar sesión'
                        onClick={handleLogout}
                        variant={ButtonVariants.TRANSPARENT}
                    />
                </div>
            </div>
            <div className='rds-m_top__md rds-grid rds-grid_3-columns'>
                <div className='rds-m_right'>
                    <Label>Email</Label>
                    <Typography>{sessionUser.email}</Typography>
                </div>
                <div className='rds-m_right'>
                    <Label>Teléfono</Label>
                    <Typography>{sessionUser.phone || '-'}</Typography>
                </div>
            </div>
        </Card>
    )
}

export default UserProfileBanner
