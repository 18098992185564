import React from 'react'
import { useSelector } from 'react-redux'
import { FormItem, FormItemTypes } from 'rds'
import LocationSelectLocationDescription from './LocationSelectLocationDescription'
import CheckoutFormOptionLabel from '../../components/Cart/CheckoutFormOptionLabel'
import { DEBUG_MODE } from '../../utils'

const DEBUG_PREFIX = '[LOCATION_SELECT]'

const LocationSelect = ({ onChange, value }) => {
    const locations = useSelector(state => state.location.locations)
    if (DEBUG_MODE) {
        console.log(DEBUG_PREFIX, 'Locations', locations)
    }
    const [userCoords, setUserCoords] = React.useState()
    const [values, setValues] = React.useState([])

    React.useEffect(() => {
        if (!navigator?.geolocation) {
            return
        }
        navigator.geolocation.getCurrentPosition(
            currentPosition => {
                const userCoords = {
                    lat: currentPosition.coords.latitude,
                    lng: currentPosition.coords.longitude,
                }
                setUserCoords(userCoords)
            },
            () => {
                setUserCoords()
            },
        )
    }, [])

    React.useEffect(() => {
        const distanceByLocationId = {}
        if (userCoords) {
            for (let index = 0; index < locations.length; index++) {
                const location = locations[index]
                if (location.coords) {
                    const distance = window.google.maps.geometry.spherical
                        .computeDistanceBetween(userCoords, location.coords)
                        .toFixed(1)
                    distanceByLocationId[location.id] = distance
                }
            }
        }
        const values = locations.map(location => {
            const formattedLocation = {
                ...location,
                distance: distanceByLocationId?.[location.id],
            }
            return {
                label: (
                    <CheckoutFormOptionLabel
                        icon='LocalPin'
                        label={formattedLocation.name}
                    />
                ),
                description: (
                    <LocationSelectLocationDescription
                        location={formattedLocation}
                    />
                ),
                value: formattedLocation.id,
                id: formattedLocation.id,
            }
        })
        setValues(values)
    }, [locations, userCoords])

    return (
        <FormItem
            type={FormItemTypes.CARD_SELECT}
            values={values}
            value={value}
            onChange={onChange}
        />
    )
}

export default LocationSelect
