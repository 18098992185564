import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumbs } from 'rds'
import api from '../../api'

const DEBUG_PREFIX = '[PRODUCT_BREADCRUMBS]'

const ProductBreadcrumbs = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [product, setProduct] = React.useState({})

    React.useEffect(() => {
        if (!params.productId) {
            return
        }
        api.get(`products/${params.productId}`)
            .then(res => {
                setProduct(res.data.product)
            })
            .catch(error => console.error(DEBUG_PREFIX, error))
    }, [params.productId])

    return (
        <Breadcrumbs
            value={'#'}
            values={[
                {
                    label: product.category?.name,
                    value: `/categories/${product.category?._id}`,
                },
                {
                    label: product.name,
                    value: '#',
                },
            ]}
            onClick={value => navigate(value)}
        />
    )
}

export default ProductBreadcrumbs
