import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Categories',
            es: 'Categorías',
        },
        name: 'categories',
        type: FormItemTypes.LOOKUP,
        multiple: true,
        itemType: 'categories',
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Tags',
            es: 'Etiquetas',
        },
        name: 'tags',
        type: FormItemTypes.LOOKUP,
        multiple: true,
        itemType: 'tags',
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Minimum price',
            es: 'Precio mínimo',
        },
        name: 'minPrice',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Maximum price',
            es: 'Precio máximo',
        },
        name: 'maxPrice',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Sorting',
            es: 'Ordenar por',
        },
        name: 'sort',
        type: FormItemTypes.SELECT,
        enum: ['name', 'price', 'createdAt', 'updatedAt'],
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Sorting direction',
            es: 'Dirección de ordenamiento',
        },
        name: 'sortDirection',
        type: FormItemTypes.SELECT,
        enum: ['asc', 'desc'],
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Limit',
            es: 'Límite',
        },
        name: 'limit',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Columns',
            es: 'Columnas',
        },
        name: 'columns',
        type: FormItemTypes.NUMBER,
        max: 5,
        min: 1,
    },
    {
        label: {
            en: 'Use cache',
            es: 'Usar caché',
        },
        name: 'useCache',
        type: FormItemTypes.CHECKBOX,
    },
]

export default props
