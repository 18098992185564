import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { cssRules, ThemeContext } from 'rds'

const DEFAULT_CENTER = Object.freeze({
    lat: 0,
    lng: 0,
})

const StyledShipmentZonesMap = styled.div(props => ({
    minHeight: Number(props.minHeight),
    borderRadius: props.borderRadius,
}))

const DEFAULT_POLYGON_STYLE = {
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    clickable: false,
}

const ShipmentZones = ({
    minHeight = 600,
    centerLat,
    centerLng,
    zoom = 14,
}) => {
    const themeContext = React.useContext(ThemeContext)
    const ref = React.useRef()
    const locations = useSelector(state => state.location.locations)
    const [map, setMap] = React.useState()
    const [zones, setZones] = React.useState([])
    const [safeCenter, setSafeCenter] = React.useState(DEFAULT_CENTER)

    React.useEffect(() => {
        if (centerLat && centerLng) {
            setSafeCenter({
                lat: centerLat,
                lng: centerLng,
            })
            return
        }
        navigator?.geolocation?.getCurrentPosition(
            currentPosition => {
                setSafeCenter({
                    lat: currentPosition.coords.latitude,
                    lng: currentPosition.coords.longitude,
                })
            },
            () => {
                setSafeCenter(DEFAULT_CENTER)
            },
        )
    }, [centerLat, centerLng])

    React.useEffect(() => {
        const zones = []
        locations?.forEach(location => {
            zones.push(...location.shipmentCosts)
        })
        setZones(zones)
    }, [locations])

    const loadMap = React.useCallback(async () => {
        const map = new window.google.maps.Map(ref.current, {
            center: safeCenter,
            zoom: Number(zoom),
            styles: [
                {
                    featureType: 'poi',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'transit',
                    stylers: [{ visibility: 'off' }],
                },
            ],
        })
        setMap(map)
    }, [safeCenter, zoom])

    React.useEffect(() => {
        loadMap()
    }, [loadMap])

    React.useEffect(() => {
        if (!map) {
            return
        }
        zones.forEach((zone, i) => {
            const paths = zone.paths.map(coord => ({
                lat: coord.lat,
                lng: coord.lng,
            }))
            const zIndex = zones.length - i
            new window.google.maps.Polygon({
                ...DEFAULT_POLYGON_STYLE,
                map,
                strokeColor: zone.color,
                fillColor: zone.color,
                paths,
                zIndex,
            })
        })
    }, [map, zones])

    React.useEffect(() => {
        if (!map) {
            return
        }
        locations.forEach(location => {
            new window.google.maps.Marker({
                position: {
                    lat: location.coords.lat,
                    lng: location.coords.lng,
                },
                map,
                title: location.name,
            })
        })
    }, [map, locations])

    return (
        <StyledShipmentZonesMap
            minHeight={minHeight}
            borderRadius={cssRules.borderRadiusValue({ themeContext })}
            ref={ref}
        ></StyledShipmentZonesMap>
    )
}

export default ShipmentZones
