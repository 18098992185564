import { FormItemTypes } from 'rds'
import { HEADER_SIZES } from '../../constants'

const props = [
    {
        label: {
            en: 'Title',
            es: 'Título',
        },
        name: 'title',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text',
            es: 'Texto',
        },
        name: 'text',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Size',
            es: 'Tamaño',
        },
        name: 'headerSize',
        type: FormItemTypes.SELECT,
        enum: HEADER_SIZES,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
]
export default props
