import React from 'react'
import api from '../../api'
import ProductCard from './ProductCard'
import ProductCardSkeleton from './ProductCard.skeleton'

const DEBUG_PREFIX = '[PRODUCTS_GRID]'

const ProductsGrid = ({
    columns = 2,
    limit = '',
    minPrice,
    maxPrice,
    categories,
    tags,
    useCache = true,
    sort = 'name',
    sortDirection = 'asc',
}) => {
    const [isLoading, setIsLoading] = React.useState()
    const [products, setProducts] = React.useState([])
    const timeout = React.useRef()

    const getProducts = React.useCallback(async () => {
        try {
            setIsLoading(true)
            const url = new URL('products', api.defaults.baseURL)
            const filters = new URLSearchParams({
                useCache,
                limit,
                state: true,
            })
            if (sort) {
                filters.append(
                    'sort',
                    sortDirection === 'asc' ? sort : `-${sort}`,
                )
            }
            if (categories) {
                categories.forEach(category => {
                    if (category) {
                        filters.append('category', category)
                    }
                })
            }
            if (tags) {
                tags.forEach(tagId => {
                    filters.append('tags', tagId)
                })
            }
            if (minPrice) {
                filters.append('price=>', minPrice)
            }
            if (maxPrice) {
                filters.append('price=<', maxPrice)
            }
            url.search = filters
            const res = await api.get(decodeURIComponent(url))
            setProducts(res.data.products)
        } catch (error) {
            console.error(DEBUG_PREFIX, error.response)
        } finally {
            setIsLoading(false)
        }
    }, [
        sort,
        sortDirection,
        limit,
        categories,
        minPrice,
        maxPrice,
        useCache,
        tags,
    ])

    React.useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(() => getProducts(), 500)
    }, [getProducts])

    return (
        <div className={`rds-grid rds-grid_${columns}-columns`}>
            {isLoading
                ? [...Array(2).keys()].map(index => (
                      <ProductCardSkeleton key={index} />
                  ))
                : products?.map(product => (
                      <ProductCard key={product._id} product={product} />
                  ))}
        </div>
    )
}

export default ProductsGrid
