import React, { useState } from 'react'
import {
    FormItem,
    FormItemTypes,
    Button,
    IconTypes,
    Card,
    Header,
    HeaderSizes,
    Label,
} from 'rds'
import api from '../../api'
import { useParams, useSearchParams } from 'react-router-dom'
import { DEBUG_MODE } from '../../utils'

const Filters = ({ mobileReady }) => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const abortController = React.useMemo(() => new AbortController(), [])
    const [subcategories, setSubcategories] = useState([])
    const [fields, setFields] = useState([])
    const [expanded, setExpanded] = useState()
    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            abortController.abort()
            mounted.current = false
        }
    }, [abortController])

    React.useEffect(() => {
        let { categoryId } = params
        // This is to make sure Category ID does not contain any
        // of the filters applied (sometimes happened categoryId="<category-id>?<subcategory-id>")
        categoryId = categoryId.split('?')[0]

        if (!categoryId) {
            return
        }

        api.get(
            `fields?categories[]=${categoryId}&visibleInWebstore=true&type=!ADDITIONALS`,
            {
                signal: abortController.signal,
            },
        )
            .then(res => {
                if (mounted.current) {
                    setFields(res.data.fields)
                }
            })
            .catch(err => {
                if (DEBUG_MODE) {
                    console.error('[FILTERS]', err)
                }
            })

        api.get(`subcategories?category=${categoryId}`, {
            signal: abortController.signal,
        })
            .then(res => setSubcategories(res.data.subcategories))
            .catch(err => {
                if (DEBUG_MODE) {
                    console.error('[FILTERS]', err)
                }
            })
    }, [params, abortController.signal])

    const handleFilterChange = event => {
        const filterKey = event.target.id
        const filterValue = event.target.value
        if (
            !filterValue ||
            (Array.isArray(filterValue) && !filterValue.length)
        ) {
            searchParams.delete(filterKey)
        } else {
            searchParams.set(filterKey, filterValue)
        }
        setSearchParams(searchParams)
    }

    const restoreFilters = () => {
        setSearchParams({})
    }

    return (
        <div
            className={`product-filters ${
                mobileReady ? 'product-filters_mobile-ready' : ''
            } ${expanded ? 'expanded' : ''} rds-p_bottom__md`}
        >
            <Card className='product-filters_panel rds-full-block rds-overflow_visible'>
                {mobileReady ? (
                    <div
                        className='product-filters_mobile-header rds-flexbox align-center justify-between rds-m_bottom__md'
                        onClick={() => setExpanded(!expanded)}
                    >
                        <Header
                            title='Filtrar'
                            size={HeaderSizes.SMALL}
                            alignCenter={!expanded}
                        />
                        {expanded ? (
                            <Button
                                label='Cerrar'
                                onClick={() => setExpanded(!expanded)}
                            />
                        ) : null}
                    </div>
                ) : null}
                {Array.isArray(subcategories) && subcategories.length ? (
                    <FormItem
                        className='rds-m_bottom__md'
                        id='subcategories'
                        label='Subcategorías'
                        type={FormItemTypes.MULTI_SELECT}
                        values={subcategories.map(subc => ({
                            id: subc._id,
                            label: subc.name,
                            value: subc._id,
                        }))}
                        value={searchParams.get('subcategories') || []}
                        onChange={handleFilterChange}
                    />
                ) : null}
                {fields.map(field => (
                    <FormItem
                        className='rds-m_bottom__md'
                        key={field._id}
                        id={field.apiName}
                        label={field.name}
                        type={
                            field.type === 'PICKLIST'
                                ? FormItemTypes.SELECT
                                : field.type === 'CHECKBOX'
                                ? FormItemTypes.CHECKBOX
                                : null
                        }
                        values={field.values.map(value => ({
                            id: value,
                            label: value,
                            value: value,
                        }))}
                        value={searchParams.get(field.apiName)}
                        onChange={handleFilterChange}
                        forceDropdown
                    />
                ))}
                <div className='rds-form-item rds-full-block'>
                    <Label>Precio</Label>
                    <div className='rds-flexbox align-center justify-between'>
                        <FormItem
                            type={FormItemTypes.NUMBER}
                            id='product_min-price'
                            value={searchParams.get('product_min-price')}
                            onChange={handleFilterChange}
                            min={0}
                        />
                        <span className='rds-m_left__sm rds-m_right__sm'>
                            -
                        </span>
                        <FormItem
                            type={FormItemTypes.NUMBER}
                            id='product_max-price'
                            value={searchParams.get('product_max-price')}
                            onChange={handleFilterChange}
                            min={searchParams.get('product_min-price') + 1 || 0}
                        />
                    </div>
                </div>
                {searchParams.size ? (
                    <Button
                        className='rds-m_top__md'
                        style={{ width: '100%' }}
                        label='Restaurar'
                        icon={IconTypes.RELOAD}
                        onClick={restoreFilters}
                    />
                ) : null}
            </Card>
        </div>
    )
}

export default Filters
