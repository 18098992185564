const PICTURE_FORMATS = Object.freeze(['jpg', 'jpeg', 'png', 'webp'])
const VIDEO_FORMATS = Object.freeze([
    'mp4',
    'webm',
    'ogg',
    'mov',
    'avi',
    'wmv',
    'flv',
    'mkv',
])

/**
 * Checks if the given format is a picture format.
 * @param {string} format - The format to check.
 * @returns {boolean} - Returns true if the format is a picture format, otherwise false.
 */
export const getIsPicture = format => {
    if (!format) {
        return false
    }
    return !!PICTURE_FORMATS.find(standardFormat =>
        format.includes(standardFormat),
    )
}

/**
 * Checks if a given format is a video format.
 *
 * @param {string} format - The format to check.
 * @returns {boolean} - Returns true if the format is a video format, otherwise returns false.
 */
export const getIsVideo = format => {
    if (!format) {
        return false
    }
    return !!VIDEO_FORMATS.find(standardFormat =>
        format.includes(standardFormat),
    )
}

/**
 * Builds a Cloudinary thumbnail URL
 * @param {String} url Cloudinary picture URL
 * @param {Number} width Width in pixels of the thumbnail to request
 * @param {Number} height Height in pixels of the thumbnail to request
 * @returns Cloudinary thumbnail URL
 */
export const getPictureThumbnail = (url, width, height) => {
    let thumbnailTransformation = 'c_thumb'
    if (width) {
        thumbnailTransformation += `,w_${width}`
    }
    if (height) {
        thumbnailTransformation += `,h_${height}`
    }
    const splitter = '/upload/'
    const splittedURL = url.split(splitter)
    return `${splittedURL[0]}${splitter}${thumbnailTransformation}/${splittedURL[1]}`
}
