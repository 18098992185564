import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
    Card,
    cssRules,
    Icon,
    IconTypes,
    ThemeContext,
    Typography,
    TypographyTypes,
} from 'rds'

const StyledProductResultImage = styled.img`
    object-fit: cover;
    ${props => cssRules.borderRadiusRule(props)}
`

const SearchResults = ({ searchResults, onSelect }) => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <div>
            {searchResults ? (
                !searchResults.length ? (
                    <div className='rds-flexbox align-center direction-column'>
                        <Icon
                            icon={IconTypes.EMPTY}
                            className='rds-m_top__sm rds-m_bottom__sm'
                        />
                        <Typography>No se encontraron resultados</Typography>
                    </div>
                ) : (
                    searchResults.map(product => (
                        <Link
                            to={`/products/${product._id}`}
                            key={product._id}
                            className='rds-full-block rds-m_bottom__sm'
                            onClick={onSelect}
                        >
                            <Card
                                className='rds-grid'
                                style={{
                                    gridTemplateColumns: '80px auto',
                                    boxShadow: cssRules.boxShadowValue({
                                        themeContext,
                                    }),
                                }}
                            >
                                {product.pictures?.length ? (
                                    <StyledProductResultImage
                                        themeContext={themeContext}
                                        src={product.pictures[0].secure_url}
                                        alt={product.name}
                                        width={80}
                                        height={80}
                                    />
                                ) : null}
                                <div className='rds-grid rds-p_left__sm'>
                                    <Typography type={TypographyTypes.H3}>
                                        {product.name}
                                    </Typography>
                                    <Typography
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {product.description}
                                    </Typography>
                                    <Typography
                                        className='rds-m_top__sm'
                                        type={TypographyTypes.H4}
                                    >
                                        ${product.priceTag}
                                    </Typography>
                                    {product.variants.length > 1 ? (
                                        <Typography>
                                            {product.variants.length} variantes
                                        </Typography>
                                    ) : null}
                                </div>
                            </Card>
                        </Link>
                    ))
                )
            ) : null}
        </div>
    )
}

export default SearchResults
