import React from 'react'
import styled from 'styled-components'
import { cssRules, ThemeContext } from 'rds'
import { useNavigate } from 'react-router-dom'
import { applyEffectToImage } from '../../utils'

const StyledImage = styled.img`
    ${props => `
        display: block;
        width: ${props.margin ? `calc(100% - ${props.margin * 2}px)` : '100%'};
        ${props.navigateToURL ? 'cursor: pointer;' : ''}
        ${props.margin ? `margin: ${props.margin}px;` : ''}
        ${props.roundedBorder ? cssRules.borderRadiusRule(props) : ''}
        ${props.bordered ? cssRules.borderRule(props) : ''}
        ${props.objectFit ? `object-fit: ${props.objectFit};` : ''}
        ${
            props.objectPosition
                ? `object-position: ${props.objectPosition};`
                : ''
        }
    `}
`

const Image = props => {
    const navigate = useNavigate()
    const themeContext = React.useContext(ThemeContext)
    const { navigateToURL } = props

    const handleClick = () => {
        if (navigateToURL) {
            if (
                navigateToURL.includes('http:') ||
                navigateToURL.includes('https:')
            ) {
                window.location.assign(navigateToURL)
            } else {
                navigate(navigateToURL)
            }
        }
    }

    return (
        <StyledImage
            {...props}
            src={applyEffectToImage(props.src, props.effect, themeContext)}
            themeContext={themeContext}
            onClick={navigateToURL ? handleClick : undefined}
        />
    )
}

export default Image
