import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Video ID',
            es: 'ID del video',
        },
        name: 'videoId',
        tooltip: {
            en: 'The video YouTube ID',
            es: 'El ID del video de YouTube',
        },
    },
    {
        label: {
            en: 'Height',
            es: 'Altura',
        },
        min: 0,
        name: 'height',
        tooltip: {
            en: 'The height of the video',
            es: 'La altura del video',
        },
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Style',
            es: 'Estilo',
        },
    },
    {
        label: {
            en: 'Allow Full Screen',
            es: 'Permitir pantalla completa',
        },
        name: 'allowFullScreen',
        tooltip: {
            en: 'Whether to allow full screen',
            es: 'Si se permite pantalla completa',
        },
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Configuration',
            es: 'Configuración',
        },
    },
    {
        label: {
            en: 'Auto Play',
            es: 'Reproducción automática',
        },
        name: 'autoPlay',
        tooltip: {
            en: 'Whether to auto play the video',
            es: 'Si se reproduce automáticamente el video',
        },
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Configuration',
            es: 'Configuración',
        },
    },
    {
        label: {
            en: 'Start Muted',
            es: 'Comenzar silenciado',
        },
        name: 'startMuted',
        tooltip: {
            en: 'Whether to start the video muted',
            es: 'Si se comienza el video silenciado',
        },
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Configuration',
            es: 'Configuración',
        },
    },
    {
        label: {
            en: 'Show Controls',
            es: 'Mostrar controles',
        },
        name: 'showControls',
        tooltip: {
            en: 'Whether to show the video controls',
            es: 'Si se muestran los controles del video',
        },
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Configuration',
            es: 'Configuración',
        },
    },
    {
        label: {
            en: 'Loop',
            es: 'Repetir',
        },
        name: 'loop',
        tooltip: {
            en: 'Whether to loop the video',
            es: 'Si se repite el video',
        },
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Configuration',
            es: 'Configuración',
        },
    },
    {
        label: {
            en: 'Start',
            es: 'Comienzo',
        },
        name: 'start',
        tooltip: {
            en: 'The start time of the video',
            es: 'El tiempo de comienzo del video',
        },
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Configuration',
            es: 'Configuración',
        },
    },
    {
        label: {
            en: 'End',
            es: 'Final',
        },
        name: 'end',
        tooltip: {
            en: 'The end time of the video',
            es: 'El tiempo final del video',
        },
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Configuration',
            es: 'Configuración',
        },
    },
    {
        label: {
            en: 'Overlay Color',
            es: 'Color de superposición',
        },
        name: 'overlayColor',
        tooltip: {
            en: 'The color of the overlay',
            es: 'El color de la superposición',
        },
        type: FormItemTypes.COLOR,
        category: {
            en: 'Style',
            es: 'Estilo',
        },
    },
    {
        label: {
            en: 'Overlay Opacity',
            es: 'Opacidad de superposición',
        },
        name: 'overlayOpacity',
        tooltip: {
            en: 'The opacity of the overlay',
            es: 'La opacidad de la superposición',
        },
        type: FormItemTypes.RANGE,
        min: 0,
        max: 1,
        step: 0.1,
        category: {
            en: 'Style',
            es: 'Estilo',
        },
    },
]

export default props
