import { io } from 'socket.io-client'
import {
    REANGO_GUEST_TOKEN_LOCALSTORAGE_NAME,
    REANGO_TOKEN_LOCALSTORAGE_NAME,
} from './constants'
import { DEBUG_MODE } from './utils'

const webstoreUserToken = localStorage.getItem(REANGO_TOKEN_LOCALSTORAGE_NAME)
const webstoreGuestToken = localStorage.getItem(
    REANGO_GUEST_TOKEN_LOCALSTORAGE_NAME,
)
const token = webstoreUserToken || webstoreGuestToken
const config = {
    auth: {
        projectId: process.env.REACT_APP_PROJECT_NAME,
        token,
    },
}
const uri = new URL(process.env.REACT_APP_API_URL)
if (process.env.REACT_APP_ENV !== 'production' && uri.pathname !== '/') {
    config.path = `${uri.pathname}/socket.io`
}
if (DEBUG_MODE) {
    console.log('[SOCKET]', `Connecting to ${uri.origin} with config:`, config)
}
export const socket = io(uri.origin, config)
