import React from 'react'
import { useSelector } from 'react-redux'
import styledComponents from 'styled-components'
import { Card, cssRules, HeaderSizes, ThemeContext, Typography } from 'rds'

const StyledShipmentZoneIndexColor = styledComponents.div`${props => `
    background-color: ${props.zone.color};
    width: 20px;
    height: 20px;
    ${cssRules.borderRadiusRule(props)}
`}`

const ShipmentZonesList = ({ title = 'Costos de envío', text, headerSize = HeaderSizes.MEDIUM }) => {
    const locations = useSelector(state => state.location.locations)
    const themeContext = React.useContext(ThemeContext)
    return (
        <Card
            className='shipment-zones_costs-panel'
            headerTitle={title}
            headerText={text}
            headerSize={headerSize}
        >
            {locations
                .filter(location => location.shipmentCosts.length)
                .map((location, index) => (
                    <Card
                        key={index}
                        headerTitle={location.name}
                        headerText={location.address}
                        headerSize={HeaderSizes.XSMALL}
                        className='rds-m_bottom__sm'
                    >
                        {location.shipmentCosts.map((zone, index) => (
                            <div
                                key={index}
                                className='rds-flexbox align-center'
                            >
                                <StyledShipmentZoneIndexColor
                                    themeContext={themeContext}
                                    zone={zone}
                                    className='rds-m_right__sm'
                                ></StyledShipmentZoneIndexColor>
                                <Typography>
                                    {zone.cost ? `$${zone.cost}` : 'Gratis'}
                                </Typography>
                            </div>
                        ))}
                    </Card>
                ))}
        </Card>
    )
}

export default ShipmentZonesList
