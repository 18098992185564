import React from 'react'
import { Label, Typography } from 'rds'

const CartEntryCustomFieldItem = ({ label, children }) => {
    return (
        <div className='rds-full-block rds-m_bottom__md'>
            <Label>{label}</Label>
            <Typography>{children}</Typography>
        </div>
    )
}

export default CartEntryCustomFieldItem
