import moment from 'moment'

/**
 * Returns a formatted date label.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date label.
 */
export const getDateLabel = date => {
    const momentDate = moment(date)
    const day = momentDate.format('dddd D MMMM YYYY')
    const time = momentDate.format('HH:mm')
    return `${day}, ${time}`
}
