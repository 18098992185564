import {
    REANGO_WEBSTORE_BUILDER_MESSAGES,
    ELEMENT_HIGHLIGHTED_CLASSNAME,
} from '../constants'
import {
    fetchPages,
    fetchPage,
    getSubscription,
} from '../reducers/sessionReducer'
import componentsMap from '../builder/components'
import { DEBUG_MODE, isBuilder } from '../utils'

const DEBUG_PREFIX = '[BUILDER_HANDLER]'

/**
 * Triggers the animation for highlighting the given component.
 *
 * @param {string} componentId - The ID of the component to locate.
 */
const scrollToElement = componentId => {
    const condition = `[data-component-id="${componentId}"]`
    const matchingElement = document.querySelector(condition)
    if (!matchingElement) {
        console.error(DEBUG_PREFIX, `Element with "${condition}" not found`)
        return
    }
    const topbarHeight = document.querySelector('#nav').clientHeight
    const distanceFromTop =
        matchingElement.offsetTop - matchingElement.scrollTop
    window.scrollTo({
        top: distanceFromTop - topbarHeight - matchingElement.clientHeight,
        left: 0,
        behavior: 'smooth',
    })
    matchingElement.classList.add(ELEMENT_HIGHLIGHTED_CLASSNAME)
}

/**
 * Handles locating a setting within the component.
 *
 * @param {string} componentId - The ID of the component to locate.
 */
const handleLocateSetting = componentId => {
    // Remove all exiting highlightings
    const alreadyHighlightedEls = document.querySelectorAll(
        `.${ELEMENT_HIGHLIGHTED_CLASSNAME}`,
    )
    alreadyHighlightedEls.forEach(alreadyHighlightedEl =>
        alreadyHighlightedEl.classList.remove(ELEMENT_HIGHLIGHTED_CLASSNAME),
    )
    if (componentId) {
        // Scroll to selected component
        scrollToElement(componentId)
    }
}

export const handleBuilderMessages = (event, dispatch) => {
    if (
        DEBUG_MODE &&
        event.data.type in REANGO_WEBSTORE_BUILDER_MESSAGES &&
        isBuilder
    ) {
        console.log(DEBUG_PREFIX, 'Received:', event.data, 'from', event.origin)
    }
    switch (event.data.type) {
        case REANGO_WEBSTORE_BUILDER_MESSAGES.FETCH_WEBSTORE_SETTINGS:
            dispatch(getSubscription())
            break
        case REANGO_WEBSTORE_BUILDER_MESSAGES.FETCH_PAGES:
            dispatch(fetchPages())
            break
        case REANGO_WEBSTORE_BUILDER_MESSAGES.FETCH_PAGE:
            dispatch(fetchPage(event.data.payload.pageId))
            break
        case REANGO_WEBSTORE_BUILDER_MESSAGES.LOCATE_SETTING:
            handleLocateSetting(event.data.payload)
            break
        case REANGO_WEBSTORE_BUILDER_MESSAGES.COMPONENT_PROPS_REQUEST:
            if (DEBUG_MODE) {
                console.log(
                    DEBUG_PREFIX,
                    'Responding to components list request',
                )
            }
            window.parent.postMessage(
                {
                    type: REANGO_WEBSTORE_BUILDER_MESSAGES.COMPONENT_PROPS_RESPONSE,
                    payload: JSON.stringify(componentsMap),
                },
                '*',
            )
            break
        default:
            break
    }
}
