import { ThemeContext, cssRules } from 'rds'
import React from 'react'
import styledComponents, { keyframes, css } from 'styled-components'

const productSkeletonKeyframes = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
`

const productSkeletonAnimation = () => css`
    ${productSkeletonKeyframes} 1s infinite;
`

const StyledProductDetailSkeleton = styledComponents.div`
    animation: ${productSkeletonAnimation};
    ${props => `
        ${cssRules.backgroundRule(props)} 
        ${cssRules.borderRadiusRule(props)}
    `}`

const ProductDetailSkeleton = () => {
    const themeContext = React.useContext(ThemeContext)
    return (
        <div className='rds-grid rds-grid_2-columns'>
            <div
                className='rds-grid rds-grid_2-columns'
                style={{
                    gridTemplateColumns:
                        'var(--productDetailPictureSelectorPictureWidth) auto',
                }}
            >
                <div>
                    <StyledProductDetailSkeleton
                        themeContext={themeContext}
                        className='rds-p_around__md'
                        style={{
                            height: 'var(--productDetailPictureSelectorPictureWidth)',
                        }}
                    />
                    <StyledProductDetailSkeleton
                        themeContext={themeContext}
                        className='rds-p_around__md rds-m_top__sm'
                        style={{
                            height: 'var(--productDetailPictureSelectorPictureWidth)',
                        }}
                    />
                </div>
                <StyledProductDetailSkeleton
                    themeContext={themeContext}
                    className='rds-p_around__md'
                    style={{
                        height: '400px',
                    }}
                />
            </div>
            <StyledProductDetailSkeleton
                themeContext={themeContext}
                className='rds-p_around__md'
                style={{
                    height: '600px',
                }}
            />
            <div></div>
            <StyledProductDetailSkeleton
                themeContext={themeContext}
                className='rds-p_around__md'
                style={{
                    height: '250px',
                }}
            />
        </div>
    )
}

export default ProductDetailSkeleton
