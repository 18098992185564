import {
    Block,
    cssRules,
    ThemeContext,
    ThemesNames,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import React from 'react'
import styles from './FeaturedCategories.module.css'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getParsedColor } from '../../utils'

const StyledFeaturedCategory = styled.div`
    height: 100%;
    & .featured-category {
        position: relative;
    }
    & .featured-category:hover {
        z-index: 1;
        box-shadow: 0 0 35px 10px
            ${props =>
                props.themeContext.scheme === ThemesNames.LIGHT
                    ? 'white'
                    : 'black'};
    }
`

const FeaturedCategories = ({
    headerVariant = ThemeVariants.WHITE,
    useCategoryPicture,
    overlayColor,
    overlayColorAlpha = 50,
    fullWidth,
}) => {
    const themeContext = React.useContext(ThemeContext)
    const sessionSubscription = useSelector(
        state => state.session.subscription,
        shallowEqual,
    )
    const [featuredCategories, setFeaturedCategories] = React.useState([])

    React.useEffect(() => {
        if (!sessionSubscription || !sessionSubscription.webstore) {
            return
        }
        setFeaturedCategories(
            sessionSubscription.webstore.settings.featuredCategories,
        )
    }, [sessionSubscription])

    const parsedOverlayColor = getParsedColor(
        overlayColor || cssRules.blackValue(themeContext),
        overlayColorAlpha,
    )
    return Array.isArray(featuredCategories) && featuredCategories.length ? (
        <div className={`featured-categories ${fullWidth ? '' : 'container'}`}>
            <div
                className={`rds-grid ${
                    featuredCategories.length > 1 ? 'rds-grid_2-columns' : ''
                } ${styles['featured-categories_inner']}`}
            >
                {featuredCategories.map(featuredCategory => (
                    <Link
                        to={`/categories/${featuredCategory._id}`}
                        key={featuredCategory._id}
                    >
                        <StyledFeaturedCategory themeContext={themeContext}>
                            <Block
                                roundedBorder={!fullWidth}
                                variant={ThemeVariants.GRAY}
                                className={`rds-p_around__md featured-category ${
                                    useCategoryPicture ? 'rds-p_top' : null
                                }`}
                                style={{
                                    height: '100%',
                                    backgroundImage: `linear-gradient(${parsedOverlayColor}, ${parsedOverlayColor})${
                                        useCategoryPicture &&
                                        featuredCategory.picture
                                            ? `, url(${featuredCategory.picture.secure_url})`
                                            : ''
                                    }`,
                                }}
                            >
                                <div
                                    className={
                                        useCategoryPicture &&
                                        featuredCategory.picture
                                            ? 'rds-m_top'
                                            : null
                                    }
                                >
                                    <Typography
                                        variant={headerVariant}
                                        type={TypographyTypes.H1}
                                    >
                                        {featuredCategory.name}
                                    </Typography>
                                    <Typography
                                        variant={headerVariant}
                                        type={TypographyTypes.H3}
                                    >
                                        {featuredCategory.description}
                                    </Typography>
                                </div>
                            </Block>
                        </StyledFeaturedCategory>
                    </Link>
                ))}
            </div>
        </div>
    ) : null
}

export default FeaturedCategories
