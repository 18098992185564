import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import api from '../../api'
import { SALE_STATUSES } from '../../constants'
import ActivePurchaseTrackerItem from './ActivePurchaseTrackerItem'

const ActivePurchaseTracker = () => {
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const [activeSales, setActiveSales] = React.useState([])
    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    const getContactSales = React.useCallback(async () => {
        if (!sessionUser.contact) {
            return
        }
        const contactId = sessionUser.contact._id || sessionUser.contact
        const endpoint = `sales?state=true&contact=${contactId}&status=!${SALE_STATUSES.DELIVERED}&status=!${SALE_STATUSES.DRAFT}`
        const res = await api.get(endpoint)
        if (mounted.current && Array.isArray(res.data.sales)) {
            setActiveSales(res.data.sales)
        }
    }, [sessionUser.contact])

    React.useEffect(() => {
        getContactSales()
    }, [getContactSales])

    if (!activeSales.length) {
        return null
    }
    return activeSales.map(activeSale => (
        <ActivePurchaseTrackerItem key={activeSale._id} sale={activeSale} />
    ))
}

export default ActivePurchaseTracker
