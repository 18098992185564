import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TypographyTypes } from 'rds'
import api from '../../../api'
import { sessionSetUser } from '../../../reducers/sessionReducer'
import Standard from './variants/Standard'
import Background from './variants/Background'

const ProductCard = ({ product }) => {
    const dispatch = useDispatch()
    const sessionUserId = useSelector(state => state.session.user._id)
    const settingsFontSize = useSelector(
        state => state.session.subscription.webstore.settings.products.fontSize,
    )
    const settingsCardType = useSelector(
        state => state.session.subscription.webstore.settings.products.cardType,
    )
    const [carouselPosition, setCarouselPosition] = React.useState(0)

    const getNameTypographyType = settingsFontSize => {
        let nameTypographyType
        switch (settingsFontSize) {
            case 'XSMALL':
                nameTypographyType = TypographyTypes.H4
                break
            case 'SMALL':
                nameTypographyType = TypographyTypes.H3
                break
            case 'MEDIUM':
                nameTypographyType = TypographyTypes.H2
                break
            case 'LARGE':
                nameTypographyType = TypographyTypes.H1
                break
            default:
                console.error(
                    '[PRODUCT_CARD]',
                    'Font size setting not recognized',
                )
                break
        }
        return nameTypographyType
    }

    const handleFav = async () => {
        const res = await api.put(
            `users/${sessionUserId}/favorites/${product._id}`,
        )
        dispatch(sessionSetUser(res.data.user))
    }

    const handleCarrouselClick = isRight => {
        let newValue = carouselPosition
        if (isRight) {
            newValue++
        } else {
            newValue--
        }
        setCarouselPosition(newValue)
    }

    const nameTypographyType = getNameTypographyType(settingsFontSize)
    if (settingsCardType === 'BACKGROUND') {
        return (
            <Background
                product={product}
                onFavClick={handleFav}
                nameTypographyType={nameTypographyType}
            />
        )
    }
    return (
        <Standard
            product={product}
            carouselPosition={carouselPosition}
            onCarrouselClick={handleCarrouselClick}
            onFavClick={handleFav}
            nameTypographyType={nameTypographyType}
        />
    )
}

export default ProductCard
