import React from 'react'
import {
    Button,
    Card,
    Form,
    FormItem,
    FormItemTypes,
    FormItemSizes,
    Header,
    HeaderSizes,
    IconTypes,
    Typography,
    TypographyTypes,
    ButtonVariants,
    ThemeVariants,
} from 'rds'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { REANGO_TOKEN_LOCALSTORAGE_NAME } from '../../constants'
import { isBuilder } from '../../utils'
import { sessionSetUser } from '../../reducers/sessionReducer'
import api from '../../api'
import UserAddressItem from './UserAddressItem'
import Modal from '../../layout/Modal'

const UserEditForm = () => {
    const dispatch = useDispatch()

    const sessionUser = useSelector(state => state.session.user, shallowEqual)

    const initialUpdatedUser = {
        ...sessionUser,
        phonePrefix: '+598',
    }
    const [updatedUser, setUpdatedUser] = React.useState(initialUpdatedUser)
    const [addresses, setAddresses] = React.useState([])
    const [modal, setModal] = React.useState({
        visible: false,
        form: {
            city: 'Montevideo',
            country: 'Uruguay',
        },
    })

    const fetchAddresses = contactId => {
        api.get(`contacts/${contactId}/addresses`).then(res => {
            setAddresses(res.data.contactAddresses)
        })
    }

    React.useEffect(() => {
        const isLoggedIn = localStorage.getItem(REANGO_TOKEN_LOCALSTORAGE_NAME)
        if (!isLoggedIn && !isBuilder) {
            window.location.assign('/login')
        }
        const contactId = sessionUser.contact?._id || sessionUser.contact
        if (contactId) {
            fetchAddresses(contactId)
        }
    }, [sessionUser.contact])

    React.useEffect(() => {
        if (!updatedUser._id) {
            setUpdatedUser(sessionUser)
        }
    }, [updatedUser, sessionUser])

    const handleSubmit = async () => {
        try {
            const res = await api.put(`users/${sessionUser._id}`, {
                name: updatedUser.name,
                email: updatedUser.email,
                phone: updatedUser.phonePrefix + updatedUser.phone,
            })
            dispatch(sessionSetUser(res.data.user))
            alert('Tu perfil se actualizó correctamente')
        } catch (error) {
            console.error('[USER_EDIT_FORM]', error.response)
            alert(error.response.data.message)
        }
    }

    const handleInputChange = (key, value) => {
        setUpdatedUser(updatedUser => ({
            ...updatedUser,
            [key]: value,
        }))
    }

    const handleRemoveAddress = async addressId => {
        try {
            if (!window.confirm('Estas seguro?')) {
                return
            }
            const contactId = sessionUser.contact._id || sessionUser.contact
            await api.delete(`contacts/${contactId}/addresses/${addressId}`)
            fetchAddresses(contactId)
        } catch (error) {
            console.error('[USER_EDIT_FORM]', error.response)
        }
    }

    const handleAddAddress = async () => {
        setModal(modal => ({ ...modal, form: {}, visible: true }))
    }

    const handleAddressSubmit = async () => {
        try {
            const contactId = sessionUser.contact._id || sessionUser.contact
            if (modal.form._id) {
                await api.put(
                    `/contacts/${contactId}/addresses/${modal.form._id}`,
                    modal.form,
                )
            } else {
                await api.post(`/contacts/${contactId}/addresses`, modal.form)
            }
            fetchAddresses(contactId)
            setModal(modal => ({ ...modal, visible: false }))
        } catch (error) {
            console.error('[DELIVER_FORM]', error)
            alert(error.response.data.message)
        }
    }

    if (!updatedUser._id) {
        return (
            <Card
                headerIcon={IconTypes.WARNING}
                headerTitle='Error'
                headerText='Inicia sesión para ver este componente'
                headerSize={HeaderSizes.SMALL}
                variant={ThemeVariants.ERROR}
            ></Card>
        )
    }
    return (
        <div>
            <Modal
                visible={modal.visible}
                onClose={() => setModal({ ...modal, visible: false })}
            >
                <div className='rds-flexbox align-center justify-between'>
                    <Header
                        className='rds-m_right__md'
                        title={
                            modal.form._id
                                ? 'Actualizar dirección'
                                : 'Nueva dirección'
                        }
                        text='Completa los datos de la dirección'
                        size={HeaderSizes.SMALL}
                    />
                    <Button
                        label='Cerrar'
                        variant={ButtonVariants.TRANSPARENT}
                        onClick={() => setModal({ ...modal, visible: false })}
                    />
                </div>
                <Form
                    className='rds-m_top__md'
                    submitCallback={handleAddressSubmit}
                    submitLabel='Guardar'
                >
                    <FormItem
                        className='rds-grid_3-columns_item'
                        id='street'
                        label='Calle'
                        onChange={e =>
                            setModal({
                                ...modal,
                                form: {
                                    ...modal.form,
                                    street: e.target.value,
                                },
                            })
                        }
                        value={modal.form.street}
                        size={FormItemSizes.BIG}
                        required
                    />
                    <FormItem
                        className='rds-grid_3-columns_item'
                        id='doorNumber'
                        label='Número de puerta'
                        onChange={e =>
                            setModal({
                                ...modal,
                                form: {
                                    ...modal.form,
                                    doorNumber: e.target.value,
                                },
                            })
                        }
                        value={modal.form.doorNumber}
                        size={FormItemSizes.BIG}
                        required
                    />
                    <FormItem
                        className='rds-grid_3-columns_item'
                        id='apartment'
                        label='Apartamento (opcional)'
                        onChange={e =>
                            setModal({
                                ...modal,
                                form: {
                                    ...modal.form,
                                    apartment: e.target.value,
                                },
                            })
                        }
                        value={modal.form.apartment}
                        size={FormItemSizes.BIG}
                    />
                    <FormItem
                        className='rds-grid_3-columns_item'
                        id='zipCode'
                        label='Código postal'
                        onChange={e =>
                            setModal({
                                ...modal,
                                form: {
                                    ...modal.form,
                                    zipCode: e.target.value,
                                },
                            })
                        }
                        value={modal.form.zipCode}
                        size={FormItemSizes.BIG}
                        required
                    />
                    <FormItem
                        className='rds-grid_3-columns_item'
                        id='city'
                        label='Ciudad'
                        type={FormItemTypes.SELECT}
                        values={[{ label: 'Montevideo', id: 'Montevideo' }]}
                        onChange={e =>
                            setModal({
                                ...modal,
                                form: {
                                    ...modal.form,
                                    city: e.target.value,
                                },
                            })
                        }
                        value={modal.form.city}
                        forceDropdown
                        size={FormItemSizes.BIG}
                        required
                    />
                    <FormItem
                        className='rds-grid_3-columns_item'
                        id='country'
                        label='País'
                        type={FormItemTypes.SELECT}
                        values={[{ label: 'Uruguay', id: 'Uruguay' }]}
                        onChange={e =>
                            setModal({
                                ...modal,
                                form: {
                                    ...modal.form,
                                    country: e.target.value,
                                },
                            })
                        }
                        value={modal.form.country}
                        size={FormItemSizes.BIG}
                        forceDropdown
                        required
                    />
                    <FormItem
                        className='rds-grid_3-columns_item'
                        id='notes'
                        label='Notas'
                        type={FormItemTypes.TEXTAREA}
                        onChange={e =>
                            setModal({
                                ...modal,
                                form: {
                                    ...modal.form,
                                    notes: e.target.value,
                                },
                            })
                        }
                        value={modal.form.notes}
                        size={FormItemSizes.BIG}
                    />
                </Form>
            </Modal>
            <Header title='Editar mi perfil' text='' />
            <Card
                headerTitle='Información Personal'
                headerSize={HeaderSizes.SMALL}
                className='rds-m_top__md'
            >
                <Form submitCallback={handleSubmit} submitLabel='Guardar'>
                    <FormItem
                        label='Nombre'
                        value={updatedUser.name}
                        onChange={e => {
                            handleInputChange('name', e.currentTarget.value)
                        }}
                        className='rds-grid_3-columns_item rds-m_bottom__sm'
                        required
                    />
                    <FormItem
                        label='Email'
                        value={updatedUser.email}
                        type={FormItemTypes.EMAIL}
                        onChange={e => {
                            handleInputChange('email', e.currentTarget.value)
                        }}
                        className='rds-grid_3-columns_item rds-m_bottom__sm'
                        required
                    />
                    <div className='rds-grid_3-columns_item'>
                        <Typography>Teléfono</Typography>
                        <div className='rds-flexbox rds-m_top__sm'>
                            <FormItem
                                type={FormItemTypes.SELECT}
                                className='rds-m_right__sm'
                                values={[
                                    {
                                        label: <>🇺🇾&nbsp;+598&nbsp;</>,
                                        value: '+598',
                                        id: '+598',
                                    },
                                ]}
                                value={updatedUser.phonePrefix}
                                style={{ width: 'auto' }}
                                forceDropdown
                            />
                            <FormItem
                                onChange={e => {
                                    handleInputChange(
                                        'phone',
                                        e.currentTarget.value,
                                    )
                                }}
                                value={updatedUser.phone}
                            />
                        </div>
                    </div>
                </Form>
            </Card>
            <Card className='rds-m_top__md'>
                <div className='rds-flexbox align-center justify-between'>
                    <Typography type={TypographyTypes.H3}>
                        Direcciones
                    </Typography>
                    <Button
                        label='Agregar dirección'
                        icon={IconTypes.ADD}
                        onClick={handleAddAddress}
                    />
                </div>
                <div className='rds-m_top__md'>
                    {addresses.map(contactAddress => (
                        <Card
                            key={contactAddress._id}
                            className='rds-m_top__sm rds-flexbox align-top justify-between'
                        >
                            <UserAddressItem
                                address={contactAddress}
                                onRemove={handleRemoveAddress}
                                onUpdate={addressId =>
                                    setModal({
                                        ...modal,
                                        visible: true,
                                        form: addresses.find(
                                            a => a._id === addressId,
                                        ),
                                    })
                                }
                            />
                        </Card>
                    ))}
                </div>
            </Card>
        </div>
    )
}

export default UserEditForm
