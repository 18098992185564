import React from 'react'
import { Icon, Typography, TypographyTypes } from 'rds'

const CheckoutFormOptionLabel = ({ icon, label }) => {
    return (
        <div>
            <Icon type={icon} size={17} />
            <Typography
                type={TypographyTypes.H3}
                className='rds-full-block rds-m_top__sm'
            >
                {label}
            </Typography>
        </div>
    )
}

export default CheckoutFormOptionLabel
