import React from 'react'
import CartEntryCustomFieldItem from './CartEntryCustomFieldItem'

const CartEntryAdditionals = ({
    entryAdditionals = {},
    customFields = [],
    boldMode,
}) => {
    if (typeof entryAdditionals !== 'object' || !customFields.length) {
        return null
    }

    const getContent = (selectedAdditional, isLastOne) =>
        `${selectedAdditional}${isLastOne ? '' : ', '}`

    return Object.keys(entryAdditionals).map(additionalAPIName => {
        const additional = customFields.find(
            customField => customField.apiName === additionalAPIName,
        )
        const selectedAdditionals = entryAdditionals[additionalAPIName]
        if (!additional) {
            return null
        }
        return (
            <CartEntryCustomFieldItem
                key={additional._id}
                label={additional.name}
            >
                {selectedAdditionals.length
                    ? selectedAdditionals.map((selectedAdditional, i) => {
                          const isLastOne = i === selectedAdditionals.length - 1
                          const content = getContent(
                              selectedAdditional,
                              isLastOne,
                          )
                          return boldMode ? (
                              <b key={selectedAdditional}>{content}</b>
                          ) : (
                              <span key={selectedAdditional}>{content}</span>
                          )
                      })
                    : '-'}
            </CartEntryCustomFieldItem>
        )
    })
}

export default React.memo(CartEntryAdditionals)
