import React from 'react'
import CartEntryCustomFieldItem from './CartEntryCustomFieldItem'

const CartEntryCustomFields = ({
    entryCustomFields = {},
    customFields = [],
    boldMode = false,
}) => {
    const getCustomFieldLabel = customFieldAPIName => {
        const matchingField = customFields.find(
            customField => customField.apiName === customFieldAPIName,
        )
        if (matchingField) {
            return matchingField.name
        }
    }

    const getCustomFieldValue = customFieldValue => {
        let safeFieldValue = customFieldValue
        if (typeof safeFieldValue === 'boolean') {
            safeFieldValue = safeFieldValue ? 'Si' : 'No'
        }
        if (boldMode) {
            return <b>{safeFieldValue}</b>
        }
        return safeFieldValue
    }

    if (typeof entryCustomFields !== 'object') {
        return null
    }
    return Object.keys(entryCustomFields).map((customField, index) => {
        const customFieldLabel = getCustomFieldLabel(customField)
        if (!customFieldLabel) {
            // If custom field's label is not found, this could
            // mean the field was deleted
            return null
        }
        return (
            <CartEntryCustomFieldItem key={index} label={customFieldLabel}>
                {getCustomFieldValue(entryCustomFields[customField])}
            </CartEntryCustomFieldItem>
        )
    })
}

export default React.memo(CartEntryCustomFields)
