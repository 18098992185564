import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
    Button,
    Card,
    HeaderSizes,
    Icon,
    IconTypes,
    ThemeContext,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import { getDeliverTypeLabel, getPaymentTypeLabel } from '../../utils/sale'
import api from '../../api'
import { socket } from '../../socket'
import PaymentDetailsItem from './PaymentDetailsItem'
import ProductCardSkeleton from '../Product/ProductCard.skeleton'
import { SALE_STATUSES } from '../../constants'

const DEBUG_PREFIX = '[PURCHASE_DETAILS]'

const StyledRealTimeIndicator = styled.div(props => ({
    borderRadius: '100%',
    backgroundColor: props.themeContext.main,
    width: 15,
    height: 15,
    '&:before': {
        content: '""',
        display: 'block',
        width: 'inherit',
        height: 'inherit',
        backgroundColor: 'inherit',
        borderRadius: 'inherit',
    },
}))

const PurchaseDetails = ({ saleId, showSaleStatusBanner = true }) => {
    const params = useParams()
    const themeContext = React.useContext(ThemeContext)
    const socketConnected = React.useRef()
    const [sale, setSale] = React.useState({})
    const [googleLocationSearch, setGoogleLocationSearch] = React.useState('')
    const locations = useSelector(state => state.location.locations)
    const location = locations?.find(location => location.id === sale.location)

    const getMapAddress = async sale => {
        let googleLocationSearch
        if (sale.address) {
            googleLocationSearch = sale.addressGoogleMapsSearch || sale.address
        } else if (sale.location) {
            const res = await api.get('locations/' + sale.location)
            googleLocationSearch = res.data.location.addressGoogleMapsSearch
        }
        setGoogleLocationSearch(googleLocationSearch)
    }

    const fetchSale = async saleId => {
        try {
            const res = await api.get(`sales/${saleId}`)
            setSale(res.data.sale)
        } catch (error) {
            console.error(DEBUG_PREFIX, error.response.data)
        }
    }

    React.useEffect(() => {
        if (sale.address || sale.location) {
            getMapAddress(sale)
        }
    }, [sale])

    React.useEffect(() => {
        // Do initial fetch
        const safeSaleId = saleId || params.purchaseId
        fetchSale(safeSaleId)

        // Handle real-time updates
        function onSaleChange(data) {
            if (data.documentKey?._id === safeSaleId) {
                fetchSale(safeSaleId)
            }
        }
        if (showSaleStatusBanner && !socketConnected.current) {
            socket.on('sales:update', onSaleChange)
            socketConnected.current = true
        }

        // Unsubscribe from real-time updates
        return () => {
            if (showSaleStatusBanner) {
                socket.off('sales:update', onSaleChange)
            }
        }
    }, [showSaleStatusBanner, saleId, params.purchaseId])

    if (!sale.id) {
        return (
            <div>
                <div className='rds-m_bottom__sm'>
                    <ProductCardSkeleton />
                </div>
                <div className='rds-m_bottom__sm'>
                    <ProductCardSkeleton />
                </div>
                <div className='rds-m_bottom__sm'>
                    <ProductCardSkeleton />
                </div>
            </div>
        )
    }
    return (
        <div>
            <Card style={{ padding: 'unset' }}>
                <iframe
                    title='Sale delivery/pick-up location'
                    className='rds-full-block'
                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${googleLocationSearch}`}
                    style={{
                        height: '200px',
                    }}
                    height=''
                    frameBorder={0}
                    allowFullScreen
                />
            </Card>
            {showSaleStatusBanner ? (
                <Card
                    className='rds-flexbox align-center rds-m_top__sm'
                    variant={ThemeVariants.DARK}
                >
                    {sale.status !== SALE_STATUSES.DELIVERED ? (
                        <StyledRealTimeIndicator
                            themeContext={themeContext}
                            className='purchase-details_real-time-indicator rds-m_right__md'
                        ></StyledRealTimeIndicator>
                    ) : (
                        <Icon
                            icon={IconTypes.SUCCESS}
                            variant={ThemeVariants.MAIN}
                            className='rds-m_right__md'
                        />
                    )}
                    <div>
                        <Typography variant={ThemeVariants.WHITE}>
                            Estado
                        </Typography>
                        <Typography
                            type={TypographyTypes.H2}
                            variant={ThemeVariants.WHITE}
                        >
                            {sale.statusLabel?.es}
                        </Typography>
                    </div>
                </Card>
            ) : null}
            <Card
                headerTitle='Información de la compra'
                headerSize={HeaderSizes.SMALL}
                className='rds-m_top__sm'
            >
                <div className='rds-grid rds-grid_2-columns'>
                    <PaymentDetailsItem label='Código' value={sale.name} />
                    <PaymentDetailsItem
                        label='Tipo de entrega'
                        value={getDeliverTypeLabel(sale)}
                    />
                    <PaymentDetailsItem
                        label='Tipo de pago'
                        value={getPaymentTypeLabel(sale)}
                    />
                    {sale.paymentType === 'ONSITE' ? (
                        <PaymentDetailsItem
                            label='Método de pago'
                            value={sale.onSitePayment.method}
                        />
                    ) : null}
                    {sale.mpPaymentDetails?.card ? (
                        <PaymentDetailsItem
                            label='Método de pago'
                            value={`**** **** **** ${sale.mpPaymentDetails.card.last_four_digits}`}
                        />
                    ) : null}
                    {sale.onSitePayment?.method === 'MONEY_TRANSFER' ? (
                        <PaymentDetailsItem
                            label='Indicaciones para transferencia'
                            value={
                                location?.paymentMethods
                                    ?.moneyTransferDescription
                            }
                        />
                    ) : null}
                    {sale.mpPaymentDetails?.id ? (
                        <PaymentDetailsItem
                            label='ID de pago'
                            value={sale.mpPaymentDetails.id}
                        />
                    ) : null}
                    <PaymentDetailsItem
                        label='Creada'
                        value={`${moment(sale.createdAt).format(
                            'D/M/yyy',
                        )} a las ${moment(sale.createdAt).format('H:mm')}`}
                    />
                </div>
            </Card>
            <Card
                headerTitle='Información de contacto'
                headerSize={HeaderSizes.SMALL}
                className='rds-m_top__sm'
            >
                <div className='rds-grid rds-grid_2-columns'>
                    {sale?.contact?.email ? (
                        <PaymentDetailsItem
                            label='Correo electrónico'
                            value={sale.contact.email}
                        />
                    ) : null}
                    {sale?.contact?.phone ? (
                        <PaymentDetailsItem
                            label='Teléfono'
                            value={sale.contact.phone}
                        />
                    ) : null}
                </div>
            </Card>
            <div className='rds-m_top__sm rds-flexbox align-center'>
                <Link to='/' className='rds-m_right__sm'>
                    <Button label='Volver a la tienda' />
                </Link>
                <Typography type={TypographyTypes.P}>
                    Necesitás ayuda? Ponte en{' '}
                    <Link to={`/contact?sale=${sale.id}`}>
                        <b>contacto con nosotros</b>
                    </Link>
                </Typography>
            </div>
        </div>
    )
}

export default PurchaseDetails
