import { FormItemTypes } from 'rds'
import {
    HEADER_VARIANTS,
    POSITIONING_ENUM,
    HEADER_SIZES,
    CLOUDINARY_EFFECTS,
} from '../../constants'

const props = [
    {
        label: {
            en: 'Title',
            es: 'Título',
        },
        name: 'title',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text',
            es: 'Texto',
        },
        name: 'text',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text variant',
            es: 'Variante del texto',
        },
        name: 'headerVariant',
        type: FormItemTypes.SELECT,
        enum: HEADER_VARIANTS,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text size',
            es: 'Tamaño del texto',
        },
        name: 'headerSize',
        type: FormItemTypes.SELECT,
        enum: HEADER_SIZES,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text alignment',
            es: 'Alineacion del texto',
        },
        name: 'alignment',
        type: FormItemTypes.SELECT,
        enum: ['left', 'center', 'right'],
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Footer text',
            es: 'Texto de pie',
        },
        name: 'footerText',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Background file',
            es: 'Imagen de fondo',
        },
        name: 'backgroundImage',
        type: FormItemTypes.FILES_PICKER,
        category: {
            en: 'Background',
            es: 'Fondo',
        },
    },
    {
        label: {
            en: 'Effect',
            es: 'Efecto',
        },
        tooltip: {
            en: 'Apply a visual effect to the image, it can take a few seconds to load',
            es: 'Aplica un efecto visual a la imagen, puede tardar unos segundos en cargar',
        },
        name: 'effect',
        type: FormItemTypes.SELECT,
        enum: Object.keys(CLOUDINARY_EFFECTS),
        usesAI: true,
        category: {
            en: 'Background',
            es: 'Fondo',
        },
    },
    {
        label: {
            en: 'Background position',
            es: 'Posición de fondo',
        },
        name: 'backgroundImagePosition',
        type: FormItemTypes.SELECT,
        enum: POSITIONING_ENUM,
        category: {
            en: 'Background',
            es: 'Fondo',
        },
    },
    {
        label: {
            en: 'Overlay color',
            es: 'Color superpuesto',
        },
        name: 'overlayColor',
        type: 'color',
        category: {
            en: 'Background',
            es: 'Fondo',
        },
    },
    {
        label: {
            en: 'Overlay color opacity',
            es: 'Opacidad del color superpuesto',
        },
        name: 'overlayColorAlpha',
        type: 'range',
        category: {
            en: 'Background',
            es: 'Fondo',
        },
    },
    {
        label: {
            en: 'Navigate to URL',
            es: 'URL de Navegación',
        },
        name: 'navigateToURL',
        tooltip: {
            en: 'URL to navigate to when clicking the hero',
            es: 'URL a la que se navegará al hacer clic en el componente',
        },
        type: FormItemTypes.TEXT,
    },
    {
        label: {
            en: 'Padding',
            es: 'Relleno',
        },
        name: 'padding',
        type: FormItemTypes.NUMBER,
    },
    {
        label: {
            en: 'Margin',
            es: 'Márgen',
        },
        name: 'margin',
        type: FormItemTypes.TEXT,
    },
    {
        label: {
            en: 'Rounded borders',
            es: 'Bordes redondeados',
        },
        name: 'roundedBorder',
        type: FormItemTypes.CHECKBOX,
    },
    {
        label: {
            en: 'Bordered',
            es: 'Bordeado',
        },
        name: 'bordered',
        type: FormItemTypes.CHECKBOX,
    },
]
export default props
