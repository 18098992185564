import React from 'react'
import { useSelector } from 'react-redux'
import { Card, Typography, HeaderSizes } from 'rds'
import { REANGO_TOKEN_LOCALSTORAGE_NAME } from '../../../constants'
import FavListItem from '../../Product/FavListItem'

const UserFavorites = ({ title = 'Mis favoritos' }) => {
    const sessionUserId = useSelector(state => state.session.user?._id)
    const sessionUserFavorites = useSelector(
        state => state.session.user.favorites,
    )

    React.useEffect(() => {
        if (!localStorage.getItem(REANGO_TOKEN_LOCALSTORAGE_NAME)) {
            window.location.assign('/login')
        }
    }, [])

    if (!sessionUserId) {
        return null
    }
    return (
        <Card
            className='user-favorites'
            headerTitle={title}
            headerSize={HeaderSizes.MEDIUM}
        >
            {sessionUserFavorites.length ? (
                sessionUserFavorites.map((favorite, index) => (
                    <FavListItem
                        key={favorite}
                        productId={favorite}
                        showLine={index < sessionUserFavorites.length - 1}
                    />
                ))
            ) : (
                <Typography>No tienes favoritos</Typography>
            )}
        </Card>
    )
}

export default UserFavorites
