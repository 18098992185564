import { FormItemTypes } from 'rds'
import { HEADER_VARIANTS } from '../../constants'

const props = [
    {
        label: {
            en: 'Text variant',
            es: 'Variante del texto',
        },
        name: 'headerVariant',
        type: FormItemTypes.SELECT,
        enum: HEADER_VARIANTS,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Centered text',
            es: 'Texto centrado',
        },
        name: 'headerAlignCenter',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Show product count',
            es: 'Mostrar cantidad del productos',
        },
        name: 'showProductCount',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Use category picture',
            es: 'Usar imagen de Categoría',
        },
        name: 'useCategoryPicture',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Background',
            es: 'Fondo',
        },
    },
    {
        label: {
            en: 'Overlay color',
            es: 'Color superpuesto',
        },
        name: 'overlayColor',
        type: FormItemTypes.COLOR,
        category: {
            en: 'Background',
            es: 'Fondo',
        },
    },
    {
        label: {
            en: 'Overlay color opacity',
            es: 'Opacidad de color superpuesto',
        },
        name: 'overlayColorAlpha',
        type: FormItemTypes.RANGE,
        category: {
            en: 'Background',
            es: 'Fondo',
        },
    },
]
export default props
