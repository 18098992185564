import {
    TableItem,
    Tag,
    ThemeContext,
    Typography,
    TypographyTypes,
    cssRules,
    ThemeVariants,
    Card,
} from 'rds'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import api from '../../api'
import { DEBUG_MODE } from '../../utils'

const PurchaseProductDetails = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()

    const themeContext = React.useContext(ThemeContext)

    const webstoreSettingsProducts = useSelector(
        state => state.session.subscription.webstore.settings.products,
        shallowEqual,
    )

    const [paymentDetails, setPaymentDetails] = React.useState({
        transaction_details: {},
    })
    const [paymentDetailsFetched, setPaymentDetailsFetched] = React.useState()
    const [saleDetails, setSaleDetails] = React.useState()

    const getSaleDetails = React.useCallback(async () => {
        // Get payment details from query params
        const purchase_id = searchParams.get('purchase_id')
        if (!purchase_id) {
            return
        }

        // Fetch sale record
        const res = await api.get(`sales/${purchase_id}`)
        const { sale } = res.data
        setSaleDetails(sale)

        // Get payment details, either using MP payment ID or simulating
        // a payload
        if (sale.mpPaymentDetails && sale.mpPaymentDetails.id) {
            const mercadoPagoRes = await api.get(
                `checkout/${sale.mpPaymentDetails.id}`,
            )
            const { paymentDetails } = mercadoPagoRes.data
            if (DEBUG_MODE) {
                console.log('[PAYMENT] MP payment details:', paymentDetails)
            }
            setPaymentDetails(paymentDetails)
        } else {
            // Simulate MP payment details
            setPaymentDetails({
                transaction_amount:
                    sale.totalPriceSnapshot - (sale.shipmentCost || 0),
                transaction_details: {
                    installment_amount: sale.totalPriceSnapshot,
                },
                shipping_amount: sale.shipmentCost || 0,
            })
        }
        setPaymentDetailsFetched(true)
    }, [searchParams])

    React.useEffect(() => {
        if (saleDetails) {
            return
        }
        getSaleDetails()
    }, [saleDetails, getSaleDetails])

    if (!saleDetails || !saleDetails.products || !paymentDetailsFetched) {
        return null
    }
    return (
        <Card>
            <div>
                {saleDetails.products.map(product => (
                    <TableItem key={product.variant._id}>
                        <div className='rds-flexbox align-center justify-between'>
                            <div className='rds-flexbox align-center'>
                                {product.variant.pictures?.length ? (
                                    <img
                                        style={{
                                            borderRadius:
                                                cssRules.borderRadiusValue({
                                                    themeContext,
                                                }),
                                            objectFit:
                                                webstoreSettingsProducts.picturesType ===
                                                'UNCROPPED'
                                                    ? 'contain'
                                                    : 'cover',
                                        }}
                                        src={
                                            product.variant.pictures[0]
                                                .secure_url
                                        }
                                        alt={product.variant.sku}
                                        width={40}
                                        height={40}
                                        className='rds-m_right__sm'
                                    />
                                ) : null}
                                <div>
                                    <Typography type={TypographyTypes.H3}>
                                        {product.master.name}
                                    </Typography>
                                    {product.amount > 1 ? (
                                        <Tag
                                            variant={ThemeVariants.DARK}
                                        >{`x ${product.amount}`}</Tag>
                                    ) : null}
                                </div>
                            </div>
                            <div className='rds-flexbox align-center'>
                                <Typography type={TypographyTypes.P}>
                                    ${product.priceSnapshot * product.amount}
                                </Typography>
                            </div>
                        </div>
                    </TableItem>
                ))}
            </div>
            <div className='rds-m_top__md'>
                <div className='rds-flexbox align-center justify-between'>
                    <Typography type={TypographyTypes.H4}>Sub-total</Typography>
                    <Typography type={TypographyTypes.H4}>
                        $ {paymentDetails.transaction_amount}
                    </Typography>
                </div>
                {saleDetails.address ? (
                    <div className='rds-flexbox align-center justify-between rds-m_top__sm'>
                        <Typography type={TypographyTypes.H4}>Envío</Typography>
                        <Typography type={TypographyTypes.H4}>
                            {saleDetails.shipmentCost
                                ? `$ ${saleDetails.shipmentCost}`
                                : 'Gratis'}
                        </Typography>
                    </div>
                ) : null}
                <div className='rds-flexbox align-center justify-between rds-m_top__sm'>
                    <Typography type={TypographyTypes.H2}>Total</Typography>
                    <Typography type={TypographyTypes.H2}>
                        ${' '}
                        {paymentDetails.transaction_details.installment_amount}
                    </Typography>
                </div>
            </div>
        </Card>
    )
}

export default PurchaseProductDetails
