/**
 * Builds a user-friendly delivery type label
 * @param {Object} sale Sale document
 * @returns User-friendly delivery type label
 */
export const getDeliverTypeLabel = sale => {
    let type = ''
    switch (sale.type) {
        case 'ONLINE_PAYMENT__DELIVERY':
            type = `Delivery a ${sale.address}`
            break

        case 'ONLINE_PAYMENT__PICKUP':
            type = 'Retira en el local'
            break

        case 'ONSITE_PAYMENT__DELIVERY':
            type = `Delivery a ${sale.address}`
            break

        case 'ONSITE_PAYMENT__PICKUP':
            type = 'Retira en el local'
            break

        default:
            break
    }
    return type
}

/**
 * Builds a user-friendly payment type label
 * @param {Object} sale Sale document
 * @returns User-friendly payment type label
 */
export const getPaymentTypeLabel = sale => {
    let paymentType
    if (sale.paymentType === 'ONSITE') {
        paymentType = 'En la entrega'
    } else {
        paymentType = 'Online'
    }
    return paymentType
}
