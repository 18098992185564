import React from 'react'
import styledComponents from 'styled-components'
import { NavLink } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Card,
    cssRules,
    Loading,
    TableItem,
    Typography,
    TypographyTypes,
} from 'rds'
import api from '../../api'
import { toggleSidebarVisibility } from '../../reducers/uiReducer'
import GlobalSearch from './GlobalSearch'
import SearchResults from './SearchResults'
import Session from './Session'

const StyledMobileSidebar = styledComponents.div(props => ({
    visibility: props.isVisible ? 'initial' : 'hidden',
    '& .mobile-sidebar_inner': {
        maxWidth: `calc(${
            props.webstoreSettings.theme.containerWidth
        }px - ${cssRules.getCSSVariable('spacingLarge')})`,
        opacity: props.isVisible ? 1 : 0,
        transform: `translateX(-50%) translateY(${
            props.isVisible ? '-50%' : '-80%'
        })`,
    },
}))

const MobileSidebar = () => {
    const dispatch = useDispatch()
    const webstoreSettings = useSelector(
        state => state.session.subscription.webstore.settings,
        shallowEqual,
    )
    const navigationItems = useSelector(
        state =>
            state.session.subscription.webstore.settings.header.navigation
                .items,
        shallowEqual,
    )
    const uiSidebar = useSelector(state => state.ui.sidebar, shallowEqual)
    const [searching, setSearching] = React.useState()
    const [searchTerm, setSearchTerm] = React.useState()
    const [searchResults, setSearchResults] = React.useState()
    const timeout = React.useRef()

    const handleEsc = React.useCallback(
        event => {
            if (event.key === 'Escape' && uiSidebar.visible) {
                dispatch(toggleSidebarVisibility())
            }
        },
        [dispatch, uiSidebar.visible],
    )

    React.useEffect(() => {
        window.addEventListener('keydown', handleEsc)
        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [handleEsc])

    const handleItemTap = () => {
        dispatch(toggleSidebarVisibility())
    }

    const handleSearch = searchTerm => {
        setSearchTerm(searchTerm)
    }

    const handleSearchChange = React.useCallback(async searchTerm => {
        try {
            if (timeout.current) {
                clearTimeout(timeout.current)
            }
            if (!searchTerm || !searchTerm.length) {
                setSearchResults(null)
                return
            }
            timeout.current = setTimeout(async () => {
                setSearching(true)
                let results = []
                if (searchTerm) {
                    const res = await api.get(
                        `products?state=true&name=~${searchTerm}`,
                    )
                    results = res.data.products
                }
                setSearchResults(results)
                setSearching(false)
            }, 300)
        } catch (error) {
            console.error('[TOPBAR_SEARCH]', error.response)
        }
    }, [])

    React.useEffect(() => {
        handleSearchChange(searchTerm)
    }, [searchTerm, handleSearchChange])

    React.useEffect(() => {
        document.body.style.overflowY = uiSidebar.visible ? 'hidden' : ''
    }, [uiSidebar.visible])

    const getNavigationLink = navigationItem => {
        return navigationItem.category
            ? `/categories/${navigationItem.category._id}`
            : navigationItem.page?.path
    }

    const getNavigationClassName = data =>
        `rds-full-block rds-flexbox rds-p_top__sm rds-p_bottom__sm ${
            data.isActive ? 'active' : ''
        }`

    const handleClose = () => {
        dispatch(toggleSidebarVisibility())
    }

    return (
        <StyledMobileSidebar
            className='mobile-sidebar rds-fixed'
            webstoreSettings={webstoreSettings}
            isVisible={uiSidebar.visible}
        >
            <Card className='mobile-sidebar_inner rds-full-block rds-p_around'>
                <div className='rds-flexbox justify-between align-center'>
                    <Typography type={TypographyTypes.H3}>Buscar</Typography>
                    <Button label='Cerrar' onClick={handleClose} />
                </div>
                <div className='rds-m_top__sm'>
                    <GlobalSearch onChange={handleSearch} />
                </div>
                {searching ? (
                    <div className='rds-m_top__md'>
                        <Loading tag='Buscando...' />
                    </div>
                ) : null}
                <div
                    className='rds-m_top__sm rds-overflow_auto'
                    style={{ maxHeight: '70vh' }}
                >
                    {searchResults ? (
                        <SearchResults
                            searchResults={searchResults}
                            onSelect={handleItemTap}
                        />
                    ) : (
                        navigationItems.map((navigationItem, index) => (
                            <NavLink
                                key={index}
                                to={getNavigationLink(navigationItem)}
                                className={getNavigationClassName}
                                onClick={handleItemTap}
                                exact='true'
                            >
                                <Typography
                                    type={TypographyTypes.H4}
                                    className='rds-text-variant_link'
                                >
                                    {navigationItem.label}
                                </Typography>
                            </NavLink>
                        ))
                    )}
                </div>
                <TableItem className='rds-full-block' />
                <div className='rds-full-block'>
                    <Session onClick={handleItemTap} fullWidth />
                </div>
            </Card>
        </StyledMobileSidebar>
    )
}

export default MobileSidebar
