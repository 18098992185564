import React from 'react'
import {
    Button,
    ButtonVariants,
    FormItem,
    FormItemSizes,
    FormItemTypes,
    Header,
    HeaderSizes,
} from 'rds'
import Modal from '../../layout/Modal'
import { CITIES, COUNTRIES } from '../../constants'

const DeliverFormAddressSelectorAdd = ({ onSubmit, onClose, address }) => {
    const [form, setForm] = React.useState({
        ...address,
        _id: address._id,
        city: CITIES[0].id,
        country: COUNTRIES[0].id,
    })
    return (
        <Modal onClose={onClose} visible>
            <div className='rds-flexbox align-center justify-between'>
                <Header
                    title={
                        address._id ? 'Actualizar dirección' : 'Nueva dirección'
                    }
                    text='Completa los datos de la dirección'
                    size={HeaderSizes.SMALL}
                />
                <Button
                    label='Cerrar'
                    variant={ButtonVariants.TRANSPARENT}
                    onClick={onClose}
                />
            </div>
            <form>
                <div className='rds-full-block rds-grid'>
                    <FormItem
                        className='rds-m_top__md'
                        id='street'
                        label='Calle'
                        onChange={event =>
                            setForm(form => ({
                                ...form,
                                [event.target.id]: event.target.value,
                            }))
                        }
                        value={form.street}
                        required
                    />
                    <FormItem
                        id='doorNumber'
                        label='Número de puerta'
                        onChange={event =>
                            setForm(form => ({
                                ...form,
                                [event.target.id]: event.target.value,
                            }))
                        }
                        value={form.doorNumber}
                        size={FormItemSizes.SMALL}
                        required
                    />
                    <FormItem
                        id='apartment'
                        label='Apartamento (opcional)'
                        onChange={event =>
                            setForm(form => ({
                                ...form,
                                [event.target.id]: event.target.value,
                            }))
                        }
                        value={form.apartment}
                        size={FormItemSizes.SMALL}
                    />
                    <FormItem
                        id='zipCode'
                        label='Código postal'
                        onChange={event =>
                            setForm(form => ({
                                ...form,
                                [event.target.id]: event.target.value,
                            }))
                        }
                        value={form.zipCode}
                        size={FormItemSizes.SMALL}
                        required
                    />
                    <FormItem
                        id='city'
                        label='Ciudad'
                        type={FormItemTypes.SELECT}
                        values={CITIES}
                        onChange={event =>
                            setForm(form => ({
                                ...form,
                                [event.target.id]: event.target.value,
                            }))
                        }
                        value={form.city}
                        size={FormItemSizes.SMALL}
                        disabled
                        forceDropdown
                        required
                    />
                    <FormItem
                        id='country'
                        label='País'
                        type={FormItemTypes.SELECT}
                        values={COUNTRIES}
                        onChange={event =>
                            setForm(form => ({
                                ...form,
                                [event.target.id]: event.target.value,
                            }))
                        }
                        value={form.country}
                        disabled
                        forceDropdown
                        required
                    />
                    <FormItem
                        id='notes'
                        label='Notas'
                        size={FormItemSizes.BIG}
                        type={FormItemTypes.TEXTAREA}
                        onChange={event =>
                            setForm(form => ({
                                ...form,
                                [event.target.id]: event.target.value,
                            }))
                        }
                        value={form.notes}
                    />
                </div>
                <div className='rds-full-block rds-m_top__md rds-flexbox align-center justify-center'>
                    <Button
                        label='Guardar'
                        onClick={() => onSubmit(form)}
                        variant={ButtonVariants.MAIN}
                    />
                </div>
            </form>
        </Modal>
    )
}

export default DeliverFormAddressSelectorAdd
