import { createSlice } from '@reduxjs/toolkit'
import { REANGO_CART_LOCALSTORAGE_NAME } from '../constants'
import { DEBUG_MODE } from '../utils'
import { updateUserCart } from './sessionReducer'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        isFetching: false,
        entries: [],
        isUserCart: false,
        error: null,
        sidebar: {
            visible: false,
        },
    },
    reducers: {
        setStart: state => {
            state.isFetching = true
            state.error = null
        },
        setEnd: state => {
            state.isFetching = false
            state.error = null
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        addItem: (state, action) => {
            state.entries.push(action.payload)
        },
        removeItem: (state, action) => {
            state.entries.splice(action.payload, 1)
        },
        updateItemQuantity: (state, action) => {
            state.entries[action.payload.entryIndex].quantity =
                action.payload.newQuantity
        },
        setCart: (state, action) => {
            state.entries = action.payload.entries
            state.isUserCart = action.payload.isUserCart
        },
        clearCart: state => {
            state.entries = []
        },
        toggleSidebarVisibility: state => {
            state.sidebar.visible = !state.sidebar.visible
        },
    },
})
const {
    addItem,
    removeItem,
    updateItemQuantity,
    clearCart,
    setCart,
    setError,
    setStart,
    setEnd,
    toggleSidebarVisibility,
} = cartSlice.actions

export const cartAddItem =
    (user, item, variant, additionals = {}, notes, quantity = 1) =>
    (dispatch, getState) => {
        try {
            dispatch(setStart())
            dispatch(
                addItem({ user, item, variant, additionals, notes, quantity }),
            )
            // Send updated cart to reducer that
            // updates data in database/LS
            const currentCart = getState().cart
            dispatch(
                cartSetCart({
                    entries: currentCart.entries,
                    isUserCart: currentCart.isUserCart,
                }),
            )
            dispatch(setEnd())
        } catch (error) {
            setError(error)
        }
    }

export const cartRemoveItem = position => (dispatch, getState) => {
    try {
        dispatch(setStart())
        dispatch(removeItem(position))
        // Send updated cart to reducer that
        // updates data in database/LS
        const currentCart = getState().cart
        dispatch(
            cartSetCart({
                entries: currentCart.entries,
                isUserCart: currentCart.isUserCart,
            }),
        )
        dispatch(setEnd())
    } catch (error) {
        setError(error)
    }
}

export const cartUpdateItemQuantity =
    (entryIndex, newQuantity) => (dispatch, getState) => {
        try {
            dispatch(setStart())
            dispatch(updateItemQuantity({ entryIndex, newQuantity }))
            // Send updated cart to reducer that
            // updates data in database/LS
            const currentCart = getState().cart
            dispatch(
                cartSetCart({
                    entries: currentCart.entries,
                    isUserCart: currentCart.isUserCart,
                }),
            )
            dispatch(setEnd())
        } catch (error) {
            setError(error)
        }
    }

export const cartSetCart = newCart => async dispatch => {
    try {
        dispatch(setStart())
        const parsedEntries = Array.isArray(newCart.entries)
            ? newCart.entries
            : JSON.parse(newCart.entries)
        const processedCartEntries = parsedEntries.map(cartEntry => ({
            item: cartEntry.item._id || cartEntry.item,
            variant: cartEntry.variant._id || cartEntry.variant,
            quantity: cartEntry.quantity > 0 ? cartEntry.quantity : 1,
            additionals: cartEntry.additionals,
            notes: cartEntry.notes,
        }))
        if (newCart.isUserCart) {
            dispatch(updateUserCart(processedCartEntries))
        } else {
            if (DEBUG_MODE) {
                console.log(
                    '[CART] Updating LS cart with:',
                    processedCartEntries,
                )
            }
            localStorage.setItem(
                REANGO_CART_LOCALSTORAGE_NAME,
                JSON.stringify(processedCartEntries),
            )
        }
        dispatch(setCart({ ...newCart, entries: processedCartEntries }))
        dispatch(setEnd())
    } catch (error) {
        console.error('[cartReducer]', error)
        setError(error)
    }
}

export const cartClearCart = () => (dispatch, getState) => {
    try {
        dispatch(setStart())
        dispatch(clearCart())
        // Send updated cart to reducer that
        // updates data in database/LS
        const currentCart = getState().cart
        dispatch(
            cartSetCart({
                entries: currentCart.entries,
                isUserCart: currentCart.isUserCart,
            }),
        )
        dispatch(setEnd())
    } catch (error) {
        setError(error)
    }
}

export const cartToggleSidebarVisibility = () => dispatch => {
    try {
        dispatch(setStart())
        dispatch(toggleSidebarVisibility())
        dispatch(setEnd())
    } catch (error) {
        setError(error)
    }
}

export default cartSlice.reducer
