import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import api from '../../api'
import {
    Label,
    Typography,
    TypographyTypes,
    Card,
    HeaderSizes,
    Tag,
    EmptyState,
    cssRules,
    ThemeContext,
    ThemeVariants,
} from 'rds'
import { Link } from 'react-router-dom'
import { REANGO_TOKEN_LOCALSTORAGE_NAME } from '../../constants'

const UserPurchasesHistory = ({ title = 'Historial de compras', text }) => {
    const themeContext = React.useContext(ThemeContext)
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const [salesHistory, setSalesHistory] = React.useState([])
    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    })

    React.useEffect(() => {
        if (!localStorage.getItem(REANGO_TOKEN_LOCALSTORAGE_NAME)) {
            window.location.assign('/login')
        }
    }, [])

    const getContactSales = React.useCallback(async () => {
        if (!sessionUser || !sessionUser.contact) return
        const res = await api.get(
            'sales?contact=' + sessionUser.contact._id || sessionUser.contact,
        )
        const { sales } = res.data
        if (mounted.current && Array.isArray(sales)) {
            setSalesHistory(sales.reverse())
        }
    }, [sessionUser])

    React.useEffect(() => {
        getContactSales()
    }, [getContactSales])

    const getSaleStatusTag = sale => {
        let label
        switch (sale.status) {
            case 'DELIVERED':
                label = 'Entregada'
                break

            case 'CONFIRMED':
                label = 'En preparación'
                break

            case 'IN_TRANSIT':
                label = 'En tránsito'
                break

            default:
                label = 'Esperando confirmación'
                break
        }
        return (
            <Tag
                variant={
                    sale.status === 'DELIVERED' ? ThemeVariants.PURPLE : null
                }
            >
                {label}
            </Tag>
        )
    }
    return (
        <Card
            headerTitle={title}
            headerSize={HeaderSizes.MEDIUM}
            className='user-purchase-history'
        >
            <div>
                {!salesHistory.length ? (
                    <EmptyState title='No has comprado nada aún' />
                ) : null}
                {salesHistory.map(s => (
                    <Card className='rds-m_top__sm' key={s._id}>
                        <div className='rds-flexbox align-top'>
                            <div
                                className='rds-flexbox direction-column'
                                style={{
                                    maxHeight: '200px',
                                    overflowY: 'hidden',
                                    overflowX: 'hidden',
                                }}
                            >
                                {s.products.map((p, i) => {
                                    const { variant } = p
                                    const hasPics = variant.pictures?.length
                                    return hasPics ? (
                                        <img
                                            className='rds-m_bottom__sm rds-m_right__md'
                                            key={i}
                                            src={variant.pictures[0].secure_url}
                                            alt={variant.sku}
                                            width={80}
                                            height={80}
                                            style={{
                                                borderRadius:
                                                    cssRules.borderRadiusValue({
                                                        themeContext,
                                                    }),
                                            }}
                                        />
                                    ) : null
                                })}
                            </div>
                            <div className='rds-full-block rds-flexbox align-top justify-between rds-p_left__sm'>
                                <div className='rds-full-block'>
                                    <div className='rds-flexbox align-top justify-between'>
                                        <div>
                                            <Link
                                                to={`/payment-success?purchase_id=${s._id}`}
                                            >
                                                <Typography
                                                    type={TypographyTypes.H4}
                                                    variant={ThemeVariants.MAIN}
                                                >
                                                    #{s.name}
                                                </Typography>
                                            </Link>
                                            <div>
                                                {s.products.map((p, i) => (
                                                    <React.Fragment key={i}>
                                                        <Link
                                                            to={`/products/${p.master._id}`}
                                                        >
                                                            <Typography
                                                                type={
                                                                    TypographyTypes.H3
                                                                }
                                                            >
                                                                {p.master.name}
                                                            </Typography>
                                                        </Link>
                                                        {i <
                                                        s.products.length -
                                                            1 ? (
                                                            <span>, </span>
                                                        ) : null}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div className='rds-m_top__sm'>
                                                {getSaleStatusTag(s)}
                                            </div>
                                        </div>
                                        <div align='right'>
                                            <Typography
                                                type={TypographyTypes.H2}
                                            >
                                                ${s.totalPriceSnapshot}
                                            </Typography>
                                            {s.paidDate ? (
                                                <Typography
                                                    type={TypographyTypes.P}
                                                >
                                                    pagaste el{' '}
                                                    {new Date(
                                                        s.paidDate,
                                                    ).toLocaleDateString()}
                                                </Typography>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='rds-full-block rds-grid rds-grid_3-columns rds-m_top__md'>
                                        <div>
                                            <Label>Fecha</Label>
                                            <Typography
                                                type={TypographyTypes.P}
                                            >
                                                {new Date(
                                                    s.createdAt,
                                                ).toLocaleDateString()}
                                            </Typography>
                                        </div>
                                        {s.mpPaymentDetails &&
                                        s.mpPaymentDetails.card ? (
                                            <div>
                                                <Label>Metodo de pago</Label>
                                                <Typography
                                                    type={TypographyTypes.P}
                                                >
                                                    **** **** ****{' '}
                                                    {
                                                        s.mpPaymentDetails.card
                                                            .last_four_digits
                                                    }
                                                </Typography>
                                            </div>
                                        ) : null}
                                        <div>
                                            <Label>Entrega</Label>
                                            <Typography
                                                type={TypographyTypes.P}
                                            >
                                                {s.address || 'Pick-up'}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
        </Card>
    )
}

export default UserPurchasesHistory
