import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import layoutRenderer from '../layoutRenderer'
import { REANGO_WEBSTORE_BUILDER_MESSAGES } from '../constants'
import {
    Button,
    ButtonVariants,
    ThemesNames,
    Typography,
    TypographyTypes,
} from 'rds'
import styledComponents from 'styled-components'
import api from '../api'
import Modal from './Modal'

const StyledMainWrapper = styledComponents.div`${props => `
    overflow-x: hidden;
    min-height: 100vh;
    transition: 0.2s all;
    margin-top:  calc(${
        props.webstoreSettings.header.logo.height ||
        props.webstoreSettings.header.logo.width
    }px + ${(props.webstoreSettings.header.padding || 0) * 2}px + ${
    props.webstoreSettings.theme.borderWidth || 0
}px);
    background-color: ${
        props.webstoreSettings.body?.background?.color
            ? props.webstoreSettings.body.background.color
            : props.webstoreSettings.theme.scheme === ThemesNames.DARK
            ? 'var(--rds-colorBlackDark)'
            : 'white'
    };
    ${
        props.webstoreSettings.body?.background?.picture
            ? `background-image: url(${props.webstoreSettings.body.background.picture});`
            : ''
    }
    ${
        props.webstoreSettings.body?.background?.position
            ? `background-position: ${props.webstoreSettings.body.background.position.toLowerCase()};`
            : ''
    }
    ${
        props.webstoreSettings.body?.background?.attachment
            ? `background-attachment: ${props.webstoreSettings.body.background.attachment.toLowerCase()};`
            : ''
    }
`}`

const AppContainer = () => {
    const location = useLocation()
    const pages = useSelector(
        state => state.session.subscription.webstore.pages,
        shallowEqual,
    )
    const webstoreSettings = useSelector(
        state => state.session.subscription.webstore.settings,
        shallowEqual,
    )
    const [modal, setModal] = React.useState({
        visible: false,
    })

    const sendCurrentPageToBuilder = React.useCallback(() => {
        window.parent.postMessage(
            {
                type: REANGO_WEBSTORE_BUILDER_MESSAGES.NAVIGATION,
                payload: location,
            },
            '*',
        )
    }, [location])

    React.useEffect(() => {
        api.get('/locations').then(res => {
            const { locations } = res.data
            const hasAnyOpenLocations = !!locations.find(
                location => location.isOpenNow,
            )
            if (
                !hasAnyOpenLocations &&
                (webstoreSettings.communications?.noOpenLocationModal.title ||
                    webstoreSettings.communications?.text)
            ) {
                const content = (
                    <div>
                        <Typography type={TypographyTypes.H2}>
                            {
                                webstoreSettings.communications
                                    ?.noOpenLocationModal.title
                            }
                        </Typography>
                        <Typography>
                            {
                                webstoreSettings.communications
                                    ?.noOpenLocationModal.text
                            }
                        </Typography>
                        <Button
                            className='rds-m_top__md rds-full-block'
                            label={
                                webstoreSettings.communications
                                    ?.noOpenLocationModal.acceptLabel ||
                                'Aceptar'
                            }
                            variant={ButtonVariants.MAIN}
                            onClick={() =>
                                setModal(modal => ({
                                    ...modal,
                                    visible: false,
                                }))
                            }
                        />
                    </div>
                )
                setModal(modal => ({
                    ...modal,
                    visible: true,
                    content,
                }))
            }
        })
    }, [webstoreSettings.communications])

    React.useEffect(() => {
        const handleBuilderMessages = event => {
            switch (event.data.type) {
                case REANGO_WEBSTORE_BUILDER_MESSAGES.CURRENT_PAGE_REQUEST:
                    sendCurrentPageToBuilder()
                    break

                default:
                    break
            }
        }
        window.addEventListener('message', handleBuilderMessages)
        return () => {
            window.removeEventListener('message', handleBuilderMessages)
        }
    }, [sendCurrentPageToBuilder])

    // Navigation watcher to notify Reango's builder
    React.useEffect(() => {
        sendCurrentPageToBuilder()
    }, [sendCurrentPageToBuilder])

    return (
        <StyledMainWrapper
            webstoreSettings={webstoreSettings}
            id='main-wrapper'
            className='rds-p_bottom'
        >
            <Modal
                className='communications-modal'
                visible={modal.visible}
                onClose={() => setModal({ ...modal, visible: false })}
            >
                {modal.content}
            </Modal>
            <Routes>
                {pages?.map(page => {
                    const element = layoutRenderer(page)
                    return (
                        <Route
                            key={page._id}
                            path={page.path}
                            element={element}
                            exact='true'
                        />
                    )
                })}
            </Routes>
        </StyledMainWrapper>
    )
}

export default AppContainer
