import { Block, Icon, IconTypes, Input, ThemeVariants } from 'rds'
import React from 'react'

const GlobalSearch = ({ onChange }) => {
    const [searchTerm, setSearchTerm] = React.useState()

    const handleSearchChange = e => setSearchTerm(e.target.value)

    React.useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(searchTerm)
        }
    }, [onChange, searchTerm])

    return (
        <Block
            variant={ThemeVariants.GRAY}
            className='global-search rds-flexbox align-center rds-p_left__md'
            roundedBorder
            bordered
        >
            <Icon
                icon={IconTypes.SEARCH}
                width={15}
                style={{ opacity: '0.5' }}
            />
            <Input
                className='global-search_input'
                style={{ background: 'transparent' }}
                placeholder='Buscar productos...'
                onChange={handleSearchChange}
            />
        </Block>
    )
}

export default GlobalSearch
