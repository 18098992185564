import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cartToggleSidebarVisibility } from '../../reducers/cartReducer'
import Cart from '.'

const CartSidebar = () => {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart)

    const handleClose = React.useCallback(() => {
        dispatch(cartToggleSidebarVisibility())
    }, [dispatch])

    const handleEsc = React.useCallback(
        event => {
            if (event.key === 'Escape' && cart.sidebar.visible) {
                handleClose()
            }
        },
        [cart.sidebar.visible, handleClose],
    )

    React.useEffect(() => {
        window.addEventListener('keydown', handleEsc)
        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [handleEsc])

    React.useEffect(() => {
        if (cart.sidebar.visible) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = 'auto'
        }
    }, [cart.sidebar.visible])

    return (
        <React.Fragment>
            <div
                className={`cart-aside ${
                    cart.sidebar.visible ? 'cart-aside_visible' : ''
                }`}
            >
                <Cart />
            </div>
            <div
                className={`cart-aside_shadow ${
                    cart.sidebar.visible ? 'cart-aside_shadow_visible' : ''
                }`}
                onClick={handleClose}
            ></div>
        </React.Fragment>
    )
}

export default CartSidebar
