import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Show stock left',
            es: 'Mostrar cantidad de stock',
        },
        name: 'showStockLeft',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Visibility',
            es: 'Visibilidad',
        },
    },
    {
        label: {
            en: 'Show reviews average',
            es: 'Mostrar promedio de reviews',
        },
        name: 'showReviewsAverage',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Visibility',
            es: 'Visibilidad',
        },
    },
    {
        label: {
            en: 'Show reviews section',
            es: 'Mostrar sección reviews',
        },
        name: 'showReviewsSection',
        type: FormItemTypes.CHECKBOX,
        category: {
            en: 'Visibility',
            es: 'Visibilidad',
        },
    },
    {
        label: {
            en: 'Selection mode',
            es: 'Modo de selección',
        },
        name: 'selectionMode',
        tooltip: {
            en: 'Determines the way multiple values inputs are displayed',
            es: 'Determina la forma en que se muestran los inputs de múltiples valores',
        },
        type: FormItemTypes.SELECT,
        enum: [FormItemTypes.SELECT, FormItemTypes.CARD_SELECT],
        default: FormItemTypes.SELECT,
        category: {
            en: 'Layout',
            es: 'Diseño',
        },
    },
]
export default props
