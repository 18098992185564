const props = [
    {
        label: {
            en: 'Title',
            es: 'Título',
        },
        name: 'title',
    },
]

export default props
