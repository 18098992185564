import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { cssRules, Typography, TypographyTypes, ThemeContext } from 'rds'
import { getPictureThumbnail } from '../../utils/file'

const IMAGE_SIZE = 45

const StyledProductImage = styled.img(props => ({
    display: 'block',
    minWidth: IMAGE_SIZE,
    minHeight: IMAGE_SIZE,
    borderRadius: cssRules.borderRadiusValue(props),
    objectFit:
        props.webstoreSettingsProducts.picturesType === 'UNCROPPED'
            ? 'contain'
            : 'cover',
}))

const StyledProductHeader = styled.div({
    width: `calc(100% - ${IMAGE_SIZE}px - ${cssRules.getCSSVariable(
        'spacingSmall',
    )})`,
})

const CartSummaryItemHeader = ({ item }) => {
    const themeContext = React.useContext(ThemeContext)
    const webstoreSettingsProducts = useSelector(
        state => state.session.subscription.webstore.settings.products,
        shallowEqual,
    )
    return (
        <div className='cart-summary_item-header rds-full-block rds-flexbox align-center rds-m_bottom__md'>
            {item.variant?.pictures?.length ? (
                <StyledProductImage
                    className='cart-summary_item-thumbnail rds-m_right__sm'
                    themeContext={themeContext}
                    webstoreSettingsProducts={webstoreSettingsProducts}
                    src={getPictureThumbnail(
                        item.variant.pictures[0].secure_url,
                        IMAGE_SIZE,
                        IMAGE_SIZE,
                    )}
                    alt={item.item.name}
                    width={IMAGE_SIZE}
                    height={IMAGE_SIZE}
                />
            ) : null}
            <StyledProductHeader className='cart-summary_item-header_details'>
                <Link to={`/products/${item.item.id}`}>
                    <Typography
                        type={TypographyTypes.H4}
                        className='cart-summary_item-header_name rds-text-variant_link'
                    >
                        {item.item.name}
                    </Typography>
                </Link>
                <Typography
                    className='rds-overflow_hidden rds-nowrap'
                    style={{ textOverflow: 'ellipsis' }}
                >
                    {item.item.description}
                </Typography>
            </StyledProductHeader>
        </div>
    )
}

export default CartSummaryItemHeader
