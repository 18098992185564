import {
    Form,
    FormItem,
    FormItemSizes,
    FormItemTypes,
    FormSection,
    FormSectionSizes,
} from 'rds'
import React from 'react'
import { shallowEqual } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../../api'
import { DEBUG_MODE } from '../../utils'

const CASE_TYPES = Object.freeze({
    NOT_SPECIFIED: 'NOT_SPECIFIED',
    NOT_RECEIVED: 'NOT_RECEIVED',
    WRONG_ITEM: 'WRONG_ITEM',
    DEFECTIVE_OR_BROKEN: 'DEFECTIVE_OR_BROKEN',
})

const CASE_TYPE_TRANSLATIONS = Object.freeze({
    [CASE_TYPES.NOT_SPECIFIED]: 'No especificado',
    [CASE_TYPES.NOT_RECEIVED]: 'No recibido',
    [CASE_TYPES.WRONG_ITEM]: 'Producto incorrecto',
    [CASE_TYPES.DEFECTIVE_OR_BROKEN]: 'Defectuoso o roto',
})

const CASE_TYPES_OPTIONS = Object.keys(CASE_TYPES)
    .filter(caseType => caseType !== 'NOT_SPECIFIED')
    .map(caseType => ({
        id: caseType,
        value: caseType,
        label: CASE_TYPE_TRANSLATIONS[caseType],
    }))

const DEBUG_PREFIX = '[CONTACT_FORM]'

const ContactForm = ({
    submitLabel = 'Enviar',
    formTitle,
    formText,
    nameRequired,
    emailRequired,
    submitMessage = 'Muchas gracias! Te contactaremos a la brevedad',
}) => {
    const navigate = useNavigate()
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const isAuthenticated = sessionUser._id && sessionUser.contact
    const initialFormData = {
        fullName: sessionUser.name,
        email: sessionUser.email,
        topic: 'NOT_RECEIVED',
    }
    const [saleId, setSaleId] = React.useState()
    const [userRelatedSales, setUserRelatedSales] = React.useState()
    const [formData, setFormData] = React.useState(initialFormData)

    const fetchSales = React.useCallback(async () => {
        let salesToShow = []
        if (sessionUser.contact) {
            const res = await api.get(
                `sales?contact=${
                    sessionUser.contact._id || sessionUser.contact
                }`,
            )
            salesToShow = res.data.sales
        } else if (saleId) {
            const res = await api.get(`sales/${saleId}`)
            salesToShow.push(res.data.sale)
        }
        setUserRelatedSales(salesToShow)
    }, [sessionUser.contact, saleId])

    React.useEffect(() => {
        fetchSales()
    }, [fetchSales])

    React.useEffect(() => {
        const querySaleId = searchParams.get('sale')
        setSaleId(querySaleId)
        setFormData(formData => ({
            ...formData,
            sale: querySaleId,
        }))
    }, [searchParams])

    const handleSubmit = async () => {
        try {
            const res = await api.post('cases', formData)
            if (DEBUG_MODE) {
                console.log(DEBUG_PREFIX, 'Created case:', res.data.case)
            }
            alert(submitMessage)
            navigate(`/cases/${res.data.case._id}`)
        } catch (error) {
            console.error(DEBUG_PREFIX, error)
            alert(error.response?.data?.message)
        }
    }

    const handleInputChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        })
    }

    return (
        <div className='contact-form container_sm'>
            <Form
                className='rds-m_bottom__sm'
                submitCallback={handleSubmit}
                submitLabel={submitLabel}
            >
                <FormSection
                    size={FormSectionSizes.BIG}
                    title={formTitle}
                    description={formText}
                >
                    <FormItem
                        label='Nombre completo'
                        value={formData.fullName}
                        size={FormItemSizes.SMALL}
                        onChange={e =>
                            handleInputChange('fullName', e.target.value)
                        }
                        disabled={isAuthenticated}
                        required={isAuthenticated || nameRequired}
                    />
                    <FormItem
                        label='Email'
                        type={FormItemTypes.EMAIL}
                        value={formData.email}
                        size={FormItemSizes.SMALL}
                        onChange={e =>
                            handleInputChange('email', e.target.value)
                        }
                        disabled={isAuthenticated}
                        required={isAuthenticated || emailRequired}
                    />
                    {Array.isArray(userRelatedSales) ? (
                        <>
                            <FormItem
                                label='Compra relacionada'
                                type={FormItemTypes.SELECT}
                                values={userRelatedSales.map(sale => ({
                                    label: `#${sale.name}`,
                                    id: sale._id,
                                    value: sale._id,
                                }))}
                                value={formData.sale}
                                onChange={e =>
                                    handleInputChange('sale', e.target.value)
                                }
                                size={FormItemSizes.SMALL}
                                forceDropdown
                                required
                            />
                            <FormItem
                                label='Problema'
                                type={FormItemTypes.SELECT}
                                values={CASE_TYPES_OPTIONS}
                                value={formData.topic}
                                size={FormItemSizes.SMALL}
                                onChange={e =>
                                    handleInputChange('topic', e.target.value)
                                }
                                required
                            />
                            {formData.topic !== 'NOT_RECEIVED' ? (
                                <FormItem
                                    label='Fotos (opcional)'
                                    type={FormItemTypes.FILES_PICKER}
                                    size={FormItemSizes.SMALL}
                                    uploadLabel='Subir foto'
                                    onChange={files => {
                                        handleInputChange(
                                            'attachment',
                                            files[0],
                                        )
                                    }}
                                />
                            ) : null}
                        </>
                    ) : null}
                    <FormItem
                        label='Descripción'
                        type={FormItemTypes.TEXTAREA}
                        value={formData.description}
                        size={FormItemSizes.BIG}
                        onChange={e =>
                            handleInputChange('description', e.target.value)
                        }
                        required
                    />
                </FormSection>
            </Form>
        </div>
    )
}

export default ContactForm
