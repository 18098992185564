import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    Card,
    Header,
    HeaderSizes,
    Icon,
    IconTypes,
    Loading,
    ThemeVariants,
} from 'rds'
import api from '../../api'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { sessionSetUser } from '../../reducers/sessionReducer'
import { DEBUG_MODE } from '../../utils'
import { cartSetCart, cartClearCart } from '../../reducers/cartReducer'
import { MERCADOPAGO_PAYMENT_STATUS, SALE_STATUSES } from '../../constants'

const DEBUG_PREFIX = '[PAYMENT]'

const Payment = ({ loadingLabel = 'Espere unos segundos' }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const [isProcessing, setIsProcessing] = React.useState()
    const [error, setError] = React.useState()

    const fetchUser = React.useCallback(
        async userId => {
            // Fetch latest User's data
            const res = await api.get(`users/${userId}`)
            dispatch(sessionSetUser(res.data.user))
            dispatch(
                cartSetCart({ entries: res.data.user.cart, isUserCart: true }),
            )
        },
        [dispatch],
    )

    const handlePayment = React.useCallback(async () => {
        setError()
        setIsProcessing(true)

        // Get payment details from query params
        const collection_id = searchParams.get('collection_id')
        const purchase_id = searchParams.get('purchase_id')
        let saleId = purchase_id

        // If URL contains the purchase ID it means the Sale was already added
        // Fetch it and show its details
        if (purchase_id && sessionUser?._id) {
            // Fetch user in case this page is accesed as a
            // success callback (user cart is wiped out on the back-end)
            fetchUser(sessionUser._id)
        } else if (!purchase_id) {
            if (!collection_id) {
                setError('No se pudo obtener el ID del pago')
                return
            }

            // Get MP payment details to check if Sale records
            // needs to be updated
            const mercadoPagoRes = await api.get(`checkout/${collection_id}`)
            const mpPaymentDetails = mercadoPagoRes.data.paymentDetails
            if (DEBUG_MODE) {
                console.log(
                    DEBUG_PREFIX,
                    'MP payment details:',
                    mpPaymentDetails,
                )
            }
            if (
                mpPaymentDetails.status ===
                    MERCADOPAGO_PAYMENT_STATUS.REJECTED ||
                mpPaymentDetails.status === MERCADOPAGO_PAYMENT_STATUS.CANCELLED
            ) {
                setError(
                    'Hubo un problema con el pago, por favor contáctese con su proveedor',
                )
                if (DEBUG_MODE) {
                    console.log(
                        DEBUG_PREFIX,
                        'Payment not approved, status is',
                        mpPaymentDetails.status,
                    )
                }
                return
            }
            // If payment was approved, update Sale record in Reango
            // (This can be also done via MercadoPago webhook, but it needs to be configured by the client)
            saleId = mpPaymentDetails.metadata.reango_sale_id
            const getSaleRes = await api.get(`sales/${saleId}`)
            let { sale } = getSaleRes.data
            if (DEBUG_MODE) {
                console.log(DEBUG_PREFIX, 'Matching Reango Sale', sale)
            }
            if (sale.status === SALE_STATUSES.DRAFT) {
                // Update Reango Sale to "New" status
                // so notifications can be sent
                if (DEBUG_MODE) {
                    console.log(
                        DEBUG_PREFIX,
                        'Updating Reango Sale status to "New" so notifications can be sent',
                    )
                }
                const updateSaleRes = await api.put(`sales/${saleId}`, {
                    status: SALE_STATUSES.NEW,
                })
                sale = updateSaleRes.data.sale
            }
            if (!sale.mpPaymentDetails?.id) {
                // Update Reango Sale to "Payment Approved" status
                // and add MercadoPago payment details
                if (DEBUG_MODE) {
                    console.log(
                        DEBUG_PREFIX,
                        'Updating Reango Sale MercadoPago payment details',
                    )
                }
                await api.put(`sales/${saleId}`, {
                    status:
                        sale.status === SALE_STATUSES.NEW
                            ? SALE_STATUSES.PAYMENT_RECEIVED
                            : sale.status,
                    mpPaymentDetails,
                })
            }
        }
        if (!sessionUser?._id) {
            // Purchase was made using guest mode, clear cart entries manually
            dispatch(cartClearCart())
        }
        setIsProcessing(false)
        navigate(`/purchases/${saleId}`)
    }, [dispatch, fetchUser, sessionUser, searchParams, navigate])

    React.useEffect(() => {
        handlePayment()
    }, [handlePayment])

    if (error) {
        return (
            <Card variant={ThemeVariants.ERROR}>
                <Icon icon={IconTypes.WARNING} variant={ThemeVariants.ERROR} />
                <Header
                    title='Hubo un error procesando el pago'
                    text={error}
                    size={HeaderSizes.SMALL}
                    variant={ThemeVariants.ERROR}
                />
            </Card>
        )
    }
    if (isProcessing) {
        return (
            <div className='rds-m_top__sm'>
                <Loading tag={loadingLabel} />
            </div>
        )
    }
    return <div></div>
}

export default Payment
