import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    TableItem,
    Typography,
    TypographyTypes,
    Button,
    ButtonVariants,
    Block,
} from 'rds'
import api from '../../api'
import { sessionSetUser } from '../../reducers/sessionReducer'

const DEBUG_PREFIX = '[FAV_LIST_ITEM]'

const FavListItem = ({
    productId,
    showActions = true,
    showLine,
    thumbnailSize = 80,
}) => {
    const dispatch = useDispatch()
    const sessionUserId = useSelector(state => state.session.user._id)
    const [product, setProduct] = React.useState(null)

    const fetchProduct = async productId => {
        try {
            const res = await api.get(`products/${productId}`)
            setProduct(res.data.product)
        } catch (error) {
            console.error(DEBUG_PREFIX, error)
        }
    }

    React.useEffect(() => {
        let safeProductId = productId
        if (typeof safeProductId === 'object') {
            safeProductId = productId._id
        }
        fetchProduct(safeProductId)
    }, [productId])

    const handleUnfav = async () => {
        try {
            const res = await api.put(
                `users/${sessionUserId}/favorites/${product._id}`,
            )
            dispatch(sessionSetUser(res.data.user))
        } catch (error) {
            console.error(DEBUG_PREFIX, error)
        }
    }

    if (!product) {
        return null
    }
    return (
        <TableItem noLine={!showLine}>
            <div className='rds-flexbox align-center justify-between'>
                <div className='rds-flexbox align-center'>
                    {product.pictures?.length ? (
                        <Block
                            className='rds-overflow_hidden rds-m_right__md'
                            roundedBorder
                            bordered
                        >
                            <img
                                className='rds-flexbox'
                                src={product.pictures[0].secure_url}
                                alt={product.name}
                                height={thumbnailSize}
                                width={thumbnailSize}
                            />
                        </Block>
                    ) : null}
                    <div>
                        <Link to={`/products/${product._id}`}>
                            <Typography
                                className='rds-text-variant_link'
                                type={TypographyTypes.H3}
                            >
                                {product.name}
                            </Typography>
                        </Link>
                        <Typography className='rds-m_top__sm'>
                            ${product.priceTag}
                        </Typography>
                    </div>
                </div>
                {showActions ? (
                    <div className='rds-flexbox align-center'>
                        <Button
                            icon='DislikeTwo'
                            label='Quitar de favoritos'
                            variant={ButtonVariants.DANGER_SECONDARY}
                            className='rds-m_left__sm'
                            onClick={handleUnfav}
                        />
                    </div>
                ) : null}
            </div>
        </TableItem>
    )
}

export default FavListItem
