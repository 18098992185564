import { FormItemTypes } from 'rds'
import { POSITIONING_ENUM } from '../../constants'

const props = [
    {
        label: {
            en: 'Video',
            es: 'Video',
        },
        name: 'url',
        type: FormItemTypes.FILES_PICKER,
    },
    {
        label: {
            en: 'Video position',
            es: 'Posición del video',
        },
        name: 'objectPosition',
        type: FormItemTypes.SELECT,
        enum: POSITIONING_ENUM,
        tooltip: {
            en: 'Position of the video inside the container',
            es: 'Posición del video dentro del contenedor',
        },
    },
    {
        label: {
            en: 'Height',
            es: 'Altura',
        },
        name: 'height',
        type: FormItemTypes.NUMBER,
        tooltip: {
            en: 'Height of the video',
            es: 'Altura del video',
        },
    },
    {
        label: {
            en: 'Loop',
            es: 'Repetir',
        },
        name: 'loop',
        type: FormItemTypes.CHECKBOX,
        tooltip: {
            en: 'If the video should loop',
            es: 'Si el video debería repetirse',
        },
    },
    {
        label: {
            en: 'Overlay color',
            es: 'Color superpuesto',
        },
        name: 'overlayColor',
        type: FormItemTypes.COLOR,
        tooltip: {
            en: 'Color of the overlay',
            es: 'Color del superpuesto',
        },
        category: {
            en: 'Overlay',
            es: 'Superpuesto',
        },
    },
    {
        label: {
            en: 'Overlay color opacity',
            es: 'Opacidad del color superpuesto',
        },
        name: 'overlayColorAlpha',
        type: FormItemTypes.RANGE,
        tooltip: {
            en: 'Opacity of the overlay color',
            es: 'Opacidad del color superpuesto',
        },
        category: {
            en: 'Overlay',
            es: 'Superpuesto',
        },
    },
]

export default props
