import React from 'react'
import { Link } from 'react-router-dom'
import styledComponents from 'styled-components'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
    Button,
    FormItem,
    FormItemTypes,
    Header,
    HeaderSizes,
    TableItem,
    ThemeContext,
    cssRules,
    IconTypes,
    Typography,
    TypographyTypes,
} from 'rds'
import {
    cartRemoveItem,
    cartUpdateItemQuantity,
} from '../../reducers/cartReducer'
import { calculateUnitPriceWithAdditionals } from '../../utils'
import { getPictureThumbnail } from '../../utils/file'
import CartEntryAdditionals from './CartEntryAdditionals'
import CartEntryCustomFields from './CartEntryCustomFields'
import CartEntryCustomFieldItem from './CartEntryCustomFieldItem'
import ProductCardSkeleton from '../Product/ProductCard.skeleton'

const StyledCartEntryImage = styledComponents.img(props => ({
    objectFit: props.cropped ? 'cover' : 'contain',
    borderRadius: cssRules.borderRadiusValue(props),
}))

const CartEntry = ({
    entry,
    customFields = [],
    isLastOne = false,
    onClose,
    populatedProduct,
    position,
}) => {
    const dispatch = useDispatch()
    const themeContext = React.useContext(ThemeContext)
    const webstoreSettingsProducts = useSelector(
        state => state.session.subscription.webstore.settings.products,
        shallowEqual,
    )
    // This state contains a populated version of the Cart entry
    // IMPORTANT: It should only be used for visual stuff
    const [populatedEntry, setPopulatedEntry] = React.useState({
        item: {},
        variant: {},
    })
    const [entryPriceWithAdditionals, setEntryPriceWithAdditionals] =
        React.useState(populatedEntry.variant.price)
    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    /**
     * Calculates the individual price of the variant
     * including selected additionals
     */
    React.useEffect(() => {
        if (!populatedEntry.variant._id || !populatedEntry.variant.price) {
            return
        }
        const additionalsFields = customFields.filter(
            field => field.type === 'ADDITIONALS',
        )
        setEntryPriceWithAdditionals(
            calculateUnitPriceWithAdditionals(
                populatedEntry.variant.price,
                entry.additionals,
                additionalsFields,
            ),
        )
    }, [
        entry.additionals,
        customFields,
        populatedEntry.variant._id,
        populatedEntry.variant.price,
    ])

    // TODO: Improve performance here (API gets called every time we change the quantity)
    // an approach could be accepting "variantId" and "itemId" props and react on that
    React.useEffect(() => {
        if (typeof populatedProduct !== 'object' || !entry.variant) {
            return
        }
        const productVariantId = entry.variant._id || entry.variant
        const productVariant = populatedProduct.variants.find(
            productVariant => productVariant._id === productVariantId,
        )
        setPopulatedEntry({
            item: populatedProduct,
            variant: productVariant,
        })
    }, [populatedProduct, entry.variant])

    const handleRemoveCartItem = () => {
        if (window.confirm('Estas seguro?')) {
            dispatch(cartRemoveItem(position))
        }
    }

    const handleQuantityChange = (e, entryIndex) => {
        dispatch(cartUpdateItemQuantity(entryIndex, e.target.value))
    }

    if (!populatedEntry.item._id) {
        return (
            <TableItem key={populatedEntry.item._id} noLine={isLastOne}>
                <ProductCardSkeleton />
            </TableItem>
        )
    }
    return (
        <TableItem key={populatedEntry.item._id} noLine={isLastOne}>
            <article className='cart-item rds-flexbox align-center justify-between rds-p_bottom__sm'>
                <div className='rds-full-block rds-flexbox align-top'>
                    <div className='rds-full-block'>
                        {populatedEntry.variant.pictures?.length ? (
                            <StyledCartEntryImage
                                className='cart-item_thumbnail'
                                themeContext={themeContext}
                                src={getPictureThumbnail(
                                    populatedEntry.variant.pictures[0]
                                        .secure_url,
                                    80,
                                    80,
                                )}
                                alt={populatedEntry.variant.sku}
                                cropped={
                                    webstoreSettingsProducts.picturesType ===
                                    'CROPPED'
                                }
                            />
                        ) : null}
                        <div className='rds-flexbox align-top justify-between rds-m_top__md'>
                            <div onClick={onClose}>
                                <Link
                                    to={`/products/${populatedEntry.item._id}`}
                                >
                                    <Typography
                                        type={TypographyTypes.H3}
                                        className='rds-text-variant_link'
                                    >
                                        {populatedEntry.item.name}
                                    </Typography>
                                </Link>
                            </div>
                            <div align='right'>
                                <Header
                                    title={`$${
                                        entryPriceWithAdditionals *
                                        entry.quantity
                                    }`}
                                    text={
                                        entry.quantity > 1
                                            ? `$${entryPriceWithAdditionals} c/u`
                                            : undefined
                                    }
                                    size={HeaderSizes.SMALL}
                                />
                            </div>
                        </div>
                        <div
                            className={`rds-full-block ${
                                entry.quantity > 1 ? '' : 'rds-m_top__md'
                            }`}
                        >
                            <CartEntryCustomFields
                                entryCustomFields={
                                    populatedEntry.variant.customFields
                                }
                                customFields={customFields}
                            />
                            <CartEntryAdditionals
                                entryAdditionals={entry.additionals}
                                customFields={customFields}
                            />
                            <CartEntryCustomFieldItem label='Notas'>
                                <span style={{ wordBreak: 'break-word' }}>
                                    {entry.notes || '-'}
                                </span>
                            </CartEntryCustomFieldItem>
                            <FormItem
                                label='Cantidad'
                                type={FormItemTypes.NUMBER}
                                min={1}
                                max={populatedEntry.variant.stock}
                                className='rds-m_bottom__sm'
                                value={entry.quantity}
                                onChange={e =>
                                    handleQuantityChange(e, position)
                                }
                            />
                            <Button
                                className='rds-full-block rds-flexbox'
                                icon={IconTypes.BIN}
                                label='Quitar'
                                onClick={handleRemoveCartItem}
                            />
                        </div>
                    </div>
                </div>
            </article>
        </TableItem>
    )
}

export default CartEntry
