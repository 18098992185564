import React from 'react'
import { useNavigate } from 'react-router'
import {
    Block,
    Header,
    HeaderSizes,
    ThemeContext,
    Typography,
    TypographyTypes,
    cssRules,
} from 'rds'
import { applyEffectToImage, getBackgroundImageValue } from '../../utils'
import { getIsVideo } from '../../utils/file'
import Container from '../../layout/Container'
import Video from '../Video'

const Hero = ({
    alignment = 'left',
    backgroundImage,
    backgroundImagePosition = 'center',
    bordered,
    effect,
    footerText,
    headerSize = HeaderSizes.BIG,
    headerVariant,
    margin,
    navigateToURL,
    overlayColor = '#000000',
    overlayColorAlpha = 0,
    padding,
    roundedBorder,
    variant,
    text,
    title,
}) => {
    const themeContext = React.useContext(ThemeContext)
    const navigate = useNavigate()
    const isVideo = getIsVideo(backgroundImage)
    const style = {
        cursor: navigateToURL ? 'pointer' : undefined,
        padding: padding
            ? `calc(${cssRules.getCSSVariable(
                  'spacingLarge',
              )} + ${padding}px) calc(${cssRules.getCSSVariable(
                  'spacingSmall',
              )} + ${padding}px)`
            : undefined,
        margin,
        height: '100%',
    }
    if (!isVideo) {
        style.backgroundImage = getBackgroundImageValue(
            applyEffectToImage(backgroundImage, effect, themeContext),
            overlayColor,
            overlayColorAlpha,
        )
        style.backgroundPosition = backgroundImagePosition
    }

    const handleClick = () => {
        navigate(navigateToURL)
    }

    return (
        <Block
            className='hero rds-p_top rds-p_bottom rds-p_left__sm rds-p_right__sm'
            roundedBorder={roundedBorder}
            bordered={bordered}
            variant={variant}
            style={style}
            onClick={navigateToURL ? handleClick : null}
        >
            {isVideo ? (
                <Video
                    url={applyEffectToImage(
                        backgroundImage,
                        effect,
                        themeContext,
                    )}
                    overlayColor={overlayColor}
                    overlayColorAlpha={overlayColorAlpha}
                    objectPosition={backgroundImagePosition}
                    loop
                />
            ) : null}
            <Container className='rds-relative'>
                <Header
                    className='hero-header'
                    title={title}
                    text={text}
                    variant={headerVariant}
                    align={alignment}
                    size={headerSize}
                />
                {footerText ? (
                    <div
                        className='hero-footer rds-m_top__md'
                        align={alignment}
                    >
                        <Typography
                            type={TypographyTypes.H4}
                            variant={headerVariant}
                        >
                            {footerText}
                        </Typography>
                    </div>
                ) : null}
            </Container>
        </Block>
    )
}

export default Hero
