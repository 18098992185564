import { FormItemTypes } from 'rds'
import {
    POSITIONING_ENUM,
    HEADER_SIZES,
    HEADER_VARIANTS,
} from '../../constants'

const props = [
    {
        label: {
            en: 'Title',
            es: 'Título',
        },
        name: 'title',
        type: FormItemTypes.TEXT,
    },
    {
        label: {
            en: 'Text',
            es: 'Texto',
        },
        name: 'text',
        type: FormItemTypes.TEXT,
    },
    {
        label: {
            en: 'Size',
            es: 'Tamaño',
        },
        name: 'size',
        type: FormItemTypes.SELECT,
        enum: HEADER_SIZES,
    },
    {
        label: {
            en: 'Variant',
            es: 'Variante',
        },
        name: 'variant',
        type: FormItemTypes.SELECT,
        enum: HEADER_VARIANTS,
    },
    {
        label: {
            en: 'Alignment',
            es: 'Alineación',
        },
        name: 'align',
        type: FormItemTypes.SELECT,
        enum: POSITIONING_ENUM,
    },
    {
        label: {
            en: 'Alignment',
            es: 'Alineación',
        },
        name: 'align',
        type: FormItemTypes.SELECT,
        enum: ['left', 'center', 'right'],
    },
    {
        label: {
            en: 'Padding',
            es: 'Relleno',
        },
        name: 'padding',
        type: FormItemTypes.NUMBER,
    },
    {
        label: {
            en: 'Margin',
            es: 'Márgen',
        },
        name: 'margin',
        type: FormItemTypes.NUMBER,
    },
    {
        label: {
            en: 'Background color',
            es: 'Color de fondo',
        },
        name: 'backgroundColor',
        type: FormItemTypes.COLOR,
    },
    {
        label: {
            en: 'Navigate to URL',
            es: 'URL de Navegación',
        },
        name: 'navigateToURL',
        type: FormItemTypes.TEXT,
    },
]
export default props
