// Deps
import { IconTypes } from 'rds'
import { COMPONENTS_CATEGORIES } from '../constants'
// Components imports
import ActivePurchaseTracker from '../components/Purchases/ActivePurchaseTracker'
import CartSummary from '../components/Cart/CartSummary'
import CaseDetail from '../components/Case/CaseDetail'
import CategoryHeader from '../components/Category/CategoryHeader'
import CategoryHeaderProps from '../components/Category/CategoryHeader.props'
import CategoryProducts from '../components/Category/CategoryProducts'
import CategoryProductsProps from '../components/Category/CategoryProducts.props'
import ContactForm from '../components/Case/ContactForm'
import ContactFormProps from '../components/Case/ContactForm.props'
import CustomHTML from '../components/CustomHTML'
import CustomHTMLProps from '../components/CustomHTML/CustomHTML.props'
import CheckoutForm from '../components/Cart/CheckoutForm'
import CheckoutFormProps from '../components/Cart/CheckoutForm.props'
import FeaturedCategories from '../components/Category/FeaturedCategories'
import FeaturedCategoriesProps from '../components/Category/FeaturedCategories.props'
import FeaturedProduct from '../components/Product/FeaturedProduct'
import FeaturedProductProps from '../components/Product/FeaturedProduct.props'
import Filters from '../components/Category/Filters'
import FiltersProps from '../components/Category/Filters.props'
import Heading from '../components/Heading'
import HeadingProps from '../components/Heading/Heading.props'
import Hero from '../components/Hero'
import HeroProps from '../components/Hero/Hero.props'
import Image from '../components/Image'
import ImageProps from '../components/Image/Image.props'
import Login from '../components/User/Login'
import MyCases from '../components/Case/MyCases'
import Payment from '../components/Purchases/Payment'
import PaymentProps from '../components/Purchases/Payment.props'
import ProductBreadcrumbs from '../components/Product/ProductBreadcrumbs'
import ProductDetail from '../components/Product/ProductDetail'
import ProductDetailProps from '../components/Product/ProductDetail/ProductDetail.props'
import ProductsGrid from '../components/Product/ProductsGrid'
import ProductsGridProps from '../components/Product/ProductsGrid.props'
import ProductsCarrousel from '../components/Product/ProductsCarrousel'
import ProductsCarrouselProps from '../components/Product/ProductsCarrousel.props'
import PurchaseDetails from '../components/Purchases/PurchaseDetails'
import PurchaseDetailsProps from '../components/Purchases/PurchaseDetails.props'
import PurchaseProductDetails from '../components/Purchases/PurchaseProductDetails'
import ShipmentCostTester from '../components/Purchases/ShipmentCostTester'
import ShipmentCostTesterProps from '../components/Purchases/ShipmentCostTester.props'
import ShipmentZones from '../components/Purchases/ShipmentZones'
import ShipmentZonesProps from '../components/Purchases/ShipmentZones.props'
import ShipmentZonesList from '../components/Purchases/ShipmentZonesList'
import ShipmentZonesListProps from '../components/Purchases/ShipmentZonesList.props'
import Slider from '../components/Slider'
import SliderProps from '../components/Slider/Slider.props'
import UserEditForm from '../components/User/UserEditForm'
import UserProfileBanner from '../components/User/UserProfileBanner'
import UserPurchasesHistory from '../components/User/UserPurchasesHistory'
import UserFavorites from '../components/User/UserFavorites'
import UserFavoritesProps from '../components/User/UserFavorites/UserFavorites.props'
import Video from '../components/Video'
import VideoProps from '../components/Video/Video.props'
import YouTubeVideo from '../components/YouTubeVideo'
import YouTubeVideoProps from '../components/YouTubeVideo/YouTubeVideo.props'

const components = {
    ActivePurchaseTracker: {
        component: ActivePurchaseTracker,
        label: {
            en: 'Active Purchases Tracker',
            es: 'Estado de Compras Actuales',
        },
        icon: IconTypes.CART,
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: [],
    },
    CartSummary: {
        component: CartSummary,
        label: {
            en: 'Cart Summary',
            es: 'Resumen del carrito',
        },
        icon: IconTypes.CART,
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: [],
    },
    CaseDetail: {
        component: CaseDetail,
        label: {
            en: 'Case Detail',
            es: 'Detalle del Caso',
        },
        icon: 'Briefcase',
        category: COMPONENTS_CATEGORIES.CASE,
        properties: [],
    },
    CategoryHeader: {
        component: CategoryHeader,
        label: {
            en: 'Category Header',
            es: 'Encabezado de Categoría',
        },
        icon: IconTypes.CATEGORY,
        category: COMPONENTS_CATEGORIES.CATEGORY,
        properties: CategoryHeaderProps,
    },
    CategoryProducts: {
        component: CategoryProducts,
        label: {
            en: 'Category Products',
            es: 'Productos de la Categoría',
        },
        icon: IconTypes.CATEGORY,
        category: COMPONENTS_CATEGORIES.CATEGORY,
        properties: CategoryProductsProps,
    },
    ContactForm: {
        component: ContactForm,
        label: {
            en: 'Contact Form',
            es: 'Formulario de Contacto',
        },
        icon: IconTypes.PHONE,
        category: COMPONENTS_CATEGORIES.USER,
        properties: ContactFormProps,
    },
    CustomHTML: {
        component: CustomHTML,
        label: {
            en: 'Custom HTML',
            es: 'HTML Personalizado',
        },
        icon: 'CodeOne',
        category: COMPONENTS_CATEGORIES.DESIGN,
        properties: CustomHTMLProps,
    },
    CheckoutForm: {
        component: CheckoutForm,
        label: {
            en: 'Checkout Form',
            es: 'Formulario de Pago',
        },
        icon: 'Swipe',
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: CheckoutFormProps,
    },
    FeaturedCategories: {
        component: FeaturedCategories,
        label: {
            en: 'Featured Categories',
            es: 'Categorías destacadas',
        },
        icon: IconTypes.CATEGORY,
        category: COMPONENTS_CATEGORIES.CATEGORY,
        properties: FeaturedCategoriesProps,
    },
    FeaturedProduct: {
        component: FeaturedProduct,
        label: {
            en: 'Featured Product',
            es: 'Producto destacado',
        },
        icon: IconTypes.STAR,
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: FeaturedProductProps,
    },
    Filters: {
        component: Filters,
        label: {
            en: 'Product filters',
            es: 'Filtros de Producto',
        },
        icon: 'Filter',
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: FiltersProps,
    },
    Heading: {
        component: Heading,
        icon: 'English',
        category: COMPONENTS_CATEGORIES.DESIGN,
        label: {
            en: 'Heading',
            es: 'Encabezado',
        },
        properties: HeadingProps,
    },
    Hero: {
        component: Hero,
        icon: 'AlignTextBottomOne',
        category: COMPONENTS_CATEGORIES.DESIGN,
        label: {
            en: 'Hero',
            es: 'Portada',
        },
        properties: HeroProps,
    },
    Image: {
        component: Image,
        icon: IconTypes.PICTURE,
        category: COMPONENTS_CATEGORIES.DESIGN,
        label: {
            en: 'Image',
            es: 'Imagen',
        },
        properties: ImageProps,
    },
    Login: {
        component: Login,
        label: {
            en: 'Login form',
            es: 'Formulario de Inicio de Sesión',
        },
        icon: IconTypes.KEY,
        category: COMPONENTS_CATEGORIES.USER,
        properties: [],
    },
    MyCases: {
        component: MyCases,
        label: {
            en: 'My Cases',
            es: 'Mis Casos',
        },
        icon: 'Briefcase',
        category: COMPONENTS_CATEGORIES.CASE,
        properties: [],
    },
    Payment: {
        component: Payment,
        label: {
            en: 'Payment Handler',
            es: 'Manejo de Pagos',
        },
        icon: 'Api',
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: PaymentProps,
    },
    ProductBreadcrumbs: {
        component: ProductBreadcrumbs,
        label: {
            en: 'Products Breadcrumbs',
            es: 'Migajas de Producto',
        },
        icon: 'Navigation',
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: [],
    },
    ProductsCarrousel: {
        component: ProductsCarrousel,
        label: {
            en: 'Products Carousel',
            es: 'Carrusel de Productos',
        },
        icon: 'Carousel',
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: ProductsCarrouselProps,
    },
    PurchaseDetails: {
        component: PurchaseDetails,
        label: {
            en: 'Purchase Details',
            es: 'Detalle de la Compra',
        },
        icon: IconTypes.SALE,
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: PurchaseDetailsProps,
    },
    PurchaseProductDetails: {
        component: PurchaseProductDetails,
        label: {
            en: 'Purchase Product Details',
            es: 'Detalle de Productos de la Compra',
        },
        icon: IconTypes.SALE,
        category: COMPONENTS_CATEGORIES.PRODUCT,
    },
    ProductDetail: {
        component: ProductDetail,
        label: {
            en: 'Product Detail',
            es: 'Detalle del Producto',
        },
        icon: IconTypes.TAG,
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: ProductDetailProps,
    },
    ProductsGrid: {
        component: ProductsGrid,
        label: {
            en: 'Products Grid',
            es: 'Grilla de Productos',
        },
        icon: 'GridNine',
        category: COMPONENTS_CATEGORIES.PRODUCT,
        properties: ProductsGridProps,
    },
    Slider: {
        component: Slider,
        label: {
            en: 'Slider',
            es: 'Carrusel',
        },
        icon: 'MultiPictureCarousel',
        category: COMPONENTS_CATEGORIES.DESIGN,
        properties: SliderProps,
    },
    ShipmentCostTester: {
        component: ShipmentCostTester,
        label: {
            en: 'Shipment Cost Tester',
            es: 'Probador de Costos de Envío',
        },
        icon: 'MapTwo',
        category: COMPONENTS_CATEGORIES.SHIPMENT_COSTS,
        properties: ShipmentCostTesterProps,
    },
    ShipmentZones: {
        component: ShipmentZones,
        label: {
            en: 'Shipment Zones Map',
            es: 'Mapa de Zonas de Envío',
        },
        icon: 'MapTwo',
        category: COMPONENTS_CATEGORIES.SHIPMENT_COSTS,
        properties: ShipmentZonesProps,
    },
    ShipmentZonesList: {
        component: ShipmentZonesList,
        label: {
            en: 'Shipment Zones List',
            es: 'Lista de Zonas de Envío',
        },
        icon: 'MapTwo',
        category: COMPONENTS_CATEGORIES.SHIPMENT_COSTS,
        properties: ShipmentZonesListProps,
    },
    UserEditForm: {
        component: UserEditForm,
        label: {
            en: 'User Edit Form',
            es: 'Formulario de Editar Usuario',
        },
        icon: IconTypes.USER,
        category: COMPONENTS_CATEGORIES.USER,
        properties: [],
    },
    UserFavorites: {
        component: UserFavorites,
        label: {
            en: 'User Favorites',
            es: 'Favoritos del Usuario',
        },
        icon: IconTypes.STAR,
        category: COMPONENTS_CATEGORIES.USER,
        properties: UserFavoritesProps,
    },
    UserProfileBanner: {
        component: UserProfileBanner,
        label: {
            en: 'User Banner',
            es: 'Encabezado de Usuario',
        },
        icon: IconTypes.USER,
        category: COMPONENTS_CATEGORIES.USER,
        properties: [],
    },
    UserPurchasesHistory: {
        component: UserPurchasesHistory,
        label: {
            en: 'User Purchase History',
            es: 'Historial de Compras del Usuario',
        },
        icon: IconTypes.USER,
        category: COMPONENTS_CATEGORIES.USER,
        properties: [],
    },
    Video: {
        component: Video,
        label: {
            en: 'Video',
            es: 'Video',
        },
        icon: 'Video',
        category: COMPONENTS_CATEGORIES.DESIGN,
        properties: VideoProps,
    },
    YouTubeVideo: {
        component: YouTubeVideo,
        label: {
            en: 'YouTube Video',
            es: 'Video de YouTube',
        },
        icon: 'Video',
        category: COMPONENTS_CATEGORIES.DESIGN,
        properties: YouTubeVideoProps,
    },
}
export default components
