import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Product',
            es: 'Producto',
        },
        name: 'productId',
        type: FormItemTypes.LOOKUP,
        itemType: 'products',
    },
    {
        label: {
            en: 'Show picture',
            es: 'Mostrar foto',
        },
        name: 'showPicture',
        type: FormItemTypes.CHECKBOX,
    },
    {
        label: {
            en: 'Show price',
            es: 'Mostrar precio',
        },
        name: 'showPrice',
        type: FormItemTypes.CHECKBOX,
    },
    {
        label: {
            en: 'Header text',
            es: 'Texto del encabezado',
        },
        name: 'headerText',
        type: FormItemTypes.TEXT,
    },
    {
        label: {
            en: 'Button label',
            es: 'Texto del botón',
        },
        name: 'buttonLabel',
        type: FormItemTypes.TEXT,
    },
    {
        label: {
            en: 'Padding',
            es: 'Relleno',
        },
        name: 'padding',
        type: FormItemTypes.NUMBER,
    },
    {
        label: {
            en: 'Margin',
            es: 'Margen',
        },
        name: 'margin',
        type: FormItemTypes.NUMBER,
    },
    {
        label: {
            en: 'Rounded borders',
            es: 'Bordes redondeados',
        },
        name: 'roundedBorder',
        type: FormItemTypes.CHECKBOX,
    },
    {
        label: {
            en: 'Bordered',
            es: 'Con bordes',
        },
        name: 'bordered',
        type: FormItemTypes.CHECKBOX,
    },
]
export default props
