import { createSlice } from '@reduxjs/toolkit'

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        isFetching: false,
        error: null,
        sidebar: {
            visible: false,
        },
    },
    reducers: {
        setStart: state => {
            state.isFetching = true
            state.error = null
        },
        setEnd: state => {
            state.isFetching = false
            state.error = null
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setSidebarVisibility: (state, action) => {
            state.sidebar.visible = action.payload
        },
        toggleSidebarVisibility: state => {
            state.sidebar.visible = !state.sidebar.visible
        },
    },
})

export const {
    setError,
    setStart,
    setEnd,
    setSidebarVisibility,
    toggleSidebarVisibility,
} = uiSlice.actions

export default uiSlice.reducer
