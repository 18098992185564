import React from 'react'
import { Loader } from 'google-maps'
import { ThemeProvider, Loading, cssRules } from 'rds'
import { BrowserRouter as Router } from 'react-router-dom'
import api from './api'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    getSubscription,
    sessionSetUser,
    doGuestLogin,
} from './reducers/sessionReducer'
import { fetchLocations } from './reducers/locationReducer'
import { REANGO_TOKEN_LOCALSTORAGE_NAME } from './constants'
import { cartSetCart } from './reducers/cartReducer'
import { DEBUG_MODE } from './utils'
import { handleBuilderMessages } from './builder'
import { fetchCustomFields } from './reducers/fieldReducer'
import OneSignal from './utils/onesignal'
import { socket } from './socket'

import MobileSidebar from './layout/Topbar/MobileSidebar'
import CartSidebar from './layout/Cart/CartSidebar'
import Footer from './layout/Footer'
import Topbar from './layout/Topbar'
import AppContainer from './layout/AppContainer'
import LocationSelector from './layout/Locations/LocationSelector'

const App = () => {
    const dispatch = useDispatch()
    const subscriptionId = useSelector(state => state.session.subscription._id)
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const oneSignalCredentials = useSelector(
        state => state.session.subscription.webstore.oneSignal,
        shallowEqual,
    )
    const webstoreSettingsFavicon = useSelector(
        state => state.session.subscription.webstore.settings.favicon,
        shallowEqual,
    )
    const webstoreSettingsTheme = useSelector(
        state => state.session.subscription.webstore.settings.theme,
        shallowEqual,
    )
    const theme = {
        ...webstoreSettingsTheme,
        main: webstoreSettingsTheme.primary || cssRules.COLOR_PRIMARY_DEFAULT,
        secondary:
            webstoreSettingsTheme.secondary || cssRules.COLOR_SECONDARY_DEFAULT,
    }
    const listenerAdded = React.useRef()

    React.useEffect(() => {
        if (listenerAdded.current || !dispatch) return

        const listenerArgs = [
            'message',
            e => handleBuilderMessages(e, dispatch),
            false,
        ]

        // Listen to messages from Reango's Builder
        window.addEventListener(...listenerArgs)
        listenerAdded.current = true

        // Remove listener on component unmount
        return () => {
            window.removeEventListener(...listenerArgs)
        }
    }, [dispatch])

    const doInit = React.useCallback(async () => {
        api.defaults.baseURL = `${process.env.REACT_APP_API_URL}/projects/${process.env.REACT_APP_PROJECT_NAME}/`

        // Check auth
        const currentAuth = localStorage.getItem(REANGO_TOKEN_LOCALSTORAGE_NAME)
        if (currentAuth) {
            // Set the current auth token
            api.defaults.headers.common['Authorization'] = currentAuth
            if (DEBUG_MODE) {
                console.log('[APP] Set Auth in headers:', api.defaults.headers)
            }
            const session = jwt_decode(currentAuth)
            // Check token validity
            try {
                if (DEBUG_MODE) {
                    console.log('[APP] Trying with:', session)
                }
                const res = await api.get(`users/${session.user._id}`)
                const { user } = res.data
                if (DEBUG_MODE) {
                    console.log('[APP] Logged in user:', user)
                }
                const isCustomerUser = user.role !== 'webstore_guest'
                if (isCustomerUser) {
                    dispatch(sessionSetUser(user))
                }
                dispatch(
                    cartSetCart({
                        entries: user.cart || [],
                        isUserCart: isCustomerUser,
                    }),
                )
            } catch (error) {
                const errorMessage = error.body
                    ? error.body.message
                    : error.message
                console.error('[APP] Error getting user', errorMessage)
                // TODO: Related to socket.destroy() backend bug
                if (errorMessage !== 'Network Error') {
                    // Session expired
                    localStorage.removeItem(REANGO_TOKEN_LOCALSTORAGE_NAME)
                    await dispatch(doGuestLogin())
                } else if (DEBUG_MODE) {
                    console.error(
                        '[APP] Not clearing session given that this is an unexpected API error',
                    )
                }
            }
        } else {
            await dispatch(doGuestLogin())
        }

        // Initial fetchs
        dispatch(getSubscription())
        dispatch(fetchCustomFields())
        dispatch(fetchLocations())

        // Connect to socket
        const connection = socket.open()
        if (DEBUG_MODE) {
            console.log(
                '[APP]',
                '[SOCKET]',
                'Connected with ID:',
                connection.id,
            )
        }
    }, [dispatch])

    React.useEffect(() => {
        if (webstoreSettingsFavicon?.secure_url) {
            const link =
                document.querySelector("link[rel*='icon']") ||
                document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'
            link.href = webstoreSettingsFavicon.secure_url
            document.getElementsByTagName('head')[0].appendChild(link)
        }
    }, [webstoreSettingsFavicon])

    React.useEffect(() => {
        doInit()
    }, [doInit])

    React.useEffect(() => {
        if (!window.google) {
            new Loader(process.env.REACT_APP_GOOGLE_API_KEY, {
                libraries: ['geometry', 'places'],
            })
                .load()
                .then(google => {
                    window.google = google
                })
        }
    }, [])

    React.useEffect(() => {
        if (!sessionUser._id || !oneSignalCredentials) {
            return
        }
        OneSignal.doInit(sessionUser._id, oneSignalCredentials.appId)
    }, [sessionUser, oneSignalCredentials])

    return (
        <ThemeProvider theme={theme}>
            {subscriptionId ? (
                <Router>
                    <React.Fragment>
                        <Topbar />
                        <MobileSidebar />
                        <AppContainer />
                        <LocationSelector />
                        <Footer />
                        <CartSidebar />
                    </React.Fragment>
                </Router>
            ) : (
                <Loading tag='Cargando' className='rds-screen-centered' big />
            )}
        </ThemeProvider>
    )
}

export default App
