import React from 'react'
import { Button, ButtonVariants } from 'rds'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { cartAddItem } from '../../reducers/cartReducer'
import { getProductIsInCart } from '../../utils/product'

const CartButton = ({
    className,
    style = {},
    product,
    fullWidth,
    disabled = false,
    variant,
    additionals = {},
    notes,
    quantity = 1,
}) => {
    const dispatch = useDispatch()
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const cartEntries = useSelector(state => state.cart.entries, shallowEqual)
    const cartFetching = useSelector(state => state.cart.isFetching)
    const [inCart, setInCart] = React.useState(false)
    const [addingToCart, setAddingToCart] = React.useState(false)

    React.useEffect(() => {
        if (!Array.isArray(product.variants) || !variant._id) {
            return
        }
        setInCart(
            getProductIsInCart(
                cartEntries,
                product,
                variant,
                additionals,
                notes,
            ),
        )
    }, [product, additionals, notes, variant, cartEntries])

    React.useEffect(() => {
        if (!addingToCart || cartFetching) return
        setTimeout(() => {
            setAddingToCart(false)
        }, 500)
    }, [addingToCart, cartFetching])

    const outOfStock = !variant.infiniteStock && variant.stock <= 0
    const isDisabled = outOfStock || disabled || inCart

    const handleAddToCart = () => {
        setAddingToCart(true)
        dispatch(
            cartAddItem(
                sessionUser,
                product,
                variant,
                additionals,
                notes,
                quantity,
            ),
        )
    }

    const getLabel = variant => {
        if (!variant._id) {
            return 'No disponible'
        } else if (outOfStock) {
            return 'Sin stock'
        } else if (inCart) {
            return 'Ya está en el carrito'
        } else if (addingToCart) {
            return 'Agregando...'
        }
        return 'Añadir al carrito'
    }

    return (
        <Button
            className={`add-to-cart-button ${className || ''}`}
            style={{
                ...style,
                width: fullWidth ? '100%' : null,
            }}
            label={getLabel(variant)}
            variant={ButtonVariants.MAIN}
            onClick={
                (variant.infiniteStock || variant.stock) &&
                !inCart &&
                !addingToCart
                    ? handleAddToCart
                    : null
            }
            icon={
                variant.infiniteStock || variant.stock
                    ? 'ShoppingCartAdd'
                    : null
            }
            disabled={isDisabled}
        />
    )
}

export default CartButton
