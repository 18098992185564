import { FormItemTypes } from 'rds'
import { PRODUCT_CARROUSEL_TYPES } from '../../constants'

const props = [
    {
        label: {
            en: 'Type',
            es: 'Tipo',
        },
        name: 'type',
        tooltip: {
            en: '',
            es: 'El filtrado funciona solo en el tipo "Default"',
        },
        type: FormItemTypes.SELECT,
        enum: Object.keys(PRODUCT_CARROUSEL_TYPES),
    },
    {
        label: {
            en: 'Title',
            es: 'Título',
        },
        name: 'title',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text',
            es: 'Texto',
        },
        name: 'text',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Category',
            es: 'Categoría',
        },
        name: 'category',
        type: FormItemTypes.LOOKUP,
        itemType: 'categories',
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Minimum price',
            es: 'Precio mínimo',
        },
        name: 'minPrice',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Maximum price',
            es: 'Precio máximo',
        },
        name: 'maxPrice',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Sorting',
            es: 'Ordenar por',
        },
        name: 'sort',
        type: FormItemTypes.SELECT,
        enum: ['name', 'price', 'createdAt', 'updatedAt'],
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Sorting direction',
            es: 'Dirección de ordenamiento',
        },
        name: 'sortDirection',
        type: FormItemTypes.SELECT,
        enum: ['asc', 'desc'],
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Limit',
            es: 'Límite',
        },
        name: 'limit',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Filtering',
            es: 'Filtrado',
        },
    },
    {
        label: {
            en: 'Items per page',
            es: 'Elementos por página',
        },
        name: 'itemsPerPage',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Behavior',
            es: 'Comportamiento',
        },
    },
    {
        label: {
            en: 'Steps',
            es: 'Pasos',
        },
        name: 'steps',
        type: FormItemTypes.NUMBER,
        category: {
            en: 'Behavior',
            es: 'Comportamiento',
        },
    },
]
export default props
