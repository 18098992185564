import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Columns',
            es: 'Columnas',
        },
        name: 'columns',
        type: FormItemTypes.NUMBER,
    },
]

export default props
