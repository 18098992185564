import React from 'react'
import { useSelector } from 'react-redux'
import { Card, FormItem, ThemeVariants, Typography } from 'rds'
import api from '../../api'

const ShipmentCostTester = ({
    headerTitle,
    headerText,
    headerSize,
    headerVariant,
    searchLabel = 'Dirección de prueba',
}) => {
    const timeout = React.useRef()
    const locations = useSelector(state => state.location.locations)
    const [search, setSearch] = React.useState()
    const [matchingZones, setMatchingZones] = React.useState([])
    const [error, setError] = React.useState()
    const [isFetching, setIsFetching] = React.useState(false)
    const showErrorMessage = error && !isFetching
    const showResultsPanel = search && matchingZones.length && !isFetching
    const showNoZonesMessage =
        search && !matchingZones.length && !error && !isFetching

    const fetchMatchingZones = React.useCallback(async () => {
        if (!search) {
            return
        }
        try {
            // Reset state
            setMatchingZones([])
            setError()
            // Fetch shipment costs
            const res = await api.get(
                `webstore/shipment-costs/search?street=${search}&city=Montevideo&country=Uruguay`,
            )
            const lowestCostByLocation = {}
            // Get lowest cost for each location
            for (
                let index = 0;
                index < res.data.webstoreShipmentCosts.length;
                index++
            ) {
                const shipmentCost = res.data.webstoreShipmentCosts[index]
                if (
                    !lowestCostByLocation[shipmentCost.location] ||
                    lowestCostByLocation[shipmentCost.location].cost >
                        shipmentCost.cost
                ) {
                    lowestCostByLocation[shipmentCost.location] = shipmentCost
                }
            }
            // Add location object to each matching zone
            const matchingZones = Object.values(lowestCostByLocation).map(
                matchingZone => ({
                    ...matchingZone,
                    location: locations.find(
                        location => location.id === matchingZone.location,
                    ),
                }),
            )
            // Set state
            setMatchingZones(matchingZones)
        } catch (error) {
            setError(error.response?.data?.message)
        } finally {
            setIsFetching(false)
        }
    }, [search, locations])

    React.useEffect(() => {
        setIsFetching(true)
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(fetchMatchingZones, 500)
        return () => {
            clearTimeout(timeout.current)
        }
    }, [fetchMatchingZones])

    return (
        <Card
            headerTitle={headerTitle}
            headerText={headerText}
            headerSize={headerSize}
            headerVariant={headerVariant}
        >
            <FormItem
                onChange={event => setSearch(event.target.value)}
                value={search}
                label={searchLabel}
            />
            {showErrorMessage ? (
                <Typography
                    className='rds-full-block rds-m_top__sm'
                    variant={ThemeVariants.ERROR}
                >
                    {error}
                </Typography>
            ) : null}
            {showResultsPanel ? (
                <div className='rds-full-block rds-p_top__md'>
                    {matchingZones.map((matchingZone, index) => (
                        <Card className='rds-m_bottom__sm' key={index}>
                            <Typography>
                                <b>{matchingZone.location?.name}</b> - $
                                {matchingZone.cost}
                            </Typography>
                        </Card>
                    ))}
                </div>
            ) : null}
            {showNoZonesMessage ? (
                <Typography>No tienes envíos a esta zona</Typography>
            ) : null}
        </Card>
    )
}

export default ShipmentCostTester
