import React from 'react'
import componentsMap from './builder/components'
import { isBuilder } from './utils'
import { LAYOUT_TYPES } from './constants'
import Container from './layout/Container'
import Component from './layout/Component'

const DEBUG_PREFIX = '[LAYOUT_RENDERER]'

const renderComponents = components => {
    return components.map((componentConfig, key) => {
        const matchingCompConfig = componentsMap[componentConfig.component]
        if (typeof matchingCompConfig === 'undefined') {
            console.warn(
                DEBUG_PREFIX,
                `Component "${componentConfig.component}" not found`,
            )
            return null
        }
        const matchingComp = matchingCompConfig.component
        return (
            <Component componentConfig={componentConfig} key={key}>
                {React.createElement(
                    matchingComp,
                    componentConfig.props,
                    componentConfig.children &&
                        (typeof componentConfig.children === 'string'
                            ? componentConfig.children
                            : componentConfig.children.map(children =>
                                  layoutRenderer(children),
                              )),
                )}
            </Component>
        )
    })
}

const renderSection = (components, containerClass, contained = true) => {
    const className = `${containerClass} layout-section`
    if (contained) {
        return (
            <Container className={className}>
                {renderComponents(components)}
            </Container>
        )
    }
    return <div className={className}>{renderComponents(components)}</div>
}

export default function layoutRenderer(page) {
    const { name, layout, publishedLayout } = page
    const safePageName = name.replace(/ /g, '-').toLowerCase()
    const layoutToRender = isBuilder ? layout : publishedLayout
    switch (layoutToRender.type) {
        case LAYOUT_TYPES.ONE_COLUMN:
            return renderSection(
                layoutToRender.bodyComponents,
                `${safePageName} layout-body`,
            )

        case LAYOUT_TYPES.LEFT_SIDEBAR:
            return (
                <>
                    {renderSection(
                        layoutToRender.headerComponents,
                        `${safePageName} layout-header`,
                        false,
                    )}
                    <Container
                        className={`${safePageName} layout-main layout-left-sidebar_grid`}
                        layoutType={layoutToRender.type}
                        sidebarWidth={layoutToRender.sidebarWidth}
                    >
                        {renderSection(
                            layoutToRender.sidebarComponents,
                            `${safePageName} layout-sidebar`,
                            false,
                        )}
                        {renderSection(
                            layoutToRender.bodyComponents,
                            `${safePageName} layout-body`,
                            false,
                        )}
                    </Container>
                    {renderSection(
                        layoutToRender.footerComponents,
                        `${safePageName} layout-footer`,
                    )}
                </>
            )

        case LAYOUT_TYPES.RIGHT_SIDEBAR:
            return (
                <>
                    {renderSection(
                        layoutToRender.headerComponents,
                        `${safePageName} layout-header`,
                        false,
                    )}
                    <Container
                        className={`${safePageName} layout-main layout-right-sidebar_grid`}
                        layoutType={layoutToRender.type}
                        sidebarWidth={layoutToRender.sidebarWidth}
                    >
                        {renderSection(
                            layoutToRender.bodyComponents,
                            `${safePageName} layout-body`,
                            false,
                        )}
                        {renderSection(
                            layoutToRender.sidebarComponents,
                            `${safePageName} layout-sidebar`,
                            false,
                        )}
                    </Container>
                    {renderSection(
                        layoutToRender.footerComponents,
                        `${safePageName} layout-footer`,
                    )}
                </>
            )

        default:
            return null
    }
}
