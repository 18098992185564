import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import styles from './Topbar.module.css'
import {
    Typography,
    TypographyTypes,
    Icon,
    IconTypes,
    Tag,
    ThemeVariants,
    ThemeContext,
    ThemesNames,
    cssRules,
} from 'rds'
import {
    setSidebarVisibility,
    toggleSidebarVisibility,
} from '../../reducers/uiReducer'
import { cartToggleSidebarVisibility } from '../../reducers/cartReducer'
import { LOGO_DEFAULT_HEIGHT } from '../../constants'
import { getPictureThumbnail } from '../../utils/file'
import Session from './Session'
import Container from '../Container'
import TopbarNavigationItem from './TopbarNavigationItem'

const StyledNav = styled.nav`
    background: ${props =>
        props.webstoreSettingsHeader.background.picture
            ? `url(${props.webstoreSettingsHeader.background.picture})`
            : props.webstoreSettingsHeader.background.color
            ? props.webstoreSettingsHeader.background.color
            : props.webstoreSettingsTheme.scheme === ThemesNames.DARK
            ? 'var(--rds-colorBlackDark)'
            : 'white'};
    ${props => cssRules.borderRule(props)}
    ${props =>
        props.webstoreSettingsHeader.padding
            ? `
    padding: ${props.webstoreSettingsHeader.padding}px 0;`
            : ''}
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
`

const Topbar = () => {
    const dispatch = useDispatch()
    const themeContext = React.useContext(ThemeContext)
    const projectName = useSelector(
        state => state.session.subscription.projectFullname,
        shallowEqual,
    )
    const webstoreSettingsHeader = useSelector(
        state => state.session.subscription.webstore.settings.header,
        shallowEqual,
    )
    const navigationItems = useSelector(
        state =>
            state.session.subscription.webstore.settings.header.navigation
                .items,
        shallowEqual,
    )
    const webstoreSettingsTheme = useSelector(
        state => state.session.subscription.webstore.settings.theme,
        shallowEqual,
    )
    const cart = useSelector(state => state.cart, shallowEqual)
    const isSidebarVisible = useSelector(state => state.ui.sidebar.visible)

    const handleCartClick = () => {
        dispatch(cartToggleSidebarVisibility())
    }

    const toggleMobileSidebar = () => {
        dispatch(toggleSidebarVisibility())
    }

    return (
        <StyledNav
            id='nav'
            className='scrolled'
            role='navigation'
            webstoreSettingsHeader={webstoreSettingsHeader}
            webstoreSettingsTheme={webstoreSettingsTheme}
            themeContext={themeContext}
        >
            <Container className='nav-container rds-flexbox align-center justify-between'>
                <div
                    className={`rds-flexbox align-center ${
                        webstoreSettingsHeader.navigation.location === 'RIGHT'
                            ? 'justify-between'
                            : ''
                    }`}
                    style={{ width: '-webkit-fill-available' }}
                >
                    <div
                        className={`${styles['nav_menu']} nav_menu rds-m_right__sm`}
                        onClick={toggleMobileSidebar}
                    >
                        <div className='rds-flexbox'>
                            <Icon
                                icon='HamburgerButton'
                                size={20}
                                variant={webstoreSettingsHeader.text.variant}
                            />
                        </div>
                    </div>
                    <NavLink
                        to='/'
                        className='rds-flexbox rds-m_right__md'
                        onClick={() => {
                            if (isSidebarVisible) {
                                toggleMobileSidebar()
                            }
                        }}
                    >
                        {webstoreSettingsHeader.logo.url ? (
                            <img
                                className='nav_logo'
                                height={
                                    webstoreSettingsHeader.logo.height ||
                                    LOGO_DEFAULT_HEIGHT
                                }
                                width={webstoreSettingsHeader.logo.width}
                                src={getPictureThumbnail(
                                    webstoreSettingsHeader.logo.url,
                                    webstoreSettingsHeader.logo.width,
                                    webstoreSettingsHeader.logo.height ||
                                        LOGO_DEFAULT_HEIGHT,
                                )}
                                alt={projectName}
                            />
                        ) : (
                            <Typography
                                style={{ fontWeight: 'bolder' }}
                                variant={webstoreSettingsHeader.text.variant}
                                type={TypographyTypes.H2}
                            >
                                {projectName}
                            </Typography>
                        )}
                    </NavLink>
                    <div
                        className={`sidebar-items ${styles['sidebar-items']} rds-m_right__sm`}
                    >
                        {navigationItems.map((navItem, i) => (
                            <TopbarNavigationItem item={navItem} key={i} />
                        ))}
                    </div>
                </div>
                <div className='rds-flexbox align-center'>
                    <div className='rds-flexbox align-center'>
                        <div
                            className='rds-flexbox rds-m_left__sm rds-m_right__md'
                            onClick={() => {
                                // Open sidebar
                                dispatch(setSidebarVisibility(true))

                                // Focus on global search input automatically
                                const globalSearchEl = document.querySelector(
                                    '.global-search_input',
                                )
                                if (globalSearchEl) {
                                    globalSearchEl.focus()
                                }
                            }}
                        >
                            {webstoreSettingsHeader.search ? (
                                <Icon
                                    icon={IconTypes.SEARCH}
                                    size={20}
                                    style={{ cursor: 'pointer' }}
                                    variant={
                                        webstoreSettingsHeader.text.variant
                                    }
                                />
                            ) : null}
                        </div>
                        <div
                            onClick={handleCartClick}
                            style={{ cursor: 'pointer' }}
                            className='rds-flexbox align-center rds-m_right__md'
                        >
                            {cart.entries.length ? (
                                <Tag variant={ThemeVariants.DARK}>
                                    <Icon
                                        icon='Shopping'
                                        className='rds-m_right__sm'
                                        size={20}
                                        variant={ThemeVariants.WHITE}
                                    />
                                    {cart.entries.length}
                                </Tag>
                            ) : (
                                <Icon
                                    icon='Shopping'
                                    size={20}
                                    variant={
                                        webstoreSettingsHeader.text.variant
                                    }
                                />
                            )}
                        </div>
                        <div className='nav-session'>
                            <Session />
                        </div>
                    </div>
                </div>
            </Container>
        </StyledNav>
    )
}

export default Topbar
