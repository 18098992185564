import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'
import components from '../builder/components'

const RESIZE_EVENT_NAME = 'resize'

const StyledComponent = styled.div`
    ${props => {
        const { componentConfig, screenWidth, themeSettings } = props
        const componentName = components[componentConfig.component].label?.es
        const { containerWidth, componentsMargin, borderRadius } = themeSettings
        const size = componentConfig.size || 12
        const fitIsCover = componentConfig.fit === 'cover'
        const isFullWidth = fitIsCover && size === 12
        let margin = ''
        const spaceBetween = screenWidth - containerWidth
        const spaceBetweenPercentage = (spaceBetween * 100) / containerWidth
        if (spaceBetween >= 0 && spaceBetweenPercentage >= 20) {
            margin += `-${spaceBetween}px`
        } else {
            margin += `calc((var(--containerWidth) - ${screenWidth}px) / 2)`
        }
        const result = `
            --webstoreBuilderHighlightColor: ${themeSettings.primary};
            grid-column: span ${size};
            ${
                isFullWidth
                    ? `
            margin-left: ${margin};
            margin-right: ${margin};
            `
                    : ''
            }

            &:not(:empty) {
                margin-bottom: ${componentsMargin}px;
                ${componentConfig.customCSS || ''}
            }

            &.webstore-builder_highlighted:before {
                content: "${componentName}";
                border-top-left-radius: ${borderRadius}px;
                border-top-right-radius: ${borderRadius}px;
            }

            &.webstore-builder_highlighted:after {
                border-radius: ${borderRadius}px;
            }
        `
        return result
    }}
`

const Component = ({ componentConfig, children }) => {
    const themeSettings = useSelector(
        state => state.session.subscription.webstore.settings.theme,
        shallowEqual,
    )
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)

    React.useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener(RESIZE_EVENT_NAME, handleResize)
        return () => {
            window.removeEventListener(RESIZE_EVENT_NAME, handleResize)
        }
    }, [])

    return (
        <StyledComponent
            className='layout-component rds-relative'
            id={componentConfig._id}
            data-component-id={componentConfig._id}
            componentConfig={componentConfig}
            themeSettings={themeSettings}
            screenWidth={screenWidth}
        >
            {children}
        </StyledComponent>
    )
}

export default Component
