import { FormItemTypes } from 'rds'

const props = [
    {
        label: {
            en: 'Mobile ready',
            es: 'Apto para mobiles',
        },
        name: 'mobileReady',
        type: FormItemTypes.CHECKBOX,
    },
]
export default props
