import React from 'react'
import {
    Button,
    ButtonVariants,
    FormItem,
    FormItemTypes,
    Typography,
    TypographyTypes,
} from 'rds'

const UserAddressItem = ({ address, isSelected, onRemove, onUpdate }) => {
    return (
        <div className='rds-full-block rds-flexbox align-center justify-between'>
            <div className='rds-flexbox align-center'>
                <FormItem
                    type={FormItemTypes.CHECKBOX}
                    value={isSelected}
                    style={{ width: 'auto' }}
                    className='rds-m_right__sm'
                />
                <div>
                    <Typography type={TypographyTypes.H4}>
                        {address.street} {address.doorNumber}
                    </Typography>
                    {address.apartment ? (
                        <Typography type={TypographyTypes.P}>
                            Apartamento {address.apartment}
                        </Typography>
                    ) : null}
                    <Typography type={TypographyTypes.P}>
                        {address.zipCode} {address.city}, {address.country}
                    </Typography>
                    {address.notes ? (
                        <Typography
                            type={TypographyTypes.P}
                            className='rds-m_top__sm'
                        >
                            Notas: {address.notes}
                        </Typography>
                    ) : null}
                </div>
            </div>
            <div className='rds-flexbox align-center'>
                {onUpdate ? (
                    <Button
                        label='Modificar'
                        variant={ButtonVariants.TRANSPARENT}
                        onClick={() => {
                            if (typeof onUpdate === 'function') {
                                onUpdate(address._id)
                            }
                        }}
                    />
                ) : null}
                {onRemove ? (
                    <Button
                        label='Remover'
                        className='rds-m_left__md'
                        variant={ButtonVariants.TRANSPARENT}
                        onClick={() => {
                            if (typeof onRemove === 'function') {
                                onRemove(address._id)
                            }
                        }}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default UserAddressItem
