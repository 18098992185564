import React from 'react'
import styles from './ProductDetail.module.css'
import { shallowEqual, useSelector } from 'react-redux'

const ProductDetailPictures = ({
    product,
    pictures,
    activePicture,
    onSelect,
}) => {
    const webstoreSettings = useSelector(
        state => state.session.subscription.webstore.settings,
        shallowEqual,
    )
    return (
        <div
            className={`${styles['product-detail_pictures-section']} ${
                pictures?.length === 1
                    ? styles['product-detail_pictures-section_individual']
                    : ''
            } product-detail_pictures-section rds-grid`}
        >
            {pictures?.length > 1 ? (
                <div
                    className={`${styles['product-picture-selector']} product-picture-selector rds-flexbox direction-column align-center`}
                >
                    {pictures.map((picture, index) => (
                        <img
                            key={index}
                            src={picture.secure_url}
                            alt={product.name}
                            className={`${
                                styles['product-picture-selector_picture']
                            } product-picture-selector_picture ${
                                activePicture === picture.secure_url
                                    ? 'product-picture-selector_picture-active'
                                    : ''
                            }`}
                            onMouseOver={() => onSelect(picture)}
                            style={{
                                objectFit:
                                    webstoreSettings.products.picturesType ===
                                    'UNCROPPED'
                                        ? 'contain'
                                        : null,
                            }}
                        />
                    ))}
                </div>
            ) : null}
            <img
                src={activePicture}
                alt={product.name}
                className={`${styles['product-detail_current-picture']} product-detail_current-picture`}
            />
        </div>
    )
}

export default ProductDetailPictures
