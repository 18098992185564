import { FormItemTypes } from 'rds'
import { HEADER_VARIANTS, HEADER_SIZES } from '../../constants'

const props = [
    {
        label: {
            en: 'Title',
            es: 'Título',
        },
        name: 'headerTitle',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text',
            es: 'Texto',
        },
        name: 'headerText',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text variant',
            es: 'Variante del texto',
        },
        name: 'headerVariant',
        type: FormItemTypes.SELECT,
        enum: HEADER_VARIANTS,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Text size',
            es: 'Tamaño del texto',
        },
        name: 'headerSize',
        type: FormItemTypes.SELECT,
        enum: HEADER_SIZES,
        category: {
            en: 'Header',
            es: 'Encabezado',
        },
    },
    {
        label: {
            en: 'Search label',
            es: 'Etiqueta de búsqueda',
        },
        name: 'searchLabel',
        type: FormItemTypes.TEXT,
        category: {
            en: 'Search',
            es: 'Búsqueda',
        },
    },
]
export default props
