import React from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import styled from 'styled-components'
import { Header, Tag, ThemeVariants, ThemeContext } from 'rds'
import api from '../../api'
import { getParsedColor } from '../../utils'
import Container from '../../layout/Container'

const StyledCategoryHeader = styled.div`
    position: relative;
    ${props => {
        if (!props.overlayColor) {
            return ''
        }

        const overlayColor = getParsedColor(
            props.overlayColor,
            props.overlayColorAlpha,
        )
        const backgroundRule = `background-image: linear-gradient(${overlayColor}, ${overlayColor} 100%);`
        return `
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                ${backgroundRule}
            }
        `
    }}

    & .category-header_content {
        position: relative;
        z-index: 2;
    }
`

const StyledcategoryHeaderImage = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
    z-index: 1;
`

const CategoryHeader = ({
    headerVariant,
    headerAlignCenter = false,
    showProductCount,
    useCategoryPicture,
    overlayColor,
    overlayColorAlpha,
}) => {
    const params = useParams()
    const abortController = React.useMemo(() => new AbortController(), [])
    const themeContext = React.useContext(ThemeContext)
    const [category, setCategory] = React.useState({})
    const mounted = React.useRef()

    React.useEffect(() => {
        mounted.current = true
        return () => {
            abortController.abort()
            mounted.current = false
        }
    }, [abortController])

    React.useEffect(() => {
        const { categoryId } = params
        if (!categoryId) return
        api.get(`categories/${categoryId}?useCache=true`, {
            signal: abortController.signal,
        })
            .then(res => {
                if (mounted.current) {
                    setCategory(res.data.category)
                }
            })
            .catch(err => console.error('[CATEGORY_HEADER]', err))
    }, [params, abortController.signal])

    return (
        <>
            <Helmet
                script={[
                    helmetJsonLdProp({
                        '@context': 'https://schema.org',
                        '@type': 'Thing',
                        description: category.description,
                        image: category.picture?.secure_url,
                        name: category.name,
                    }),
                ]}
            />
            <StyledCategoryHeader
                themeContext={themeContext}
                overlayColor={overlayColor}
                overlayColorAlpha={overlayColorAlpha}
                className='category_header rds-m_bottom__sm rds-p_top rds-p_bottom'
            >
                <Container className='category-header_content'>
                    <Header
                        title={category.name}
                        text={category.description}
                        variant={headerVariant}
                        alignCenter={headerAlignCenter}
                    />
                    {showProductCount ? (
                        <div
                            className='rds-m_top__sm'
                            align={headerAlignCenter ? 'center' : ''}
                        >
                            <Tag variant={ThemeVariants.DARK}>
                                {category.productCount} productos
                            </Tag>
                        </div>
                    ) : null}
                </Container>
                {useCategoryPicture &&
                category.picture &&
                category.picture.secure_url ? (
                    <StyledcategoryHeaderImage
                        src={category.picture.secure_url}
                        alt={category.name}
                    />
                ) : null}
            </StyledCategoryHeader>
        </>
    )
}

export default CategoryHeader
