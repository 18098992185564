import React from 'react'
import { useSelector } from 'react-redux'
import { Card, HeaderSizes } from 'rds'
import Reviews from '../Reviews'

const ProductDetailReviews = ({ productId }) => {
    const sessionUserId = useSelector(state => state.session.user._id)
    return (
        <Card headerTitle='Comentarios' headerSize={HeaderSizes.SMALL}>
            <Reviews productId={productId} userId={sessionUserId} />
        </Card>
    )
}

export default ProductDetailReviews
