import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    Button,
    Typography,
    TypographyTypes,
    ThemeVariants,
    EmptyState,
    Block,
    Loading,
    ButtonVariants,
    cssRules,
    ThemeContext,
} from 'rds'
import {
    cartRemoveItem,
    cartToggleSidebarVisibility,
} from '../../reducers/cartReducer'
import { calculateUnitPriceWithAdditionals } from '../../utils'
import CartEntry from '../../components/Cart/CartEntry'
import api from '../../api'

const DEBUG_PREFIX = '[CART]'

const Cart = () => {
    const dispatch = useDispatch()
    const themeContext = React.useContext(ThemeContext)
    const cartSidebar = useSelector(state => state.cart.sidebar, shallowEqual)
    const cartEntries = useSelector(state => state.cart.entries, shallowEqual)
    const customFields = useSelector(state => state.field.fields, shallowEqual)
    const [loading, setLoading] = React.useState(true)
    const [total, setTotal] = React.useState(0)
    const [populatedCartProducts, setPopulatedCartProducts] = React.useState([])

    /**
     * Calculates the total price of the cart and retrieves the
     * custom fields for the categories involved so it can display
     * user-friendly labels for them.
     */
    const calculateTotal = React.useCallback(() => {
        if (!cartEntries.length || !populatedCartProducts.length) {
            return
        }
        // Make total sum
        let total = 0
        cartEntries.forEach(cartEntry => {
            const cartEntryItemId = cartEntry.item._id || cartEntry.item
            const cartEntryProduct = populatedCartProducts.find(
                populatedCartProduct =>
                    populatedCartProduct._id === cartEntryItemId,
            )
            if (cartEntryProduct) {
                const variant = cartEntryProduct.variants.find(
                    variant => variant._id === cartEntry.variant,
                )

                // If there are customFields, calculate depending on additionals
                let variantPriceWithAdditionals = parseInt(variant.price)
                if (
                    customFields.length &&
                    typeof cartEntry.additionals === 'object' &&
                    Object.keys(cartEntry.additionals).length
                ) {
                    const additionalsFields = customFields.filter(
                        field => field.type === 'ADDITIONALS',
                    )
                    variantPriceWithAdditionals =
                        calculateUnitPriceWithAdditionals(
                            variantPriceWithAdditionals,
                            cartEntry.additionals,
                            additionalsFields,
                        )
                }
                total +=
                    variantPriceWithAdditionals * parseInt(cartEntry.quantity)
            }
        })
        setTotal(total)
        setLoading(false)
    }, [customFields, cartEntries, populatedCartProducts])

    React.useEffect(() => {
        calculateTotal()
    }, [calculateTotal])

    React.useEffect(() => {
        if (!cartEntries.length) {
            return
        }
        const newProductIds = cartEntries.map(
            cartEntry => cartEntry.item._id || cartEntry.item,
        )
        const currentProductIds = populatedCartProducts.map(prod => prod._id)
        const hasNewProduct = newProductIds.some(
            prodId => !currentProductIds.includes(prodId),
        )

        // Get populated version of cart products ONLY if there is a difference
        // on cart entries slice and local component state
        if (
            hasNewProduct ||
            cartEntries.length !== populatedCartProducts.length
        ) {
            Promise.all(
                newProductIds.map(prodId => api.get(`products/${prodId}`)),
            )
                .then(responses => {
                    const newPopulatedCartProducts = responses.map(
                        res => res.data.product,
                    )
                    setPopulatedCartProducts(newPopulatedCartProducts)
                })
                .catch(err => {
                    // Check if product is wrong or has been deleted
                    if (err.response?.status === 404) {
                        // Remove product from cart
                        const productId = newProductIds.find(productId =>
                            err.response.request.responseURL.includes(
                                productId,
                            ),
                        )
                        const cartEntryIndex = cartEntries.findIndex(
                            cartEntry => cartEntry.item === productId,
                        )
                        dispatch(cartRemoveItem(cartEntryIndex))
                    }
                    console.error(DEBUG_PREFIX, err)
                })
        }
    }, [dispatch, cartEntries, populatedCartProducts])

    const closeCartSidebar = () => {
        if (cartSidebar.visible) {
            dispatch(cartToggleSidebarVisibility())
        }
    }

    return (
        <Block
            className='cart rds-relative rds-overflow_auto'
            style={{ overflowY: 'auto' }}
        >
            <div className='rds-p_around__md'>
                <div className='rds-flexbox align-center justify-between'>
                    <div className='rds-m_right__sm'>
                        <Typography type={TypographyTypes.H4}>
                            Carrito de compras
                        </Typography>
                        <Typography>
                            Estos son los productos que tienes en el carrito
                        </Typography>
                    </div>
                    {cartSidebar.visible ? (
                        <Button
                            label='Cerrar'
                            variant={ButtonVariants.TRANSPARENT}
                            onClick={closeCartSidebar}
                        />
                    ) : null}
                </div>
                {cartEntries.length ? (
                    loading ? (
                        <div className='rds-m_top__sm rds-m_bottom__sm'>
                            <Loading tag='Cargando tu carrito...' />
                        </div>
                    ) : (
                        <div className='rds-m_top__md'>
                            {cartEntries.map((entry, index) => (
                                <CartEntry
                                    key={index}
                                    entry={entry}
                                    customFields={customFields}
                                    onClose={closeCartSidebar}
                                    populatedProduct={populatedCartProducts.find(
                                        popProd => popProd._id === entry.item,
                                    )}
                                    isLastOne={index === cartEntries.length - 1}
                                    position={index}
                                />
                            ))}
                        </div>
                    )
                ) : (
                    <div className='rds-m_top__md rds-full-block rds-m_bottom'>
                        <EmptyState
                            alignLeft
                            title='Sin items en el carrito'
                            message='Agrega alguno de nuestros items para comprarlo'
                        />
                    </div>
                )}
            </div>
            {cartEntries.length && !loading ? (
                <Block
                    className='cart-ribbon rds-p_around__md rds-full-block'
                    style={{
                        borderTop: `${cssRules.borderWidthValue({
                            themeContext,
                        })} ${cssRules.borderColorValue({
                            themeContext,
                        })} solid`,
                    }}
                >
                    <div className='rds-flexbox align-center justify-between'>
                        <Typography type={TypographyTypes.H3}>Total</Typography>
                        <Typography type={TypographyTypes.H3}>
                            $ {total}
                        </Typography>
                    </div>
                    <Link
                        to='/checkout'
                        className='rds-full-block rds-m_top__sm'
                    >
                        <Button
                            onClick={closeCartSidebar}
                            label='Finalizar compra'
                            variant={ThemeVariants.MAIN}
                            className='cart-ribbon_complete rds-full-block rds-flexbox'
                            disabled={!total}
                        />
                    </Link>
                    <Button
                        className='rds-m_top__sm rds-full-block rds-flexbox'
                        label='Continuar comprando'
                        onClick={closeCartSidebar}
                    />
                </Block>
            ) : null}
        </Block>
    )
}

export default Cart
